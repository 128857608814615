import React, { useState } from "react";
import { act } from "react";
import { useNavigate } from "react-router-dom";

const MiniList = ({ pr }) => {
  const {
    listData,
    activeItem,
    loading,
    currtype,
    changeItem,
    destination,
    from,
  } = pr;
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(1000);
  // console.log(listData?.length, "222");
  const paginateData = listData?.slice(0, perPage);

  // let keyy = "totalAmount";
  // switch (from) {
  //   case "purchaseReceive":
  //     keyy = "quantity";
  //     break;
  //   case "paymentMade":
  //     keyy = "paymentAmount";
  //     break;
  //   default:
  //     keyy = "totalAmount";
  //     break;
  // }
  // console.log(keyy, "from");

  return (
    <>
      {/* {loading && <h1>sdfdsfdsf</h1>} */}
      <div className="bill_dtls_left">
        <div className="bill_dtls_left_top">
          {paginateData?.length > 0 ? (
            paginateData?.map((eachList) => {
              return (
                <div
                  style={{
                    cursor: eachList?._id == activeItem ? "no-drop" : "pointer",
                  }}
                  onClick={() => {
                    changeItem(eachList?._id);
                    navigate(`${destination}${eachList?._id}`);
                  }}
                  key={eachList?._id}
                  className={
                    eachList._id == activeItem
                      ? "bill_left_info active"
                      : "bill_left_info"
                  }
                >
                  <div className="billing_dtlng">
                    {/* <div className="bill_check">
                  <input type="checkbox" name="" defaultValue="" />
                </div> */}
                    <div className="sllr_cntnt">
                      {/* <h3>Mahendra &amp; Associates</h3> */}
                      <h3>{eachList?.supplierId?.name}</h3>
                      {from != "paymentMade" && (
                        <p>
                          Bill No:<span>{eachList?.billNumber}</span>
                        </p>
                      )}
                      {console.log(eachList, "eachList")}
                      {from == "purchaseReceive" ? (
                        <p>
                          Receive Date:
                          <span>{eachList?.receivedDate?.slice(0, 10)}</span>
                        </p>
                      ) : from == "paymentMade" ? (
                        <p>
                          date:
                          <span>{eachList?.paymentDate?.slice(0, 10)}</span>
                        </p>
                      ) : from == "bills" ? (
                        <p>
                          date : <span>{eachList?.billDate?.slice(0, 10)}</span>
                        </p>
                      ) : (
                        <p>
                          date:
                          <span>
                            {eachList?.dateOfPurchaseOrder?.slice(0, 10)}
                          </span>
                        </p>
                      )}
                      {/* {from != "purchaseReceive" ||
                        ("paymentMade" && (
                          <p>
                            Status:
                            <span className="bill_pd grn">
                              {eachList?.status}
                            </span>
                          </p>
                        ))} */}
                      {from == "paymentMade" && (
                        <p>
                          Payment mode :
                          <span className="bill_pd grn">
                            {eachList?.paymentMode}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                  {from == "purchaseReceive" ? (
                    <div className="bill_amt sllr_cntnt">
                      <h3>QTY - {eachList?.quantity}</h3>
                    </div>
                  ) : from == "paymentMade" ? (
                    <div className="bill_amt sllr_cntnt">
                      <h3>Amount - {eachList?.paymentAmount}</h3>
                    </div>
                  ) : (
                    <div className="bill_amt sllr_cntnt">
                      <h3>Amount - {eachList?.totalAmount}</h3>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <h1>No Item Found.</h1>
          )}
        </div>
        <div className="bill_dtls_left_btm">
          <div className="bill_page">
            <div className="bttm_drv_lft">
              <div className="bttm_drv_pg_slct">
                <select
                  onChange={(e) => {
                    setPerPage(Number(e.target.value));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
            <h3>Per Page</h3>
          </div>
          <h3>
            <span>{listData?.length}</span> bills found
          </h3>
        </div>
      </div>
    </>
  );
};

export default MiniList;
