import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faEye,
  faMagnifyingGlass,
  faPlus,
  faStar,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import ordr_img1 from "../../../assets/images/ordr_img1.png";

import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";
import {
  logout,
  sellerProduct,
} from "../../../redux/features/sellers/sellerLoginSlice";
import { allProducts } from "../../../redux/features/sellers/sellerProductSlice";
import axios from "axios";
import NavBar from "../../common/Nav/NavBar";
import { addSupplierInfo } from "../../../redux/features/sellers/sellerPurchaseSlice";
import { addWarehouse } from "../../../redux/features/sellers/sellerWarehouseSlice";
import { useFormik } from "formik";
import * as rules from "yup";
import { add_warehouse } from "../../../constants/Api/Api";

const AddWarehouse = ({ prr = {} }) => {
  const {
    wareHouseName: renamedwarehouse,
    wfromm,
    setWarehoue,
    setOpenWareHouseForm,
    setWareHouseName,
  } = prr;
  // console.log(aa, "ccc");

  const loc = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [warehouseInfo, setWarehouseInfo] = useState({
  //   warehouseName: "",
  //   email: "",
  //   phone: 0,
  //   street: "",
  //   city: "",
  //   state: "",
  //   zipcode: "",
  //   country: "",
  // });

  // const changeHandler = (e) => {
  //   if (e.target.type === "text") {
  //     setWarehouseInfo({ ...warehouseInfo, [e.target.name]: e.target.value });
  //   } else if (e.target.type === "email") {
  //     setWarehouseInfo({ ...warehouseInfo, [e.target.name]: e.target.value });
  //   } else if (e.target.type === "number") {
  //     setWarehouseInfo({ ...warehouseInfo, [e.target.name]: e.target.value });
  //   }
  // };

  const createWareHouseByModal = async ({ warehouseInfo }) => {
    try {
      const res = await axios.post(add_warehouse, warehouseInfo, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (res?.data?.success) {
        console.log(res?.data, "abc");
        swal("Done", res?.data?.message, "success");
        setWareHouseName(res?.data?.data?.warehouseName);
        setWarehoue(res?.data?.data?._id);
        setOpenWareHouseForm(false);
      } else {
        console.log(res?.data);
      }
    } catch (error) {
      console.warn(error);
      swal("Oppps !", error?.response?.data?.message, "error");
    }
  };
  const validationSchema = rules.object().shape({
    warehouseName: rules.string().required("Please type Warehouse name"),
    email: rules
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: rules
      .string()
      .matches(
        /^(?:\(\d{3}\)\s?|\d{3}[-.\s]?)(\d{3})[-.\s]?(\d{4})$/,
        "Invalid phone number format"
      )
      .required("Phone number is required"),
    street: rules.string().required("Please type the name of street"),
    city: rules.string().required("Please type the name of city"),
    state: rules.string().required("Please type the name of state"),
    country: rules.string().required("Please type the name of country"),
    zipcode: rules
      .string()
      .matches(/^\d{6}$/, "Zip code must be exactly 6 digits")
      .required("Zip code is required"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: {
      warehouseName: renamedwarehouse ? renamedwarehouse : "",
      email: "",
      phone: "",
      street: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    },
    validationSchema,
    onSubmit: (val) => {
      if (wfromm == "createNewBill") {
        createWareHouseByModal({ warehouseInfo: val });
      } else {
        dispatch(addWarehouse({ warehouseInfo: val, navigate }));
      }
    },
  });

  return (
    <>
      {/* <main>
        <section className="total_parent_element">
          <div className="left_parent_element">
            <div className="total_upper_left">
              <div className="logo_area">
                <img
                  style={{ width: "82%", height: "50px", margin: "auto" }}
                  src={require("../../../assets/images/bank.png")}
                />
              </div>
              <div className="nav_area">
                <div className="outr_dashboard_nav">
                  <h4>Dashboards</h4>
                  <div className="parent_nav_menu">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="nav_btm_logo">
 
              </div>
            </div>
          </div>
          <div className="right_parent_element">
            <NavBar /> */}
      <h1 style={{ textAlign: "center" }}>New Warehouse Create form</h1>

      <div className="outr-right-content">
        <div className="outr-right-content splr_frm_cntnt">
          <div className="splr_frm_ttl">
            <div className="splr_frm_main">
              <form onSubmit={handleSubmit}>
                <div className="splr_frm_flx">
                  <div className="form-group">
                    <label>Warehouse Name</label>
                    <input
                      name="warehouseName"
                      className="form-control"
                      value={values.warehouseName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.warehouseName && touched.warehouseName
                        ? errors.warehouseName
                        : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Email Id</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.email && touched.email ? errors.email : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="number"
                      name="phone"
                      className="form-control"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.phone && touched.phone ? errors.phone : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Street</label>
                    <input
                      type="text"
                      name="street"
                      className="form-control"
                      value={values.street}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.street && touched.street ? errors.street : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.city && touched.city ? errors.city : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      name="state"
                      className="form-control"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.state && touched.state ? errors.state : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Zipcode</label>
                    <input
                      type="text"
                      name="zipcode"
                      className="form-control"
                      value={values.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.zipcode && touched.zipcode
                        ? errors.zipcode
                        : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      type="text"
                      name="country"
                      className="form-control"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.country && touched.country
                        ? errors.country
                        : null}
                    </div>
                  </div>
                  {/* <div className="form-group">
                          <label>ZipCode</label>
                          <input
                            type="text"
                            name="zipCode"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div> */}
                  {/* <div className="form-group">
                          <label>Country</label>
                          <input
                            type="text"
                            name="country"
                            className="form-control"
                            onChange={changeHandler}
                          />
                        </div> */}
                  {/* <div className="form-group">
                          <label>Tax Rate</label>
                          <select className="form-control">
                            <option value="tax-rate">Tax Rate</option>
                            <option value="tax-rate">Tax Rate</option>
                            <option value="tax-rate">Tax Rate</option>
                          </select>
                        </div> */}
                  {/* <div className="form-group">
                          <label>Tax Category</label>
                          <select className="form-control">
                            <option value="tax-category">Tax Category</option>
                            <option value="tax-category">Tax Category</option>
                            <option value="tax-category">Tax Category</option>
                          </select>
                        </div> */}
                  {/* <div className="form-group">
                          <label>Status</label>
                          <div className="sppl_tgls">
                            <div className="sppl_mn_tgl">
                              <span>Inactive</span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={changeHandler}
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                            <div className="sppl_mn_tgl">
                              <span>Active</span>
                            </div>
                          </div>
                        </div> */}
                </div>
                <button
                  type="submit"
                  href="javascript:void(0);"
                  className="btn_add_supplier"
                >
                  Add Warehouse
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
        </section>
      </main> */}
    </>
  );
};

export default AddWarehouse;
