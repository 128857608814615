import {
  faClose,
  faPercent,
  faPlus,
  faSort,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Drawer,
  Paper,
  TextField,
  Select as MuiSelect,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allSuppliers } from "../../../redux/features/sellers/sellerPurchaseSlice";
import { Warehouse } from "@mui/icons-material";
import { allWarehouse } from "../../../redux/features/sellers/sellerWarehouseSlice";
import { GenereteUniqueBillNumber } from "../../../redux/features/sellers/GenereteUniqueBillNo";
import { formatToDate, paymentTypeOption } from "./ConvertToBill";
import {
  allProducts,
  allVariants,
} from "../../../redux/features/sellers/sellerProductSlice";
import Select from "react-select";
import { filterOption } from "./PurchaseOrder";
import uniqid from "uniqid";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { generateBill } from "../../../redux/features/sellers/GenerateBill";
import { useNavigate } from "react-router-dom";
import CreateNewSupplier from "../../common/openModalForm/CreateNewSupplier";
import CreateNewWareHouseByModal from "./CreateNewWareHouseByModal";
import { Bounce, toast } from "react-toastify";
import SearchingSupplier from "../../common/SearchingSupplier";
import SearchingWarehouseDropdown from "../../common/SearchingWarehouseDropdown";
import CommonMultipleFile from "../../common/CommonMultipleFile";
import { FieldArray } from "formik";
import { paymenttermOptions } from "./CreateNewPurchaseOrder";
import CommonPaymentTerm from "../../common/CommonPaymentTerm";
import CommonPaymentType from "../../common/commonDropDwon/CommonPaymentType";

const handleSelectChange = async (e, i) => {
  // const { payload } = await dispatch(allVariants(e.idd));
  // console.log(e, "abcd");
  setProductsRow((pre) => {
    let res = JSON.parse(JSON.stringify(pre));
    res[i].productId = e.idd;
    // res[i].variantOption = JSON.parse(JSON.stringify(payload));
    res[i].quantity = 1;
    res[i].unitCost = 0;
    res[i].totalCost = 0;
    res[i].variantOption = JSON.parse(JSON.stringify(e?.allvariantss));
    return res;
  });
};

const CreateNewBill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { suppliers } = useSelector((state) => state.sellerPurchase);
  const { warehouseDetail } = useSelector((state) => state.sellerWarehouse);
  const { billNumber: billno, loading: billnumberloading } = useSelector(
    (state) => state.CreateBillNumber
  );
  const { products, allVariant } = useSelector((state) => state.sellerProducts);

  // console.log(products, "abc");
  const productOptions = products?.map((v) => ({
    value: v?.productInfo?.name,
    label: v?.productInfo?.name,
    idd: v?._id,
    allvariantss: JSON.parse(JSON.stringify(v?.variants)),
  }));

  const [attachFile, setAttachFile] = useState();
  // console.log(attachFile, "abcd");
  const [allData, setAllData] = useState({
    supplierId: "",
    warehouseId: "",
    billNumber: "",
    purchaseOrderNumber: "",
    billDate: formatToDate(new Date()),
    dueDate: "",
    paymentTerm: "COD",
    paymentType: "",
    // AttachFile: "",
    discountRate: 0,
    taxType: "",
    taxRate: 0,
    // totalAmount: "",
    notes: "",
  });
  const [productsRow, setProductsRow] = useState([
    {
      productId: "",
      variantId: "",
      quantity: 1,
      unitCost: 0,
      totalCost: 0,
      variantOption: [],
      uniqkey: uniqid(),
    },
    {
      productId: "",
      variantId: "",
      quantity: 1,
      unitCost: 0,
      totalCost: 0,
      variantOption: [],
      uniqkey: uniqid(),
    },
    {
      productId: "",
      variantId: "",
      quantity: 1,
      unitCost: 0,
      totalCost: 0,
      variantOption: [],
      uniqkey: uniqid(),
    },
  ]);

  const [fileArray, setFileArray] = useState([]);
  const [picURL, setPicURL] = useState([]);
  const [removedFile, setRemovedFile] = useState([]);

  // const [openSupplier, setOpenSupplier] = useState(false);
  // const [supplierName, setsupplierName] = useState("");
  // const [openNewSupplierForm, setOpenNewSupplierForm] = useState(false);

  // const [openWareHouse, setOpenWareHouse] = useState(false);
  // const [wareHouseName, setWareHouseName] = useState("");
  // const [openNewWareHouseForm, setOpenWareHouseForm] = useState(false);

  useEffect(() => {
    // dispatch(allSuppliers());
    // dispatch(allWarehouse());
    dispatch(GenereteUniqueBillNumber());
    dispatch(allProducts());
  }, []);

  useEffect(() => {
    setAllData((pre) => {
      const res = JSON.parse(JSON.stringify(pre));
      res.billNumber = billno;
      return res;
    });
  }, [billno]);

  useEffect(() => {
    setAllData((pre) => {
      const res = JSON.parse(JSON.stringify(pre));
      const sett = new Date(res?.billDate);
      const datee =
        sett.getDate() +
        Number(
          res?.paymentTerm == "COD"
            ? 0
            : Number(res?.paymentTerm?.split(" ")[1])
        );
      sett.setDate(datee);
      res.dueDate = formatToDate(sett);
      return res;
    });
  }, [allData.billDate, allData.paymentTerm]);
  // console.log(allData, "abc");
  const handleSelectChange = async (e, i) => {
    // const { payload } = await dispatch(allVariants(e.idd));
    // console.log(e, "abcd");
    setProductsRow((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res[i].productId = e.idd;
      // res[i].variantOption = JSON.parse(JSON.stringify(payload));
      res[i].quantity = 1;
      res[i].unitCost = 0;
      res[i].totalCost = 0;
      res[i].variantOption = JSON.parse(JSON.stringify(e?.allvariantss));
      return res;
    });
  };

  const ammontWithoutDiscount = productsRow.reduce((acc, val) => {
    // console.log(val, "abc");

    return (acc += val?.totalCost);
  }, 0);

  // const ammontWithoutDiscount = fetchData?.products.reduce((acc, val) => {
  //   return (acc += val?.totalCost);
  // }, 0);
  const discountAmmount = ammontWithoutDiscount * (allData?.discountRate / 100);
  const ammountAfterDiscount = ammontWithoutDiscount - discountAmmount;
  const taxAmmount = ammountAfterDiscount * (allData?.taxRate / 100);
  const finalAmmount = ammountAfterDiscount - taxAmmount;

  const submitFromm = (receive) => {
    // if (
    //   allData?.supplierId == "" ||
    //   allData?.warehouseId == "" ||
    //   allData?.purchaseOrderNumber == "" ||
    //   allData?.paymentType == ""
    // ) {
    //   alert("dsf");
    // } else {
    // }
    const newObj = new FormData();
    for (const key in allData) {
      newObj.append(key, JSON.parse(JSON.stringify(allData[key])));
    }
    // console.log(productsRow, "abcd");
    if (attachFile) {
      // newObj.append("picURL", attachFile);
    }
    newObj.append("totalAmount", finalAmmount);
    newObj.append("products", JSON.stringify(productsRow));
    newObj.append("removedFiles", JSON.stringify(removedFile));
    if (Array.isArray(FieldArray)) {
      FieldArray.forEach((file, index) => {
        if (file instanceof File) {
          newObj.append("uploadFiles", file);
        }
      });
    }

    for (let [key, value] of newObj.entries()) {
      console.log(key, value, "222");
    }

    if (receive) {
      dispatch(
        generateBill({
          bodydata: newObj,
          withPO: "no",
          navigate,
          destination: "/purchase/listOfBill",
          receive: true,
        })
      );
    } else {
      dispatch(
        generateBill({
          bodydata: newObj,
          withPO: "no",
          navigate,
          destination: "/purchase/listOfBill",
          receive: false,
        })
      );
    }

    // dispatch(
    //   generateBill({
    //     bodydata: newObj,
    //     withPO: "no",
    //     navigate,
    //     destination: "/purchase/listOfBill",
    //   })
    // );
  };

  console.log(allData);

  // const activeSupplier = suppliers?.filter((v) => v?.status?.isActive);
  // const filterSupplierbySearch = activeSupplier?.filter((v) => {
  //   return v?.name.toLowerCase().includes(supplierName?.toLocaleLowerCase());
  // });

  // const filterWareHouseBySearech = warehouseDetail.filter((v) =>
  //   v?.warehouseName.toLowerCase().includes(wareHouseName?.toLowerCase())
  // );

  // const funtionToSend = {
  //   openNewSupplierForm,
  //   setOpenNewSupplierForm,
  //   supplierName,
  //   setsupplierName,
  //   setSuppId: (a) => {
  //     setAllData((pre) => {
  //       const res = JSON.parse(JSON.stringify(pre));
  //       res.supplierId = a;
  //       return res;
  //     });
  //   },
  //   fromm: "createNewBill",
  // };

  // const forSendingtoCreateWarehouse = {
  //   openNewWareHouseForm,
  //   setOpenWareHouseForm,
  //   wareHouseName,
  //   setWareHouseName,
  //   wfromm: "createNewBill",
  //   setWarehoue: (a) => {
  //     setAllData((pre) => {
  //       const res = JSON.parse(JSON.stringify(pre));
  //       res.warehouseId = a;
  //       return res;
  //     });
  //   },
  // };

  const setSupplier = (props) => {
    setAllData((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res.supplierId = props;
      return res;
    });
  };

  const setWarehouse = (props) => {
    setAllData((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res.warehouseId = props;
      return res;
    });
  };

  const [productName, setProductName] = useState([null, null, null]);
  const setProductNameForShow = (v, i) => {
    setProductName((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res[i] = { ...v };
      return res;
    });
  };

  const editVariant = (variantId, variants, i) => {
    const { variant } = variants.filter((v) => v?._id === variantId)[0];
    setProductsRow((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res[i].variantId = variantId;
      res[i].unitCost = Number(variant.Price);
      res[i].totalCost =
        Number(variant.Price) * Number(productsRow[i]?.quantity);
      // res[i].uniqkey = uniqid();
      return res;
    });
    if (productsRow[i].variantId == "" && i + 1 == productsRow?.length) {
      setProductsRow((pre) => {
        const res = JSON.parse(JSON.stringify(pre));

        return [
          ...res,
          {
            productId: "",
            variantId: "",
            quantity: 1,
            unitCost: 0,
            totalCost: 0,
            variantOption: [],
            uniqkey: uniqid(),
          },
        ];
      });
    }
  };

  const removeFile = (val, ind) => {
    setRemovedFile((pre) => {
      return [...pre, val?._id];
    });

    setFileArray((pre) => {
      let res = [...pre];
      let res2 = res.filter((v) => v !== val);
      // console.log(res[0] == val, "ccc");
      return [...res2];
    });
  };
  const propsToSendinCommonFile = {
    removeFile,
    setFileArray,
    setPicURL,
    fileArray,
  };

  const dataToSendPayentTerm = {
    initialValue: allData?.paymentTerm,
    setPaymentTerm: (v) => {
      setAllData((pre) => {
        let res = JSON.parse(JSON.stringify(pre));
        res.paymentTerm = v;
        return res;
      });
    },
  };

  const dataToSendPaymentType = {
    initialValue: allData?.paymentType,
    setPaymentType: (v) => {
      setAllData((pre) => {
        let res = JSON.parse(JSON.stringify(pre));
        res.paymentType = v;
        return res;
      });
    },
  };

  console.log(allData, "allData");

  return (
    <>
      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitFromm();
                  }}
                  className="bills_cnt_otr"
                >
                  <div className="bll_hdr">
                    <h2>Create New Bill</h2>
                    <span
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} size="2xl" />
                    </span>
                  </div>
                  <div className="bll_innr_c">
                    <div className="bll_l">
                      <div className="bill_l_cnt">
                        <div className="bill_l_top_bx">
                          <SearchingSupplier setSupplierId={setSupplier} />
                          <SearchingWarehouseDropdown
                            setWarehouseId={setWarehouse}
                          />
                          {/* <div className="div_row new_dev_row">
                            <label>Warehouse</label>
                            <input
                              type="text"
                              name="supplierName"
                              value={wareHouseName}
                              placeholder="Search Warehouse "
                              onBlur={() => {
                                setTimeout(() => {
                                  setOpenWareHouse(false);
                                }, 150);
                              }}
                              onClick={() => {
                                setOpenWareHouse(true);
                              }}
                              onChange={(e) => {
                                setWareHouseName(e.target.value);
                              }}
                              className="form-control div_row_nw_inpt"
                            />
                            {openWareHouse && warehouseDetail.length > 0 && (
                              <div className="div_row_drops">
                                <div className="div_row_drops_inr">
                                  <ul style={{ position: "static" }}>
                                    {filterWareHouseBySearech?.length > 0
                                      ? filterWareHouseBySearech?.map((v) => (
                                          <li
                                            className="eachLIOfSearchingDropdown"
                                            onClick={() => {
                                              setWareHouseName(
                                                v?.warehouseName
                                              );
                                              setAllData((pre) => {
                                                const res = JSON.parse(
                                                  JSON.stringify(pre)
                                                );
                                                res.warehouseId = v?._id;
                                                return res;
                                              });
                                            }}
                                            key={v?._id}
                                          >
                                            {v?.warehouseName}
                                          </li>
                                        ))
                                      : "No result found"}
                                    <li>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setOpenWareHouseForm(true);
                                        }}
                                        className="edit new_dev_row_edit"
                                      >
                                        {wareHouseName == ""
                                          ? `+ Add new warehouse`
                                          : `+ Add ${wareHouseName}`}
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div> */}
                        </div>
                      </div>
                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Bill No<span>*</span>
                          </label>
                          <div className="inp_srch">
                            <input
                              type="text"
                              disabled
                              value={
                                billnumberloading
                                  ? "wait..."
                                  : allData?.billNumber
                              }
                            />
                          </div>
                        </div>
                        <div className="div_row">
                          <label>Order No</label>
                          <div className="inp_srch">
                            <input
                              required
                              type="text"
                              onChange={(e) => {
                                setAllData((pre) => {
                                  const res = JSON.parse(JSON.stringify(pre));
                                  res.purchaseOrderNumber = e.target.value;
                                  return res;
                                });
                              }}
                              value={allData.purchaseOrderNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clum_div">
                        {/* <div className="div_row">
                          <label>
                            Payment Terms<span>*</span>
                          </label>

                          <Autocomplete
                            onBlur={(e) => {
                              if (
                                !allData.paymentTerm ||
                                allData.paymentTerm == ""
                              ) {
                                setAllData((pre) => {
                                  let res = JSON.parse(JSON.stringify(pre));
                                  res.paymentTerm = "COD";
                                  return res;
                                });
                              }
                            }}
                            options={paymenttermOptions}
                            onChange={(e, v) => {
                              setAllData((pre) => {
                                let res = JSON.parse(JSON.stringify(pre));
                                res.paymentTerm = v;
                                return res;
                              });
                            }}
                            value={allData.paymentTerm}
                            label="Select payment terms"
                            // getOptionLabel={(op) => op.displayText}
                            getOptionSelected={(option, value) =>
                              option == value
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder="Select payment terms"
                                {...params}
                              />
                            )}
                            PaperComponent={(props) => (
                              <Paper
                                {...props}
                                sx={{
                                  borderRadius: "16px",
                                  fontFamily: "source_sans_proregular",
                                  zIndex: 1,
                                }}
                              />
                            )}
                            ListboxProps={{
                              sx: {
                                fontSize: "1.5rem",
                              },
                            }}
                          />
                        </div> */}

                        <CommonPaymentTerm {...dataToSendPayentTerm} />

                        <CommonPaymentType {...dataToSendPaymentType} />

                        {/* <div className="div_row">
                          <label>
                            Payment Type<span>*</span>
                          </label>

                          <Autocomplete
                            onBlur={(e) => {
                              if (
                                !allData.paymentType ||
                                allData.paymentType == ""
                              ) {
                                setAllData((pre) => {
                                  let res = JSON.parse(JSON.stringify(pre));
                                  res.paymentType = "COD";
                                  return res;
                                });
                              }
                            }}
                            options={paymentTypeOption}
                            onChange={(e, v) => {
                              setAllData((pre) => {
                                let res = JSON.parse(JSON.stringify(pre));
                                res.paymentType = v?.value;
                                console.log(res?.paymentType, "222");
                                return res;
                              });
                            }}
                            value={allData.paymentType}
                            label="Select payment type"
                            // getOptionLabel={(op) => op.displayText}
                            getOptionSelected={(option, value) =>
                              option == value
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder="Select payment type"
                                {...params}
                              />
                            )}
                            PaperComponent={(props) => (
                              <Paper
                                {...props}
                                sx={{
                                  borderRadius: "16px",
                                  fontFamily: "source_sans_proregular",
                                  zIndex: 1,
                                }}
                              />
                            )}
                            ListboxProps={{
                              sx: {
                                fontSize: "1.5rem",
                              },
                            }}
                          />
                        </div> */}
                      </div>
                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Bill Date<span>*</span>
                          </label>
                          <div className="inp_srch">
                            <input
                              required
                              type="date"
                              name="billDate"
                              value={allData.billDate}
                              className="form-control"
                              onChange={(e) => {
                                setAllData((pre) => {
                                  const res = JSON.parse(JSON.stringify(pre));
                                  res.billDate = formatToDate(e.target.value);
                                  return res;
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="div_row">
                          <label>Due Date</label>
                          <div className="inp_srch">
                            <input
                              disabled
                              type="date"
                              name="dueDate"
                              value={allData.dueDate}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="div_row">
                        <div className="d-flex">
                          <label>Notes</label>
                          <span className="spon">
                            It will not be shown in PDF
                          </span>
                        </div>
                        <div className="inp_srch">
                          <input
                            type="text"
                            value={allData.notes}
                            onChange={(e) => {
                              setAllData((pre) => {
                                const res = JSON.parse(JSON.stringify(pre));
                                res.notes = e.target.value;
                                return res;
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="frm_row">
                        <label>Attach File to Bill</label>
                        <div className="task_dD attach">
                          <div id="dropArea" className="drag-area">
                            <p>
                              <span>
                                img
                                <img
                                  src={require("../../../assets/images/bill_imgt.png")}
                                />
                              </span>
                              Upload
                            </p>
                          </div>

                          <span
                            id="icon"
                            onClick={() => {
                              document.getElementById("fileInput").click();
                            }}
                            className="brws_btn"
                          >
                            Browse
                          </span>
                          <div>
                            <input
                              multiple
                              type="file"
                              id="fileInput"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                setAttachFile(e.target.files[0]);
                              }}
                            />
                            <div className="preview" id="preview" />
                          </div>
                        </div>
                      </div> */}
                      <CommonMultipleFile pr={propsToSendinCommonFile} />
                    </div>
                    <div className="bll_r">
                      <div className="bll_r_tp_hdr">
                        <div className="bllr_hdr">
                          <h3>Item Table</h3>
                          {/* <h3 className="h_color">
                                  <span>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </span>
                                  Bulk Action
                                </h3> */}
                        </div>
                        <div className="bll_tbl_otr">
                          <div className="bll_tbl_innr">
                            <table>
                              <thead>
                                <tr>
                                  <th>Item Details</th>
                                  {/* <th>Account</th> */}
                                  <th>Quantity</th>
                                  <th>Rate</th>
                                  {/* <th>Customer Details</th> */}
                                  <th>Amount</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {productsRow?.map((v, i) => {
                                  return (
                                    <tr key={v?.uniqkey}>
                                      <td className="img_bll">
                                        <div className="img_bll_main">
                                          <img
                                            src={require("../../../assets/images/bill_imgt.png")}
                                          />
                                        </div>
                                        <div className="cnvrt_text_ottr">
                                          <div>
                                            {/* <input
                                              className="cnvrt_text"
                                              type="text"
                                            /> */}
                                            {/* <Select
                                              filterOption={filterOption}
                                              name="product"
                                              onChange={(e) =>
                                                handleSelectChange(e, i)
                                              }
                                              options={productOptions}
                                            /> */}

                                            <Autocomplete
                                              options={productOptions}
                                              onChange={(e, v) => {
                                                setProductNameForShow(v, i);
                                                handleSelectChange(v, i);
                                              }}
                                              value={productName[i]}
                                              getOptionLabel={(op) =>
                                                op?.label ?? ""
                                              }
                                              getOptionSelected={(
                                                option,
                                                value
                                              ) => option.value == value.value}
                                              renderInput={(params) => (
                                                <TextField
                                                  placeholder="Select product"
                                                  {...params}
                                                />
                                              )}
                                              PaperComponent={(props) => (
                                                <Paper
                                                  {...props}
                                                  sx={{
                                                    borderRadius: "16px",
                                                    fontFamily:
                                                      "source_sans_proregular",
                                                  }}
                                                />
                                              )}
                                              ListboxProps={{
                                                sx: {
                                                  fontSize: "1.5rem",
                                                  height: "20rem",
                                                },
                                              }}
                                            />
                                          </div>
                                          {v?.productId != "" && (
                                            <>
                                              <MuiSelect
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                placeholder="Please select variant"
                                                sx={{
                                                  borderRadius: "9px",
                                                  height: "40px",
                                                }}
                                                value={
                                                  productsRow[i]?.variantId
                                                }
                                                MenuProps={{
                                                  MenuListProps: {
                                                    sx: {
                                                      "& .Mui-selected": {
                                                        backgroundColor:
                                                          "rgb(242, 247, 247) !important",
                                                      },
                                                    },
                                                  },
                                                }}
                                                onChange={(e) => {
                                                  editVariant(
                                                    e.target.value,
                                                    v.variantOption,
                                                    i
                                                  );
                                                }}
                                              >
                                                {v.variantOption?.map(
                                                  (eachvariant, variantind) => (
                                                    <MenuItem
                                                      value={eachvariant?._id}
                                                    >
                                                      {`Price: ${eachvariant?.variant?.Price}, Color: ${eachvariant?.variant?.Color}`}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </MuiSelect>

                                              {/* <div>
                                              <select
                                                onChange={(e) => {
                                                  const selectedOption =
                                                    e.target.selectedOptions[0];
                                                  const id =
                                                    selectedOption.value;
                                                  const price =
                                                    selectedOption.getAttribute(
                                                      "data-price"
                                                    );

                                                  if (id !== "") {
                                                    setProductsRow((pre) => {
                                                      let res = JSON.parse(
                                                        JSON.stringify(pre)
                                                      );
                                                      res[i].variantId = id;
                                                      // res[i].quantity = 1;
                                                      res[i].unitCost =
                                                        Number(price);
                                                      res[i].totalCost =
                                                        Number(price);
                                                      return res;
                                                    });

                                                    if (
                                                      productsRow[i]
                                                        .variantId == "" &&
                                                      i + 1 ==
                                                        productsRow?.length
                                                    ) {
                                                      setProductsRow((pre) => {
                                                        const res = JSON.parse(
                                                          JSON.stringify(pre)
                                                        );

                                                        return [
                                                          ...res,
                                                          {
                                                            productId: "",
                                                            variantId: "",
                                                            quantity: 1,
                                                            unitCost: 0,
                                                            totalCost: 0,
                                                            variantOption: [],
                                                            uniqkey: uniqid(),
                                                          },
                                                        ];
                                                      });
                                                    }
                                                  }
                                                }}
                                                className="cnvrt_text_slct"
                                              >
                                                <option value="">
                                                  Select variant
                                                </option>
                                                {v.variantOption?.map(
                                                  (eachvariant, variantind) => (
                                                    <option
                                                      key={eachvariant?._id}
                                                      value={eachvariant?._id}
                                                      data-price={
                                                        eachvariant?.variant
                                                          ?.Price
                                                      }
                                                    >
                                                      {`Price: ${eachvariant?.variant?.Price} Color: ${eachvariant?.variant?.Color}`}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </div> */}
                                            </>
                                          )}
                                        </div>
                                      </td>

                                      <td>
                                        <input
                                          className="cnvrt_text_nmbr"
                                          type="number"
                                          // step={1}
                                          min={1}
                                          value={v?.quantity}
                                          onChange={(e) => {
                                            setProductsRow((pre) => {
                                              let result = JSON.parse(
                                                JSON.stringify(pre)
                                              );
                                              result[i].quantity = Math.floor(
                                                Number(e.target.value)
                                              );
                                              result[i].totalCost =
                                                Math.floor(
                                                  Number(e.target.value)
                                                ) * Number(result[i].unitCost);
                                              return result;
                                            });
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="cnvrt_text_nmbr rate"
                                          type="number"
                                          min={0}
                                          value={v?.unitCost}
                                          onChange={(e) => {
                                            setProductsRow((pre) => {
                                              let res = JSON.parse(
                                                JSON.stringify(pre)
                                              );
                                              res[i].unitCost = Number(
                                                e.target.value
                                              );
                                              res[i].totalCost =
                                                Number(e.target.value) *
                                                Number(res[i].quantity);
                                              return res;
                                            });
                                          }}
                                        />

                                        {/* <div>{product.unitCost}</div> */}
                                      </td>

                                      <td>{v?.totalCost}</td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (productsRow.length > 1) {
                                            setProductsRow((pre) => {
                                              const res = JSON.parse(
                                                JSON.stringify(pre)
                                              );
                                              res.splice(i, 1);
                                              return res;
                                            });
                                          }
                                        }}
                                        className="actn_td actn_td_crs text-center"
                                      >
                                        <FontAwesomeIcon icon={faXmark} />
                                        {/* <div className="actn">
                                      <button
                                        type="button"
                                        className="actn_btn"
                                      >
                                        <FontAwesomeIcon icon={faSort} />
                                      </button>
                                    </div> */}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="bll_btm_innr">
                            <button
                              type="button"
                              className="add_neew"
                              onClick={() => {
                                setProductsRow((pre) => {
                                  const res = JSON.parse(JSON.stringify(pre));

                                  return [
                                    ...res,
                                    {
                                      productId: "",
                                      variantId: "",
                                      quantity: 1,
                                      unitCost: 0,
                                      totalCost: 0,
                                      variantOption: [],
                                      uniqkey: uniqid(),
                                    },
                                  ];
                                });
                              }}
                            >
                              Add New Row
                              <span>
                                <FontAwesomeIcon icon={faPlus} />
                              </span>
                            </button>
                            <div className="subTOTAL_otr">
                              <div className="subTToal">
                                <div className="sBTL_r">
                                  <div className="neSBta">
                                    <label>Sub Total</label>
                                  </div>
                                  <label>
                                    {ammontWithoutDiscount.toFixed(2)}
                                  </label>
                                </div>
                                <div className="sBTL_r">
                                  <div className="neSBta">
                                    <label>Discount</label>
                                    <div className="percen">
                                      <input
                                        type="number"
                                        // placeholder={0}
                                        max={100}
                                        value={allData?.discountRate}
                                        onChange={(e) => {
                                          setAllData((prev) => {
                                            let result = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            if (
                                              e.target.value >= 0 &&
                                              e.target.value < 100
                                            ) {
                                              result.discountRate = Number(
                                                e.target.value
                                              );
                                            }
                                            return result;
                                          });
                                        }}
                                      />
                                      <span>
                                        <FontAwesomeIcon icon={faPercent} />
                                      </span>
                                    </div>
                                  </div>
                                  <label>{discountAmmount.toFixed(2)} </label>
                                </div>
                                {/* <div className="sBTL_r">
                                  <div className="neSBta">
                                    <div className="rdo_bt">
                                      <input
                                        type="radio"
                                        id="TDS"
                                        name="sub_total"
                                        value="TDS"
                                        onChange={(e) => {
                                          setAllData((prev) => {
                                            let rr = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            rr.taxType = e.target.value;
                                            return rr;
                                          });
                                        }}
                                      />
                                      <label htmlFor="TDS">TDS</label>
                                    </div>
                                    <div className="rdo_bt">
                                      <input
                                        type="radio"
                                        id="TCS"
                                        name="sub_total"
                                        value="TCS"
                                        onChange={(e) => {
                                          setAllData((prev) => {
                                            let rr = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            rr.taxType = e.target.value;
                                            return rr;
                                          });
                                        }}
                                      />
                                      <label htmlFor="TCS">TCS</label>
                                    </div>
                                    {allData?.taxType !== "" && (
                                      <div className="percen">
                                        <input
                                          type="number"
                                          onChange={(e) => {
                                            setAllData((prev) => {
                                              let aa = JSON.parse(
                                                JSON.stringify(prev)
                                              );
                                              aa.taxRate = e.target.value;
                                              return aa;
                                            });
                                          }}
                                        />
                                        <span>
                                          <FontAwesomeIcon icon={faPercent} />
                                        </span>
                                      </div>
                                    )}

                                  </div>
                                  <label>{taxAmmount.toFixed(2)}</label>
                                </div> */}
                              </div>
                              <div className="sBTL_r btm">
                                <div className="neSBta">
                                  <label>Sub Total</label>
                                </div>
                                <label>{finalAmmount.toFixed(2)}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cancel__save">
                          <button
                            type="button"
                            className="srch_btn"
                            onClick={() => {
                              submitFromm(true);
                            }}
                          >
                            Save & Receive
                          </button>
                          <button type="button" className="srch_btn can">
                            Cancel
                          </button>

                          <button
                            type="submit"
                            // onClick={saveOrderBill}
                            className="srch_btn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <CreateNewSupplier pr={funtionToSend} /> */}
      {/* <CreateNewWareHouseByModal pr={forSendingtoCreateWarehouse} /> */}
    </>
  );
};

export default CreateNewBill;
