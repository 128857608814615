import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { fetchingAllBils, showEachBill } from "../../../redux/GetAllBill";
import MiniList from "../../common/MiniList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent, faXmark } from "@fortawesome/free-solid-svg-icons";
import FilterByStatus from "../../common/FilterByStatus";
import { statusOption } from "../../../routes/ListOfBill";
import {
  downloadBillInExcelFormat,
  printTheBill,
} from "../../../redux/AboutBills/PrintBill";
import CommonFileViewPage from "../../common/CommonFileViewPage";

const EachBill = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { eachBillData, showEachBillLoading } = useSelector((a) => a.AllBills);
  const {
    allBillloading,
    AllBilldata,
    currencySymbol,
    totalPaybles,
    dueToday,
    Due30days,
    overDueBils,
    searchBillByWordLoading,
    billsAfterSearchByWord,
    eachBillData,
    showEachBillLoading,
  } = useSelector((a) => a.AllBills);
  useEffect(() => {
    dispatch(fetchingAllBils());
  }, []);
  useLayoutEffect(() => {
    dispatch(showEachBill(id));
  }, [id]);
  console.log(eachBillData, "222");

  const [idOfItem, setIdOfItem] = useState(id);
  const [theStatus, setStatus] = useState("all");

  const filtredByStatus = AllBilldata?.filter((val, ind) => {
    if (theStatus === "all") {
      return val;
    } else {
      return val?.status?.split(" ")[0] == theStatus;
    }
  });

  console.log(filtredByStatus, "222");

  // useEffect(() => {
  //   dispatch(showEachBill(idOfItem)).then((res) => {});
  // }, [idOfItem]);

  const dataToSendMiniList = {
    listData: filtredByStatus,
    activeItem: idOfItem,
    loading: showEachBillLoading,
    currtype: eachBillData?.currency,
    destination: "/eachBill/",
    from: "bills",
    changeItem: (val) => {
      setIdOfItem(val);
    },
  };

  return (
    <>
      <div className="ttl_mdl"></div>
      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page bill_details_page">
                <div className="bills_cnt_otr">
                  <div className="bll_hdr">
                    <h2>Bill Details</h2>
                  </div>
                  <div className="bill_screen_top">
                    {/* select box */}
                    <FilterByStatus
                      newButtonName={"Bill"}
                      newButtonPath={"/CreateNewBill"}
                      setStatus={setStatus}
                      statusOption={statusOption}
                    />

                    <div className="bill_dwnld">
                      <div
                        className="bill_icon"
                        onClick={() => {
                          dispatch(printTheBill([id]));
                        }}
                      >
                        <span>
                          <img
                            src={require("../../../assets/images/bill_print.png")}
                          />
                        </span>
                      </div>
                      <div
                        className="bill_icon"
                        onClick={() => {
                          dispatch(downloadBillInExcelFormat([id]));
                        }}
                      >
                        <span>
                          <img
                            src={require("../../../assets/images/bill_download.png")}
                          />
                        </span>
                      </div>
                      {/* {!purchaseOrderDetail?.isBilled &&
                        purchaseOrderDetail?.isReceived != "received" && (
                          <div
                            className="bill_icon"
                            onClick={() => {
                              navigate(`/purchase/purchase-order/${idOfItem}`);
                            }}
                          >
                            <span>
                              <img
                                src={require("../../../assets/images/bill_edit.png")}
                              />
                            </span>
                          </div>
                        )} */}
                    </div>
                  </div>
                  <div className="bill_screen">
                    <MiniList pr={dataToSendMiniList} />

                    {showEachBillLoading ? (
                      <>
                        <h1
                          style={{
                            textAlign: "center",
                            width: "70%",
                            marginTop: "10%",
                          }}
                        >
                          Please wait data is fetching...
                        </h1>
                      </>
                    ) : (
                      <div className="bill_details bill_dtls_right">
                        <div className="seller_or_buyer">
                          <div className="sell_div">
                            <div className="tp_hdr">
                              <div className="s-text">
                                <h2>Seller details</h2>
                              </div>
                            </div>
                            <div className="sllr_cntnt">
                              <h3>{eachBillData?.supplierId?.name}</h3>
                              {/* <p>ABC Seller</p> */}
                              <p>{eachBillData?.supplierId?.phoneNumber}</p>
                              <p>
                                {eachBillData?.supplierId?.address?.[0]?.street}
                                {eachBillData?.supplierId?.address?.[0]?.city}
                              </p>
                              <p>
                                {
                                  eachBillData?.supplierId?.address?.[0]
                                    ?.country
                                }
                              </p>
                              <a href="javascript:void(0);">
                                {eachBillData?.supplierId?.email}
                              </a>
                            </div>
                          </div>
                          <div className="sell_div byuyerr">
                            <div className="tp_hdr">
                              {/* <div className="sllr_img">
                      <img src={require("../../../assets/images/user_img.png")} alt="user-image" />
                    </div> */}
                              <div className="s-text">
                                <h2>Buyer details</h2>
                              </div>
                            </div>
                            <div className="sllr_cntnt">
                              <h3>
                                {eachBillData?.warehouseId?.warehouseName}
                              </h3>
                              <p> {eachBillData?.warehouseId?.phone}</p>
                              <p>
                                {eachBillData?.warehouseId?.address?.street}

                                {eachBillData?.warehouseId?.address?.city}
                              </p>
                              {/* <p>Street, City</p> */}
                              <p>
                                {eachBillData?.warehouseId?.address?.country}
                              </p>
                              <a href="javascript:void(0);">
                                {eachBillData?.warehouseId?.email}
                              </a>
                            </div>
                          </div>
                          <div
                            className="seller_cross"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            {/* <i className="fa-solid fa-xmark" /> */}
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="sllr_dtl-tbl">
                          <div className="byr_div">
                            <div className="slb_div">
                              <h4>Bill Number:</h4>
                              <h4>
                                <span>{eachBillData?.billNumber}</span>
                              </h4>
                            </div>
                            <div className="slb_div">
                              <h4>Bill Date:</h4>
                              <h4>
                                <span>
                                  {" "}
                                  {/* {purchaseOrderDetail?.dateOfPurchaseOrder
                                      ?.slice(0, 10)
                                      .split("-")
                                      .reverse()
                                      .join("/")} */}
                                  {eachBillData?.billDate?.slice(0, 10)}
                                </span>
                              </h4>
                            </div>
                            <div className="slb_div">
                              <h4>Due Date :</h4>
                              <h4>
                                <span>
                                  {eachBillData?.dueDate?.slice(0, 10)}
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div className="byr_div">
                            <div className="slb_div">
                              <h4>PO Number:</h4>
                              <h4>
                                <span>{eachBillData?.purchaseOrderNumber}</span>
                              </h4>
                            </div>
                            {/* <div className="slb_div">
                                <h4>PR Number:</h4>
                                <h4>
                                  <span>SAY-2024-1</span>
                                </h4>
                              </div> */}
                            <div className="slb_div">
                              <h4>payment Term:</h4>
                              <h4>
                                <span className="status">
                                  {eachBillData?.paymentTerm}
                                </span>
                              </h4>
                            </div>
                            <div className="slb_div">
                              <h4>payment Type:</h4>
                              <h4>
                                <span className="status">
                                  {eachBillData?.paymentType}
                                </span>
                              </h4>
                            </div>
                            {/* <div className="slb_div">
                                <h4>tax Type::</h4>
                                <h4>
                                  <span>TCS</span>
                                </h4>
                              </div> */}
                          </div>
                        </div>
                        <div className="sllr_dtl-tbl btm">
                          <div className="btmm_hdr">
                            <h4>ITEMS</h4>
                            <h4>DESCRIPTION</h4>
                            <h4>QTY</h4>
                            <h4>RATE</h4>
                            <h4>Amount</h4>
                          </div>
                          {eachBillData?.products?.length > 0 &&
                            eachBillData?.products?.map((product) => {
                              return (
                                <div className="btm_cntt">
                                  <div className="div-clmn name-box">
                                    <h5>
                                      {product?.productId?.productInfo?.name}
                                    </h5>
                                  </div>
                                  <div className="div-clmn">
                                    {/* <h5>Description goes here</h5> */}
                                    <h5>
                                      Color:
                                      <span className="totaaal">
                                        {/* <span className="back_color" /> */}
                                        {product?.variantId?.variant?.Color}
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="div-clmn">
                                    {/* Extra gap before this column */}
                                    <h5>{product.quantityOrdered}</h5>
                                  </div>
                                  <div className="div-clmn">
                                    <h5>{`${eachBillData?.currency}  ${product?.unitCost} `}</h5>
                                  </div>
                                  <div className="div-clmn">
                                    <h5>{`${eachBillData?.currency}  ${product?.totalCost} `}</h5>
                                  </div>
                                </div>
                              );
                            })}
                          <br />
                          {/* <div className="subTOTAL_otr">
                            <div className="subTToal">
                              <div className="sBTL_r">
                                <div className="neSBta">
                                  <label>Sub Total</label>
                                </div>
                                <label>{`  ${eachBillData?.subTotal} `}</label>
                              </div>
                              <div className="sBTL_r">
                                <div className="neSBta">
                                  <label>Discount</label>
                                  <div className="percen">
                                    <input
                                      type="number"
                                      disabled
                                      value={eachBillData?.discountRate}
                                    />
                                    <span>
                                      <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                  </div>
                                </div>
                                <label>{eachBillData?.discountedAmount} </label>
                              </div>
                            </div>
                            <div className="sBTL_r btm">
                              <div className="neSBta">
                                <label>Sub Total</label>
                              </div>
                              <label>{eachBillData?.totalAmount}</label>
                            </div>
                          </div> */}

                          <div className="footer-bottm">
                            <div className="div-clmn">
                              <h5>Sub Total</h5>
                            </div>
                            <div className="div-clmn">
                              <h5 className="amount">
                                {`${eachBillData?.currency}  ${eachBillData?.subTotal} `}
                              </h5>
                            </div>
                          </div>
                          <div className="footer-bottm">
                            <div className="div-clmn">
                              <h5>Discount</h5>
                            </div>
                            <div className="div-clmn">
                              <h5 className="amount">
                                {`${eachBillData?.currency}  ${eachBillData?.discountedAmount} `}
                              </h5>
                            </div>
                          </div>
                          <div className="footer-bottm">
                            <div className="div-clmn">
                              <h5>Total</h5>
                            </div>
                            <div className="div-clmn">
                              <h5 className="amount">
                                {`${eachBillData?.currency}  ${eachBillData?.totalAmount} `}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <CommonFileViewPage fileArray={eachBillData?.files} />
                        {eachBillData?.status !== "paid" && (
                          <div
                            onClick={() => {
                              navigate(
                                `/recordPaymentForm/${id}/${eachBillData?.balanceDue}`
                              );
                            }}
                            className="record_pmnt"
                          >
                            <a href="javascript:void(0);" className="edit">
                              Record Payment
                            </a>
                          </div>
                        )}
                        {/* {eachBillData?.isReceived !== "received" &&
                          eachBillData?.status !== "draft" && (
                            <div className="record_pmnt">
                              <a href="javascript:void(0);" className="edit">
                                Receive
                              </a>
                            </div>
                          )}
                        {!eachBillData?.isBilled &&
                          eachBillData?.status !== "draft" && (
                            <div className="record_pmnt">
                              <a href="javascript:void(0);" className="edit">
                                Convet to Bill
                              </a>
                            </div>
                          )} */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* <div className="outr-right-content">
        <div className="outr-right-content splr_frm_cntnt nw_prchs_ordr">
          <div className="splr_frm_ttl">
            <div className="splr_frm_main">
              <form>
                <div className="splr_frm_flx">
                  <div className="prch_ordtl_flx">
                    <div className="form-group">
                      <div className="td_hdr">
                        <h3>Bill Details</h3>
                      </div>
                      <h4>
                        balanceDue :<span>{eachBillData?.balanceDue}</span>
                      </h4>
                      <h4>
                        billDate :
                        <span>{eachBillData?.billDate?.slice(0, 10)}</span>
                      </h4>
                      <h4>
                        billNumber :<span>{eachBillData?.billNumber}</span>
                      </h4>
                      <h4>
                        discountRate :<span>{eachBillData?.discountRate}</span>
                      </h4>
                      <h4>
                        discountedAmount :
                        <span>{eachBillData?.discountedAmount}</span>
                      </h4>
                      <h4>
                        dueDate :
                        <span>{eachBillData?.dueDate?.slice(0, 10)}</span>
                      </h4>
                      <h4>
                        paymentTerm :<span>{eachBillData?.paymentTerm}</span>
                      </h4>
                      <h4>
                        purchaseOrderNumber :
                        <span>{eachBillData?.purchaseOrderNumber}</span>
                      </h4>
                      <h4>
                        status :<span>{eachBillData?.status}</span>
                      </h4>

                      <h4>
                        taxDeductedAmount :
                        <span>{eachBillData?.taxDeductedAmount}</span>
                      </h4>
                      <h4>
                        taxRate :<span>{eachBillData?.taxRate}</span>
                      </h4>
                      <h4>
                        taxType :<span>{eachBillData?.taxType}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="prchs_itm_tbl">
              <table>
                <thead>
                  <tr>
                    <th>ITEMS &amp; DESCRIPTION</th>
                    <th>ORDERED QUANTITY</th>
                    <th>RATE</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {eachBillData?.products?.length > 0 &&
                    eachBillData?.products?.map((product) => {
                      return (
                        <tr key={product?._id}>
                          <td>
                            {product?.productId.productInfo.name}
                            <br />
                            {`Price: ${product?.variantId.variant.Price}`}{" "}
                            {`Color: ${product?.variantId.variant.Color}`}
                          </td>
                          <td>{product?.quantityOrdered}</td>
                          <td>{product?.unitCost}</td>
                          <td>{product?.totalCost}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="prchs_rows">
              <div className="prchs_ordr_bl_ttl">
                <div className="prchs_ordr_bl_bttm">
                  <div className="prchs_ttl prchs_ordr_bl">
                    <span className="prchs_ordr_bl_left">Total</span>
                    <span className="prchs_ordr_bl_right">
                      ₹{eachBillData?.subTotal}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {eachBillData?.status !== "paid" && (
            <NavLink
              className="edit"
              to={`/recordPaymentForm/${id}/${eachBillData?.balanceDue}`}
            >
              Record Payment
            </NavLink>
          )}
        </div>
      </div> */}
    </>
  );
};

export default EachBill;
