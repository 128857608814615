import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AdminPrivate = () => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  if (token && user == "admin") {
    return <Outlet />;
  } else {
    return <Navigate to={"/login/admin"} />;
  }
  //   return <>AdminPrivate</>;
};

export default AdminPrivate;
