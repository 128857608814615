import { Bounce, toast } from "react-toastify";

export const successToast = (messege) =>
  toast.success(messege, {
    className: "toast-message",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Bounce,
  });

export const errorToast = (messege) =>
  toast.error(messege, {
    className: "toast-message",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Bounce,
  });
