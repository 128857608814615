import { Autocomplete, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export const paymentModeOptions = [
  "Cash",
  "Bank Transfer",
  "Cheque",
  "Credit Card",
  "UPI",
];
const CommonPaymentModeDropDown = ({
  initialPaymentMode,
  setPaymentModefn,
}) => {
  //   const [paymentMode, setPaymentMode] = useState(initialPaymentMode || "UPI");
  //   useEffect(() => {
  //     console.log(paymentMode, "paymentMode");
  //   }, [paymentMode]);
  return (
    <>
      <Autocomplete
        onBlur={(e) => {
          if (!initialPaymentMode) {
            setPaymentModefn(paymentModeOptions[0]);
          }
        }}
        options={paymentModeOptions}
        onChange={(e, v) => {
          setPaymentModefn(v);
        }}
        value={initialPaymentMode}
        label="Select payment mode"
        // getOptionLabel={(op) => op.displayText}
        getOptionSelected={(option, value) => option == value}
        renderInput={(params) => (
          <TextField placeholder="Select payment mode" {...params} />
        )}
        PaperComponent={(props) => (
          <Paper
            {...props}
            sx={{
              borderRadius: "16px",
              fontFamily: "source_sans_proregular",
              zIndex: 1,
            }}
          />
        )}
        ListboxProps={{
          sx: {
            fontSize: "1.5rem",
          },
        }}
      />
    </>
  );
};

export default CommonPaymentModeDropDown;
