import { Autocomplete, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useLazyGetOptionsQuery,
  useUpdatePaymentTypeArrayMutation,
} from "../../../redux/reduxNeeded/commonApi";
import Swal from "sweetalert2";
import { message } from "antd";

const CommonPaymentType = ({ initialValue, setPaymentType }) => {
  const [allpaymentTypeOptions, setAllPaymentTypeOptions] = useState([]);

  const [
    updatePaymentType,
    { isSuccess: isPaymentTypeSuccess, isError: isPaymentTypeError },
  ] = useUpdatePaymentTypeArrayMutation();

  const [getOptions, { data: theOptions, isSuccess: allOptionsFlag }] =
    useLazyGetOptionsQuery();
  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (allOptionsFlag) {
      setAllPaymentTypeOptions([...theOptions?.paymentTypes]);
    }
  }, [theOptions]);

  return (
    <>
      <div className="div_row">
        <label>
          Payment Type<span>*</span>
        </label>

        <Autocomplete
          onBlur={(e) => {
            if (!initialValue || initialValue == "") {
              setPaymentType("COD");
            }
          }}
          options={[...allpaymentTypeOptions]}
          onChange={(e, v) => {
            setPaymentType(v);
          }}
          // onChange funtionalities not completed yet billow.
          //   onChange={(e, v) => {
          //     if (v === "Create Custom Payment Type") {
          //       Swal.fire({
          //         title: "Create your custom payment type .",
          //         input: "text",
          //         // inputValue: 120,
          //         inputAttributes: {
          //           autocapitalize: "off",
          //         },
          //         showCancelButton: true,
          //         confirmButtonText: "Save",
          //         showLoaderOnConfirm: true,

          //         preConfirm: async (data) => {
          //           try {
          //             const res = await updatePaymentType({
          //               paymentTypes: [...allpaymentTypeOptions, data],
          //             }).unwrap();
          //             return { data: data, flag: true };
          //           } catch (error) {
          //             return { flag: false };
          //           }
          //         },
          //         allowOutsideClick: () => !Swal.isLoading(),
          //       }).then((result) => {
          //         console.log(result, isPaymentTypeSuccess, "result");
          //         if (result?.value?.flag) {
          //           setPaymentType(result?.value?.data?.trim());
          //           Swal.fire({
          //             title: ` Created new option  ${result.value.data}`,
          //           });
          //         } else {
          //           Swal.fire({
          //             title:
          //               isPaymentTypeError?.message || `Something went wrong`,
          //           });
          //         }
          //       });

          //       // setCustomTermModalOpen(true);
          //     } else {
          //       setPaymentType(v);
          //     }
          //   }}
          value={initialValue}
          label="Select payment type"
          // getOptionLabel={(op) => op.displayText}
          getOptionSelected={(option, value) => option == value}
          renderInput={(params) => (
            <TextField placeholder="Select payment type" {...params} />
          )}
          PaperComponent={(props) => (
            <Paper
              {...props}
              sx={{
                borderRadius: "16px",
                fontFamily: "source_sans_proregular",
                zIndex: 1,
              }}
            />
          )}
          ListboxProps={{
            sx: {
              fontSize: "1.5rem",
            },
          }}
        />
      </div>
    </>
  );
};

export default CommonPaymentType;
