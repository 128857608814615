import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createNewPRApi, downloadPRApi, downloadPRInExcelApi, filterPOReceiveApi, filterPRByWordApi, getPONumberAginstVendorNameApi } from "../../../constants/Api/Api";
import { errorToast, successToast } from "../../../components/common/ToastMessege";
import { downloadPDF } from "./PrintPurchaseOrder";
// import state from "sweetalert/typings/modules/state";



export const filteringPurchaseReceive=createAsyncThunk('filteringPurchaseReceive',async({PRStatus,word,startDate,endDate},thunkAPI)=>{
    try {
         const res=await axios.get(`${filterPOReceiveApi}prStatus=${PRStatus}&startDate=${startDate}&endDate=${endDate}`,{
            headers:{Authorization:"Bearer "+localStorage.getItem('token')}
         })
      return res.data
    } catch (error) {
        errorToast(error?.response?.data?.message)
        return thunkAPI.rejectWithValue({data:[]})
    }
})


export const filterPRByNameNo=createAsyncThunk('filterByVendorNameAndPoNo',async(data,thunkAPI)=>{
    try {
        console.log(data,'222')
        const res=await axios.get(`${filterPRByWordApi}?word=${data}`,{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })
        console.log(res?.data,'222')
        return res.data
    } catch (error) {
    //  console.warn(error,'aaa');
     return thunkAPI.rejectWithValue()  
    }
}) 


export const printPR=createAsyncThunk('downloadPRInPdf',async(data,thunkAPI)=>{
    try {
        const res=await axios.post(`${downloadPRApi}`,{purchaseReceiveIds:[...data] },{
            headers:{Authorization:"Bearer "+localStorage.getItem('token')}
        })
      if (res?.data?.success) {
        // successToast(res?.data?.message)
        downloadPDF(res?.data?.fileUrl)
      }
    } catch (error) {
        console.warn(error);        
    }
})


export const downloadPRInExcel=createAsyncThunk('downloadPRInExcel',async(data,thunkAPI)=>{
    try {
        console.log(data,'222')
        const res=await axios.post(downloadPRInExcelApi,{
            purchaseReceiveIds:[...data]
        },{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })

        if (res?.data?.success) {
            // window.open(res.data.fileUrl);
            const fileURL = res.data.fileUrl;

        // Create a link element
        const link = document.createElement('a');
        link.href = fileURL;

        // Extract the filename from the URL (or you can set it manually)
        const fileName = fileURL.split('/').pop();
        link.download = fileName;

        // Programmatically trigger the download
        link.click();
         }
    } catch (error) {
        console.warn(error);
    }
})

export const pendingPoByName=createAsyncThunk("GetALLPoNumberByName",async(data,thunkAPI)=>{

    try {
        const res = await axios.get(`${getPONumberAginstVendorNameApi}${data}`,{headers:{
            Authorization:'Bearer '+localStorage.getItem('token') 
        }})
        console.log(res.data,'222')
            return res?.data   
    } catch (error) {
        // errorToast('No Pending Order found ')
       return thunkAPI.rejectWithValue({data:[]})
        
    }
})



export const createNewPurchaseReceive=createAsyncThunk('createNewPurchaseReceive',async ({newObj,navigate},thunkAPI)=>{
    try {
        console.log(newObj)
        const res=await axios.post(createNewPRApi,newObj,{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })
       if (res?.data?.success) {
    swal("Done!",res?.data.message, "success");
         
        navigate('/purchaseRecives')
       }
    } catch (error) {
        errorToast(error?.response?.data?.message)                
    }
})



 export const purchaseReceiveStates=createSlice({
    name:'purchaseReceiveStates',
    initialState:{
        dataAfetFiler:[],
        PRLoading:false,
        PRTypingLoading:false,
        PRAfterSearchByWord:[],
        pendingPoStatus:false,
        pendingPoList:[],
        detailsViaNameInPR:undefined,
        creatingNewPRLoading:false
    },

    extraReducers:(builder)=>{
           builder.addCase(filteringPurchaseReceive.pending,(state,action)=>{
               state.PRLoading=true
           }).addCase(filteringPurchaseReceive.fulfilled,(state,action)=>{
               state.PRLoading=false
            //   console.log(action?.payload,'222')
            state.dataAfetFiler=action?.payload?.data
           }).addCase(filteringPurchaseReceive.rejected,(state,action)=>{
               state.PRLoading=false
               state.dataAfetFiler=[]
           })

           builder.addCase(filterPRByNameNo.pending,(state,action)=>{
                       state.PRTypingLoading=true
                   }).addCase(filterPRByNameNo.fulfilled,(state,action)=>{
                       state.PRTypingLoading=false
                       state.PRAfterSearchByWord=action.payload.data
                   }).addCase(filterPRByNameNo.rejected,(state,action)=>{
                       state.PRTypingLoading=false
                       state.PRAfterSearchByWord=[]
                   })

             builder.addCase(pendingPoByName.pending,(state,action)=>{
                state.pendingPoStatus=true
             }).addCase(pendingPoByName.fulfilled,(state,action)=>{
                state.pendingPoStatus=false
                state.pendingPoList=action?.payload?.pendingPOs
                state.detailsViaNameInPR=action?.payload?.supplierDetails                
             })  .addCase(pendingPoByName.rejected,(state,action)=>{
                state.pendingPoStatus=false
                state.pendingPoList=action?.payload?.data             
                state.detailsViaNameInPR=undefined         
             })  
             
             
             builder.addCase(createNewPurchaseReceive.pending,(state,action)=>{
                state.creatingNewPRLoading=true
             }).addCase(createNewPurchaseReceive.fulfilled,(state,action)=>{
                state.creatingNewPRLoading=false
                state.pendingPoList=[]
                state.detailsViaNameInPR=action?.payload?.supplierDetails                
             })  .addCase(createNewPurchaseReceive.rejected,(state,action)=>{
                state.creatingNewPRLoading=false
                state.pendingPoList=[]          
                state.detailsViaNameInPR=undefined         
             })     
        }
})

