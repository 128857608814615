import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SearchingDropdownForFilter = ({ pr, comesFrom }) => {
  const { word, setWord, showDataOfSearch, typingLoading, viewPage } = pr;
  const navigate = useNavigate();

  // const {
  //   filteredPurchaseOrderList,
  //   PurchaseOrderListLoading,
  //   showDataOfSearch,
  //   typingLoading,
  //   currency,
  // } = useSelector((a) => a.FilterPurchaseOrderListt);
  return (
    <>
      <div className="div_row new_dev_row ">
        <div className="drv_rght_rte">
          <input
            type="search"
            placeholder="Search vendor name"
            onChange={(e) => {
              if (e.target.value.trim().length >= 3) {
                setWord(e.target.value.trim());
              } else {
                setWord("");
              }
            }}
          />
        </div>
        {word.length >= 3 && (
          <div className="div_row_drops new_drop_r">
            <div className="div_row_drops_inr">
              <ul
                style={{
                  position: "static",
                  backgroundColor: "#f8f9fd",
                }}
              >
                {typingLoading ? (
                  <li className="eachLIOfSearchingDropdown">loading ...</li>
                ) : showDataOfSearch?.length > 0 ? (
                  showDataOfSearch?.map((v) => (
                    <li
                      className="eachLIOfSearchingDropdown"
                      key={v?._id}
                      onClick={() => {
                        navigate(`${viewPage}${v?._id}`);
                      }}
                    >
                      {/* {`${v?.supplierId?.name} ${v?.purchaseOrderNumber}`} */}
                      {v?.supplierId?.name}
                      <br />
                      {v?.paymentNumber}
                      {comesFrom == "bill"
                        ? v?.billNumber
                        : v?.purchaseOrderNumber}
                    </li>
                  ))
                ) : (
                  <li className="eachLIOfSearchingDropdown">
                    No result found.
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchingDropdownForFilter;
