import * as rules from "yup";

export const validationSchemaa = rules.object().shape({
  name: rules
    .string()
    .required("Please type Supplier name")
    .min(3, "3 charecters needed"),
  // .matches(/^[A-Za-z\s]+$/, "Name must only contain alphabetic characters"),
  email: rules
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  countryCode: rules.string().required("Please type Supplier name"),
  phoneNumber: rules
    .string()
    .matches(
      /^(?:\(\d{3}\)\s?|\d{3}[-.\s]?)(\d{3})[-.\s]?(\d{4})$/,
      "Invalid phone number format"
    )
    .required("Phone number is required"),
  typee: rules.string().required("Please type Address type ."),
  street: rules.string().required("Please type the name of street"),
  city: rules.string().required("Please type the name of city"),
  state: rules.string().required("Please type the name of state"),
  country: rules.string().required("Please type the name of country"),
  zipCode: rules
    .string()
    .matches(/^\d{6}$/, "Zip code must be exactly 6 digits")
    .required("Zip code is required"),
  isActive: rules.boolean(),
});
