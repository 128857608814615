import {
  faClose,
  faPaperclip,
  faPercent,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Select as AntdSelect, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatToDate } from "./ConvertToBill";
import Select from "react-select";
import { filterOption } from "./PurchaseOrder";
import uniqid from "uniqid";
import {
  allSuppliers,
  createPurchaseOrder,
  POdetail,
} from "../../../redux/features/sellers/sellerPurchaseSlice";
import { allWarehouse } from "../../../redux/features/sellers/sellerWarehouseSlice";
import { allProducts } from "../../../redux/features/sellers/sellerProductSlice";
import { GeneratePurchaseOrderNumber } from "../../../redux/features/sellers/GenetatePurchaseOrderNumber";
import SearchingSupplier from "../../common/SearchingSupplier";
import SearchingWarehouseDropdown from "../../common/SearchingWarehouseDropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  MenuItem,
  Paper,
  TextField,
  Select as MuiSelect,
  Menu,
  Modal,
  Typography,
  Button,
} from "@mui/material";
import { editPurchaseOrder } from "../../../redux/features/sellers/EditPurchaseOrder";
import CommonMultipleFile from "../../common/CommonMultipleFile";
import CommonPaymentTerm from "../../common/CommonPaymentTerm";

export const paymenttermOptions = ["COD", "Net 30", "Net 45", "Net 60"];
const CreateNewPurchaseOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    // dispatch(allSuppliers());
    // dispatch(allWarehouse());
    if (id != "new") {
      dispatch(POdetail(id));
    }
    dispatch(allProducts());
    dispatch(GeneratePurchaseOrderNumber());
  }, []);
  const { products, allVariant } = useSelector((state) => state.sellerProducts);
  const { loading: PoLoading, PONum } = useSelector(
    (a) => a.GeneratNewPurchaseOrder
  );
  const { loading, purchaseOrderDetail } = useSelector(
    (state) => state.sellerPurchase
  );
  const [customTermModalOpen, setCustomTermModalOpen] = useState(false);
  const [customTerm, setCustomTerm] = useState("");
  const [picURL, setPicURL] = useState([]);
  const [orderDetail, setOrderDetail] = useState({
    supplierId: "",
    warehouseId: "",
    purchaseOrderNumber: "",
    dateOfPurchaseOrder: formatToDate(new Date()),
    expectedDeliveryDate: "",
    paymentTerm: "COD",
    // paymentType: "",
    notes: "",
  });
  const [productName, setProductName] = useState([null, null, null]);
  const setProductNameForShow = (v, i) => {
    setProductName((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res[i] = { ...v };
      return res;
    });
  };

  const handleCustomTermSave = () => {
    setOrderDetail((prev) => ({
      ...prev,
      paymentTerm: customTerm,
    }));
    setCustomTermModalOpen(false); // Close the modal
    setCustomTerm(""); // Reset the input
  };

  const [productsRow, setProductsRow] = useState([
    {
      productId: "",
      variantId: "",
      quantity: 1,
      unitCost: 0,
      totalCost: 0,
      uniqkey: uniqid(),
    },
    {
      productId: "",
      variantId: "",
      quantity: 1,
      unitCost: 0,
      totalCost: 0,
      uniqkey: uniqid(),
    },
    {
      productId: "",
      variantId: "",
      quantity: 1,
      unitCost: 0,
      totalCost: 0,
      uniqkey: uniqid(),
    },
  ]);

  const [fileArray, setFileArray] = useState([]);
  const [removedFile, setRemovedFile] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setOrderDetail((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res.purchaseOrderNumber = PONum;
      return res;
    });
  }, [PONum]);
  const productOptions = products?.map((v) => ({
    value: v?.productInfo?.name,
    label: v?.productInfo?.name,
    idd: v?._id,
    allvariantss: JSON.parse(JSON.stringify(v?.variants)),
  }));

  const allProductOptions = products.map((v) => ({
    value: v?._id,
    displayText: v?.productInfo?.name,
  }));
  useEffect(() => {
    // console.log(purchaseOrderDetail, "ddd");
    if (id != "new" && purchaseOrderDetail) {
      setFileArray(purchaseOrderDetail?.files);
      let prow = [];
      prow = purchaseOrderDetail?.products?.reduce((acc, eachval) => {
        return [
          ...acc,
          {
            productId: eachval?.productId?._id,
            variantId: eachval?.variantId?._id,
            quantity: eachval?.quantity,
            unitCost: eachval?.unitCost,
            totalCost: eachval?.totalCost,
            variantOption: JSON.parse(
              JSON.stringify(eachval?.productId?.variants)
            ),
            uniqkey: eachval?.variantId?._id,
          },
        ];
      }, []);
      setProductsRow(JSON.parse(JSON.stringify(prow)));

      let forsetSelectdproducts;
      forsetSelectdproducts = purchaseOrderDetail?.products?.reduce(
        (acc, eachvalue) => {
          return [
            ...acc,
            {
              idd: eachvalue.productId._id,
              value: eachvalue?.productId.productInfo.name,
              label: eachvalue?.productId.productInfo.name,
            },
          ];
        },
        []
      );
      setProductName(JSON.parse(JSON.stringify(forsetSelectdproducts)));

      setOrderDetail({
        supplierId: purchaseOrderDetail?.supplierId?._id,
        warehouseId: purchaseOrderDetail?.warehouseId?._id,
        purchaseOrderNumber: purchaseOrderDetail?.purchaseOrderNumber,
        dateOfPurchaseOrder: formatToDate(
          purchaseOrderDetail?.dateOfPurchaseOrder.slice(0, 10)
        ),
        expectedDeliveryDate: formatToDate(
          purchaseOrderDetail?.expectedDeliveryDate.slice(0, 10)
        ),
        paymentTerm: purchaseOrderDetail?.paymentTerm,
        notes: purchaseOrderDetail?.notes,
      });
    }
  }, [purchaseOrderDetail]);

  const handleSelectChange = async (e, i) => {
    setProductsRow((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res[i].productId = e?.idd;
      res[i].quantity = 1;
      res[i].unitCost = 0;
      res[i].totalCost = 0;
      res[i].variantOption = JSON.parse(JSON.stringify(e?.allvariantss ?? []));
      return res;
    });
  };
  const ammontWithoutDiscount = productsRow.reduce((acc, val) => {
    return (acc += val?.totalCost);
  }, 0);

  const submitFromm = (e, typee) => {
    const newObj = new FormData();
    for (const key in orderDetail) {
      newObj.append(key, orderDetail[key]);
    }
    newObj.append("products", JSON.stringify(productsRow));
    newObj.append("totalAmount", ammontWithoutDiscount.toFixed(2));
    // newObj.append("removedFile", removedFile);
    // newObj.append("uploadFiles", picURL);
    newObj.append("removedFiles", JSON.stringify(removedFile));
    if (Array.isArray(fileArray)) {
      fileArray.forEach((file, index) => {
        if (file instanceof File) {
          newObj.append("uploadFiles", file);
        }
      });
    }

    // if (Array.isArray(picURL)) {
    //   picURL.forEach((file, index) => {
    //     if (file instanceof File) {
    //       newObj.append("picURL", file); // Append each file
    //     } else {
    //       console.error(`picURL[${index}] is not a valid File object.`);
    //     }
    //   });
    // }

    // newObj.append("totalAmount", ammontWithoutDiscount);

    for (let [key, value] of newObj.entries()) {
      // console.log(key, value, "222");
    }
    if (id == "new") {
      dispatch(createPurchaseOrder({ newObj, navigate, typee }));
    } else {
      dispatch(editPurchaseOrder({ newObj, navigate, typee, idOfPO: id })).then(
        (a) => {
          navigate(-1);
        }
      );
    }
  };

  const setSupplier = (props) => {
    setOrderDetail((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res.supplierId = props;
      return res;
    });
  };
  const setWarehouse = (a) => {
    setOrderDetail((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res.warehouseId = a;
      return res;
    });
  };
  // console.log(productsRow, orderDetail, "abc");
  // console.log(picURL, "dddd");
  const removeFile = (val, ind) => {
    setRemovedFile((pre) => {
      return [...pre, val?._id];
    });

    setFileArray((pre) => {
      let res = [...pre];
      let res2 = res.filter((v) => v !== val);
      // console.log(res[0] == val, "ccc");
      return [...res2];
    });
  };
  const variantChange = (b, i) => {
    setProductsRow((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res[i].variantId = b?._id;
      res[i].unitCost = Number(b?.details?.variant?.Price);
      res[i].totalCost = Number(b?.details?.variant?.Price);
      return res;
    });
  };

  // const [test, settest] = useState("sayan");
  const editVariant = (variantId, variants, i) => {
    const { variant } = variants.filter((v) => v?._id === variantId)[0];
    setProductsRow((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res[i].variantId = variantId;
      res[i].unitCost = Number(variant.Price);
      res[i].totalCost =
        Number(variant.Price) * Number(productsRow[i]?.quantity);
      // res[i].uniqkey = uniqid();
      return res;
    });
    if (productsRow[i].variantId == "" && i + 1 == productsRow?.length) {
      setProductsRow((pre) => {
        const res = JSON.parse(JSON.stringify(pre));

        return [
          ...res,
          {
            productId: "",
            variantId: "",
            quantity: 1,
            unitCost: 0,
            totalCost: 0,
            variantOption: [],
            uniqkey: uniqid(),
          },
        ];
      });
    }
  };
  const openFileList = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const propsToSendinCommonFile = {
    removeFile,
    setFileArray,
    setPicURL,
    fileArray,
  };

  console.log(orderDetail, "orderDetail");

  const dataToSendPayentTerm = {
    initialValue: orderDetail?.paymentTerm,
    setPaymentTerm: (v) => {
      setOrderDetail((pre) => {
        let res = JSON.parse(JSON.stringify(pre));
        res.paymentTerm = v;
        return res;
      });
    },
    id,
    selectedPyamentTerm: purchaseOrderDetail?.paymentTerm,
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFileList}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fileArray.length > 0 &&
          fileArray?.map((v, i) => (
            <MenuItem
              key={v?._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "30rem",
                fontSize: "1.5rem",
              }}
            >
              <span key={v?._id}>{v?.name}</span>
              <button
                type="button"
                onClick={() => {
                  removeFile(v, i);
                }}
              >
                {/* <FontAwesomeIcon icon={faXmark} /> */}
                <DeleteIcon />
              </button>
            </MenuItem>
          ))}
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
      </Menu>
      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page">
                <form
                  onSubmit={(e) => {
                    // const filess = e.target.images.files;
                    // console.log(filess, "dddd");
                    e.preventDefault();
                    submitFromm(e, "no");
                  }}
                  className="bills_cnt_otr"
                >
                  <div className="bll_hdr">
                    {id == "new" ? (
                      <h2>Create new purchase order</h2>
                    ) : (
                      <h2>Edit purchase order</h2>
                    )}
                    <span
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} size="2xl" />
                    </span>
                  </div>
                  <div className="bll_innr_c">
                    <div className="bll_l">
                      <div className="bill_l_cnt">
                        <div className="bill_l_top_bx">
                          {id == "new" ? (
                            <>
                              <SearchingSupplier setSupplierId={setSupplier} />
                              <SearchingWarehouseDropdown
                                setWarehouseId={setWarehouse}
                              />
                            </>
                          ) : (
                            <>
                              <SearchingSupplier
                                setSupplierId={setSupplier}
                                supname={purchaseOrderDetail?.supplierId?.name}
                              />
                              <SearchingWarehouseDropdown
                                setWarehouseId={setWarehouse}
                                initialwarehouseName={
                                  purchaseOrderDetail?.warehouseId
                                    ?.warehouseName
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Purchase order number<span>*</span>
                          </label>
                          <div className="inp_srch">
                            <input
                              type="text"
                              disabled
                              value={orderDetail?.purchaseOrderNumber}
                            />
                          </div>
                        </div>
                        {/* <div className="div_row">
                          <label>Order No</label>
                          <div className="inp_srch">
                            <input
                              required
                              type="text"
                            />
                          </div>
                        </div> */}

                        <CommonPaymentTerm {...dataToSendPayentTerm} />

                        {/* <div className="div_row">
                          <label>
                            Payment Terms<span>*</span>
                          </label>
                          <Autocomplete
                            onBlur={(e) => {
                              if (orderDetail.paymentTerm == "") {
                                setOrderDetail((pre) => {
                                  let res = JSON.parse(JSON.stringify(pre));
                                  if (id != "new") {
                                    res.paymentTerm =
                                      purchaseOrderDetail?.paymentTerm;
                                  } else {
                                    res.paymentTerm = "COD";
                                  }
                                  return res;
                                });
                              }
                            }}
                            options={[
                              ...paymenttermOptions,
                              "Create custom payment term",
                            ]}
                            onChange={(e, v) => {
                              if (v === "Create custom payment term") {
                                setCustomTermModalOpen(true); // Open modal for custom input
                              } else {
                                setOrderDetail((pre) => {
                                  let res = JSON.parse(JSON.stringify(pre));
                                  res.paymentTerm = v;
                                  return res;
                                });
                              }
                            }}
                            value={orderDetail.paymentTerm}
                            label="Select payment terms"
                            // getOptionLabel={(op) => op.displayText}
                            getOptionSelected={(option, value) =>
                              option == value
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder="Select payment terms"
                                {...params}
                              />
                            )}
                            PaperComponent={(props) => (
                              <>
                                <Paper
                                  {...props}
                                  sx={{
                                    borderRadius: "16px",
                                    fontFamily: "source_sans_proregular",
                                    zIndex: 1,
                                  }}
                                />
                              </>
                            )}
                            ListboxProps={{
                              sx: {
                                fontSize: "1.5rem",
                              },
                            }}
                          />
                        </div> */}
                      </div>

                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Purchase Order Date <span>*</span>
                          </label>
                          <div className="inp_srch">
                            <input
                              required
                              type="date"
                              name="billDate"
                              value={orderDetail.dateOfPurchaseOrder}
                              className="form-control"
                              onChange={(e) => {
                                setOrderDetail((pre) => {
                                  let res = JSON.parse(JSON.stringify(pre));
                                  res.dateOfPurchaseOrder = formatToDate(
                                    e.target.value
                                  );
                                  return res;
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="div_row">
                          <label>Expected Delivery Date</label>
                          <div className="inp_srch">
                            <input
                              required
                              type="date"
                              name="dueDate"
                              value={orderDetail.expectedDeliveryDate}
                              onChange={(e) => {
                                setOrderDetail((pre) => {
                                  let res = JSON.parse(JSON.stringify(pre));
                                  res.expectedDeliveryDate = formatToDate(
                                    e.target.value
                                  );
                                  return res;
                                });
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clum_div">
                        {/* <div className="div_row">
                          <label>
                            Payment Type<span>*</span>
                          </label>

                          <div className="inp_srch drp_dn">
                            <select
                              required
                              onChange={(e) => {
                                setOrderDetail((pre) => {
                                  let res = JSON.parse(JSON.stringify(pre));
                                  res.paymentType = e.target.value;
                                  return res;
                                });
                              }}
                            >
                              <option value="">Select payment types</option>
                              <option value="COD">COD</option>
                              <option value="cash">Cash</option>
                              <option value="credit">Credit</option>
                              <option value="debit">Debit</option>
                              <option value="check">Check</option>
                            </select>
                          </div>
                        </div> */}
                      </div>
                      <div className="div_row">
                        <div className="d-flex">
                          <label>Notes</label>
                          {/* <span className="spon">
                            It will not be shown in PDF
                          </span> */}
                        </div>
                        <div className="inp_srch">
                          <input
                            type="text"
                            value={orderDetail?.notes}
                            onChange={(e) => {
                              setOrderDetail((pre) => {
                                let res = JSON.parse(JSON.stringify(pre));
                                res.notes = e.target.value;
                                return res;
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="frm_row">
                        <label>Attach File to Bill</label>
                        <div className="task_dD attach">
                          <div
                            onClick={() => {
                              document.getElementById("fileInput").click();
                            }}
                            id="dropArea"
                            className="drag-area"
                          >
                            <p>
                              <span>
                                <img
                                  src={require("../../../assets/images/upld.png")}
                                />
                              </span>
                              Upload
                            </p>
                          </div>
                          <br />
                          <br />
                          <span
                            id="icon"
                            onClick={(event) => {
                              if (fileArray.length > 0) {
                                setAnchorEl(event.currentTarget);
                              }
                            }}
                            className="brws_btn"
                          >
                            <FontAwesomeIcon icon={faPaperclip} />
                            {`   (${fileArray.length})`}
                          </span>
                          <div>
                            <input
                              multiple
                              name="images"
                              type="file"
                              id="fileInput"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                setPicURL((pre) => [
                                  ...pre,
                                  ...Object.values(e.target.files),
                                ]);
                                setFileArray((pre) => [
                                  ...pre,
                                  ...Object.values(e.target.files),
                                ]);
                              }}
                            />
                            <div className="preview" id="preview" />
                          </div>
                        </div>
                      </div> */}
                      <CommonMultipleFile pr={propsToSendinCommonFile} />
                    </div>
                    <div className="bll_r">
                      <div className="bll_r_tp_hdr">
                        <div className="bllr_hdr">
                          <h3>Item Table</h3>
                        </div>
                        <div className="bll_tbl_otr">
                          <div className="bll_tbl_innr">
                            <table>
                              <thead>
                                <tr>
                                  <th>Item Details</th>
                                  <th>Quantity</th>
                                  <th>Rate</th>
                                  <th>Amount</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {productsRow?.map((v, i) => {
                                  return (
                                    <tr key={v?.uniqkey}>
                                      <td className="img_bll">
                                        <div className="img_bll_main">
                                          <img
                                            src={require("../../../assets/images/bill_imgt.png")}
                                          />
                                        </div>
                                        <div className="cnvrt_text_ottr">
                                          <Autocomplete
                                            options={productOptions}
                                            onChange={(e, v) => {
                                              setProductNameForShow(v, i);
                                              handleSelectChange(v, i);
                                            }}
                                            value={productName[i]}
                                            getOptionLabel={(op) =>
                                              op?.label ?? ""
                                            }
                                            getOptionSelected={(
                                              option,
                                              value
                                            ) => option.value == value.value}
                                            renderInput={(params) => (
                                              <TextField
                                                placeholder="Select product"
                                                {...params}
                                              />
                                            )}
                                            PaperComponent={(props) => (
                                              <Paper
                                                {...props}
                                                sx={{
                                                  borderRadius: "16px",
                                                  fontFamily:
                                                    "source_sans_proregular",
                                                }}
                                              />
                                            )}
                                            ListboxProps={{
                                              sx: {
                                                fontSize: "1.5rem",
                                                height: "20rem",
                                              },
                                            }}
                                          />

                                          {/* <div>
                                            <Select
                                              filterOption={filterOption}
                                              name="product"
                                              onChange={(e) =>
                                                handleSelectChange(e, i)
                                              }
                                              options={productOptions}
                                            />
                                          </div> */}
                                          {v?.productId != "" && (
                                            <>
                                              {/* <div> */}
                                              {/* <AntdSelect
                                                value={
                                                  productsRow[i]?.variantId
                                                }
                                                onChange={(a, b) => {
                                                  variantChange(b, i);
                                                }}
                                                placeholder="Please Select variant"
                                                // defaultValue={
                                                //   id != "new" &&
                                                //   `Color: ${
                                                //     v?.variantOption?.filter(
                                                //       (val) =>
                                                //         val?._id ==
                                                //         productsRow[i]
                                                //           ?.variantId
                                                //     )[0]?.variant?.Color
                                                //   },Price:${
                                                //     v?.variantOption?.filter(
                                                //       (val) =>
                                                //         val?._id ==
                                                //         productsRow[i]
                                                //           ?.variantId
                                                //     )[0]?.variant?.Price
                                                //   }  `
                                                // }
                                                style={{
                                                  width: "100%",
                                                  fontFamily:
                                                    "source_sans_proregular",
                                                  borderRadius: "30rem",
                                                }}
                                                // onChange={handleProvinceChange}
                                                options={v?.variantOption.reduce(
                                                  (acc, val) => {
                                                    return [
                                                      ...acc,
                                                      {
                                                        label: `Color ${val?.variant?.Color}, Price: ${val?.variant?.Price}`,
                                                        value: val?._id,
                                                        details: val,
                                                      },
                                                    ];
                                                  },
                                                  []
                                                )}
                                              /> */}

                                              <MuiSelect
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                placeholder="Please select variant"
                                                sx={{
                                                  borderRadius: "9px",
                                                  height: "40px",
                                                }}
                                                value={
                                                  productsRow[i]?.variantId
                                                }
                                                MenuProps={{
                                                  MenuListProps: {
                                                    sx: {
                                                      "& .Mui-selected": {
                                                        backgroundColor:
                                                          "rgb(242, 247, 247) !important", // Customize the selected item background
                                                        // "&:hover": {
                                                        //   backgroundColor:
                                                        //     "rgb(0 94 90) !important",
                                                        //   color:
                                                        //     "white !important ",
                                                        // },
                                                      },
                                                    },
                                                  },
                                                }}
                                                onChange={(e) => {
                                                  editVariant(
                                                    e.target.value,
                                                    v.variantOption,
                                                    i
                                                  );
                                                }}
                                              >
                                                {v.variantOption?.map(
                                                  (eachvariant, variantind) => (
                                                    <MenuItem
                                                      value={eachvariant?._id}
                                                    >
                                                      {`Price: ${eachvariant?.variant?.Price}, Color: ${eachvariant?.variant?.Color}`}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </MuiSelect>

                                              {/* <select
                                                style={{ borderRadius: "8px" }}
                                                onChange={(e) => {
                                                  const selectedOption =
                                                    e.target.selectedOptions[0];
                                                  const id =
                                                    selectedOption.value;
                                                  const price =
                                                    selectedOption.getAttribute(
                                                      "data-price"
                                                    );

                                                  if (id !== "") {
                                                    setProductsRow((pre) => {
                                                      let res = JSON.parse(
                                                        JSON.stringify(pre)
                                                      );
                                                      res[i].variantId = id;
                                                      // res[i].quantity = 1;
                                                      res[i].unitCost =
                                                        Number(price);
                                                      res[i].totalCost =
                                                        Number(price) *
                                                        Number(
                                                          productsRow[i]
                                                            ?.quantity
                                                        );
                                                      return res;
                                                    });

                                                    if (
                                                      productsRow[i]
                                                        .variantId == "" &&
                                                      i + 1 ==
                                                        productsRow?.length
                                                    ) {
                                                      setProductsRow((pre) => {
                                                        const res = JSON.parse(
                                                          JSON.stringify(pre)
                                                        );

                                                        return [
                                                          ...res,
                                                          {
                                                            productId: "",
                                                            variantId: "",
                                                            quantity: 1,
                                                            unitCost: 0,
                                                            totalCost: 0,
                                                            variantOption: [],
                                                            uniqkey: uniqid(),
                                                          },
                                                        ];
                                                      });
                                                    }
                                                  }
                                                }}
                                                className="cnvrt_text_slct"
                                              >
                                                <option value="">
                                                  Select variant
                                                </option>
                                                {v.variantOption?.map(
                                                  (eachvariant, variantind) => (
                                                    <option
                                                      selected={
                                                        eachvariant?._id ==
                                                        productsRow[i].variantId
                                                      }
                                                      key={eachvariant?._id}
                                                      value={eachvariant?._id}
                                                      data-price={
                                                        eachvariant?.variant
                                                          ?.Price
                                                      }
                                                    >
                                                      {`Price: ${eachvariant?.variant?.Price} Color: ${eachvariant?.variant?.Color}`}
                                                    </option>
                                                  )
                                                )}
                                              </select> */}
                                              {/* </div> */}
                                            </>
                                          )}
                                        </div>
                                      </td>

                                      <td>
                                        <input
                                          className="cnvrt_text_nmbr"
                                          type="number"
                                          // step={1}
                                          min={1}
                                          value={v?.quantity}
                                          onChange={(e) => {
                                            setProductsRow((pre) => {
                                              let result = JSON.parse(
                                                JSON.stringify(pre)
                                              );
                                              result[i].quantity = Math.floor(
                                                Number(e.target.value)
                                              );
                                              result[i].totalCost =
                                                Math.floor(
                                                  Number(e.target.value)
                                                ) * Number(result[i].unitCost);
                                              return result;
                                            });
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="cnvrt_text_nmbr rate"
                                          type="number"
                                          min={0}
                                          value={v?.unitCost}
                                          onChange={(e) => {
                                            setProductsRow((pre) => {
                                              let res = JSON.parse(
                                                JSON.stringify(pre)
                                              );
                                              res[i].unitCost = Number(
                                                e.target.value
                                              );
                                              res[i].totalCost =
                                                Number(e.target.value) *
                                                Number(res[i].quantity);
                                              return res;
                                            });
                                          }}
                                        />
                                      </td>

                                      <td>{v?.totalCost}</td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (productsRow?.length > 1) {
                                            setProductsRow((pre) => {
                                              const res = JSON.parse(
                                                JSON.stringify(pre)
                                              );
                                              res.splice(i, 1);
                                              return res;
                                            });
                                          }
                                        }}
                                        className="actn_td actn_td_crs text-center"
                                      >
                                        <FontAwesomeIcon icon={faXmark} />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="bll_btm_innr">
                            <button
                              type="button"
                              className="add_neew"
                              onClick={() => {
                                setProductsRow((pre) => {
                                  const res = JSON.parse(JSON.stringify(pre));

                                  return [
                                    ...res,
                                    {
                                      productId: "",
                                      variantId: "",
                                      quantity: 1,
                                      unitCost: 0,
                                      totalCost: 0,
                                      // variantOption: [],
                                      uniqkey: uniqid(),
                                    },
                                  ];
                                });
                              }}
                            >
                              Add New Row
                              <span>
                                <FontAwesomeIcon icon={faPlus} />
                              </span>
                            </button>
                            <div className="subTOTAL_otr">
                              <div className="sBTL_r btm">
                                <div className="neSBta">
                                  <label>Sub Total</label>
                                </div>
                                <label>
                                  {ammontWithoutDiscount.toFixed(2)}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cancel__save">
                          <button
                            type="button"
                            onClick={(e) => {
                              submitFromm(e, "yes");
                            }}
                            className="srch_btn"
                          >
                            Save as draft
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              navigate(-1);
                            }}
                            className="srch_btn can"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            // onClick={saveOrderBill}
                            className="srch_btn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal
        open={customTermModalOpen}
        onClose={() => setCustomTermModalOpen(false)}
        aria-labelledby="custom-term-modal"
        aria-describedby="custom-term-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="custom-term-modal"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Create Custom Payment Term
          </Typography>
          <TextField
            label="Enter custom payment term"
            type="number"
            value={customTerm}
            onChange={(e) => setCustomTerm(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setCustomTermModalOpen(false)}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              Cancel
            </Button>
            <utton onClick={handleCustomTermSave} variant="contained">
              Save
            </utton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreateNewPurchaseOrder;
