import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { downloadBillInExcelApi, printBillApi } from "../../constants/Api/Api";



export const printTheBill=createAsyncThunk('printTheBill',async(data,thunkAPI)=>{
    try {
        const res=await axios.post(printBillApi,{billIds:data},{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })

        // console.log(res?.data,'222')
        if (res?.data?.success) {
            window.open(res?.data.fileUrl,'_blank')            
        }
    } catch (error) {
        console.warn(error);
        
    }
})


export const downloadBillInExcelFormat=createAsyncThunk('downloadBillInExcelFormat',async(data,thunkAPI)=>{
    try {
        const res=await axios.post(downloadBillInExcelApi,{billIds:data},{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })
        console.log(res,'222')
        if (res?.data?.success) {
            const fileURL = res.data.fileUrl;
        const link = document.createElement('a');
        link.href = fileURL;
        const fileName = fileURL.split('/').pop();
        link.download = fileName;
        link.click();
        }
    } catch (error) {
        console.warn(error);
    }
})