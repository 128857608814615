import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatToDate } from "./ConvertToBill";
import { GenerateBillNoForPayment } from "../../../redux/features/sellers/GenereteBillNumberForPayment";
import { PyamentTheBill } from "../../../redux/features/PayTheBill";
import { errorToast } from "../../common/ToastMessege";
import { Menu, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonMultipleFile from "../../common/CommonMultipleFile";
import CommonPaymentModeDropDown from "../../common/CommonPaymentModeDropDown";

const RecordPaymentForm = () => {
  const dispatch = useDispatch();
  const [fileUrl, setFileUrl] = useState("");
  const { id, ballanceDue } = useParams();
  const navigate = useNavigate();
  const [dataa, setDataa] = useState({
    paymentAmount: ballanceDue,
    paymentMode: "Cash",
    paymentNumber: "",
    paymentDate: formatToDate(new Date()),
    notes: "",
    // autogeneratedFlag: true,
  });
  const [fileArray, setFileArray] = useState([]);
  const [removedFile, setRemovedFile] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [picURL, setPicURL] = useState([]);

  const [paymentNumberr, setPaymentNumber] = useState("");
  useEffect(() => {
    dispatch(GenerateBillNoForPayment()).then((a) => {
      setPaymentNumber(a?.payload?.data);
      setDataa((pre) => {
        const res = JSON.parse(JSON.stringify(pre));
        res.paymentNumber = a?.payload?.data;
        return res;
      });
    });
  }, []);

  const paymentNow = (a) => {
    const convetInFormData = new FormData();
    for (const key in dataa) {
      convetInFormData.append(key, dataa[key]);
    }
    convetInFormData.append("billId", id);

    convetInFormData.append("removedFiles", JSON.stringify(removedFile));
    if (Array.isArray(fileArray)) {
      fileArray.forEach((file, index) => {
        if (file instanceof File) {
          convetInFormData.append("uploadFiles", file);
        }
      });
    }
    // for (let [key, value] of convetInFormData.entries()) {
    //   console.log(key, value, "abc");
    // }
    dispatch(PyamentTheBill({ data: convetInFormData, goto: navigate }));
  };
  // console.log(dataa, "abc");

  const removeFile = (val, ind) => {
    setRemovedFile((pre) => {
      return [...pre, val?._id];
    });

    setFileArray((pre) => {
      let res = [...pre];
      let res2 = res.filter((v) => v !== val);
      // console.log(res[0] == val, "ccc");
      return [...res2];
    });
  };

  const propsToSendinCommonFile = {
    removeFile,
    setFileArray,
    setPicURL,
    fileArray,
  };
  const openFileList = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataToSendPaymentMode = {
    initialPaymentMode: dataa.paymentMode,
    setPaymentModefn: (data) => {
      return setDataa((pre) => {
        const res = JSON.parse(JSON.stringify(pre));
        res.paymentMode = data;
        return res;
      });
    },
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFileList}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fileArray.length > 0 &&
          fileArray?.map((v, i) => (
            <MenuItem
              key={v?._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "30rem",
                fontSize: "1.5rem",
              }}
            >
              <span key={v?._id}>{v?.name}</span>
              <button
                type="button"
                onClick={() => {
                  removeFile(v, i);
                }}
              >
                <DeleteIcon />
              </button>
            </MenuItem>
          ))}
      </Menu>
      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    paymentNow();
                  }}
                  className="bills_cnt_otr"
                >
                  <div className="bll_hdr">
                    <h2>Payments for the bill </h2>
                  </div>
                  <div className="bll_innr_c">
                    <div className="bll_l">
                      <div className="bill_l_cnt">
                        <div className="bill_l_top_bx">
                          <div className="div_row">
                            <label>Payment Amount</label>
                            <div className="inp_srch">
                              <input
                                type="number"
                                onChange={(e) => {
                                  if (
                                    Number(e.target.value > Number(ballanceDue))
                                  ) {
                                    errorToast(
                                      "It looks like you are typing an excess amount."
                                    );
                                  } else {
                                    setDataa((pre) => {
                                      const res = JSON.parse(
                                        JSON.stringify(pre)
                                      );
                                      res.paymentAmount = e.target.value;
                                      return res;
                                    });
                                  }
                                }}
                                name="supplierName"
                                value={dataa?.paymentAmount}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div_row">
                        <label>
                          Payment Mode<span>*</span>
                        </label>
                        <CommonPaymentModeDropDown {...dataToSendPaymentMode} />

                        {/* <div className="inp_srch drp_dn">
                          <select
                            onChange={(e) => {
                              setDataa((pre) => {
                                const res = JSON.parse(JSON.stringify(pre));
                                res.paymentMode = e.target.value;
                                return res;
                              });
                            }}
                          >
                            <option value="cash">cash</option>
                            <option value="bankTransfer">bankTransfer</option>
                            <option value="cheque">cheque</option>
                            <option value="creditCard">creditCard</option>
                            <option value="UPI">UPI</option>
                          </select>
                        </div> */}
                      </div>

                      {/* <div className="div_row">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input fs-6"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={dataa.autogeneratedFlag}
                            onChange={(e) => {
                              setDataa((pre) => {
                                const res = JSON.parse(JSON.stringify(pre));
                                res.autogeneratedFlag = e.target.checked;
                                return res;
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Auto generate payement mode
                          </label>
                        </div>
                      </div> */}

                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Payment Date<span>*</span>
                          </label>
                          <div className="inp_srch">
                            <input
                              type="date"
                              value={dataa?.paymentDate}
                              onChange={(e) => {
                                setDataa((pre) => {
                                  const res = JSON.parse(JSON.stringify(pre));
                                  res.paymentDate = formatToDate(
                                    e.target.value
                                  );
                                  return res;
                                });
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="div_row">
                          <label>Payment number</label>
                          <div className="inp_srch">
                            <input
                              // onBlur={(e) => {
                              //   console.log(
                              //     e.target.value,
                              //     paymentNumberr,
                              //     "abc"
                              //   );
                              //   if (e.target.value === paymentNumberr) {
                              //     setDataa((pre) => {
                              //       const res = JSON.parse(JSON.stringify(pre));
                              //       res.autogeneratedFlag = true;
                              //       return res;
                              //     });
                              //   }
                              // }}
                              title="Please disable auto-generate payment mode for edit payment number"
                              // disabled={dataa.autogeneratedFlag}
                              disabled
                              type="text"
                              value={dataa?.paymentNumber}
                              onChange={(e) => {
                                setDataa((pre) => {
                                  const res = JSON.parse(JSON.stringify(pre));
                                  res.paymentNumber = e.target.value;
                                  return res;
                                });
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="div_row">
                        <div className="d-flex">
                          <label>Notes</label>
                        </div>
                        <div className="inp_srch">
                          <input
                            type="text"
                            value={dataa?.notes}
                            onChange={(e) => {
                              setDataa((pre) => {
                                const res = JSON.parse(JSON.stringify(pre));
                                res.notes = e.target.value;
                                return res;
                              });
                            }}
                          />
                        </div>
                      </div>
                      <CommonMultipleFile pr={propsToSendinCommonFile} />

                      <button className="edit">Submit</button>
                    </div>
                    {/* <div className="bll_r"></div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default RecordPaymentForm;
