import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faEye,
  faMagnifyingGlass,
  faSort,
  faStar,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import ordr_img1 from "../../../assets/images/ordr_img1.png";

import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";
import {
  logout,
  sellerProduct,
} from "../../../redux/features/sellers/sellerLoginSlice";
import { allProducts } from "../../../redux/features/sellers/sellerProductSlice";
import axios from "axios";
import NavBar from "../../common/Nav/NavBar";
import { updateStatusBeforeRender } from "../../../redux/GetAllBill";

const ProductList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [toggleClick, setToggleClick] = useState(false);

  const { loading, products } = useSelector((state) => state.sellerProducts);
  console.log(products, "productsList");
  const [searchBy, setSearchBy] = useState("");

  const searchedRecords = products.filter((v, i) => {
    return (
      v?.productInfo?.name?.toLowerCase().includes(searchBy.toLowerCase()) ||
      v?.productInfo?.brand?.name
        .toLowerCase()
        .includes(searchBy.toLowerCase()) ||
      v?.productInfo?.category?.name
        .toLowerCase()
        .includes(searchBy.toLowerCase())
    );
  });

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = searchedRecords?.slice(firstIndex, lastIndex);
  console.log(records, "records");

  const npage =
    searchedRecords?.length > 0 &&
    Math.ceil(searchedRecords.length / recordsPerPage);
  console.log(npage, "npage");
  const numbers = [...Array(npage + 1).keys()].slice(1);
  // console.log(numbers, "numbers");

  const handleLogout = () => {
    // e.preventDefault();
    // console.log("called", "logout");
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(logout());
        // console.log(res, "response");
        localStorage.removeItem("token");
        toast.success("logout successfully", {
          className: "toast-message",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // theme: 'dark',
          transition: Bounce,
        });
        navigate("/login/seller");
      }
    });
  };

  useEffect(() => {
    dispatch(allProducts());
    dispatch(updateStatusBeforeRender());
  }, []);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
    window.scrollTo(0, 0);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="outr-right-content">
        <div className="oder_history">
          <div className="order_hdr">
            <div className="ordre_lft">
              <h6>Product List</h6>
              {/* <p>Manage your recent products and invoices.</p> */}
            </div>
          </div>

          <div className="newOrderBox">
            <div className="drv_rght_rte">
              <input
                value={searchBy}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearchBy(e.target.value.trimStart());
                }}
                type="search"
                placeholder="Search "
              />
            </div>
            <NavLink to={"/products/add-product"}>
              <button type="button" className="edit">
                Create new Product
              </button>
            </NavLink>
          </div>

          <div className="blls_drive_tbl_ottr">
            <table className="blls_drive_tbl_main">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Brand</th>
                  <th> Category</th>
                  <th>Sub Category</th>
                  <th>Shipping Charge</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {records &&
                  records?.length > 0 &&
                  records?.map((product) => {
                    return (
                      <tr key={product?._id}>
                        <td>
                          <span className="bl_no_spn">
                            {product?.productInfo?.name}
                          </span>
                        </td>
                        <td>
                          <span className="bl_no_spn">
                            {product?.productInfo?.brand?.name}
                          </span>
                        </td>{" "}
                        <td>
                          <span className="bl_no_spn">
                            {product?.productInfo?.category?.name}
                          </span>
                        </td>{" "}
                        <td>
                          <span className="bl_no_spn">
                            {product?.productInfo?.subCategory?.name}
                          </span>
                        </td>{" "}
                        <td>
                          <span className="bl_no_spn">
                            {
                              product?.productInfo?.shippingDetails
                                ?.shippingCharge
                            }
                          </span>
                        </td>{" "}
                        <td>
                          <div className="drv_tbl_icns dropdown">
                            <button
                              className="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <FontAwesomeIcon icon={faSort} />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <button className="dpdn_btn_icns trash">
                                  <FontAwesomeIcon icon={faTrash} />
                                  Delete
                                </button>
                              </li>

                              <li>
                                <NavLink
                                  to={`/products/product-detail/${product?._id}`}
                                >
                                  <button className="dpdn_btn_icns eye">
                                    <FontAwesomeIcon icon={faEye} />
                                    View
                                  </button>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {/* pagination */}
          <div className="pagination">
            <a href="javascript:void(0);" onClick={prePage}>
              «
            </a>
            {numbers.map((n, i) => (
              <a
                href="javascript:void(0);"
                key={i}
                onClick={() => changeCPage(n)}
                className={`${currentPage === n ? "active" : ""}`}
              >
                {n}
              </a>
            ))}

            {/* <a href="javascript:void(0);" className="active">
                    02
                  </a>
                  <a href="javascript:void(0);">03</a>
                  <a href="javascript:void(0);">04</a> */}
            <a href="javascript:void(0);" onClick={nextPage}>
              »
            </a>
          </div>
        </div>
      </div>

      {/* moumi 12.2.24 */}
    </>
  );
};

export default ProductList;
