import { Drawer } from "@mui/material";
import React from "react";
import AddWarehouse from "../warehouse/AddWarehouse";

const CreateNewWareHouseByModal = ({ pr }) => {
  // console.log(pr);
  const { openNewWareHouseForm, setOpenWareHouseForm, wareHouseName } = pr;
  // console.log(pr, "abc");
  return (
    <>
      <Drawer
        open={openNewWareHouseForm}
        onClose={() => {
          setOpenWareHouseForm(false);
        }}
        anchor="right"
      >
        <AddWarehouse prr={pr} />
      </Drawer>
    </>
  );
};

export default CreateNewWareHouseByModal;
