

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AxiosInstance } from './AxiosInstance';
import { deletePaymentsMadeApi, getAllPaymentsMadeApi, rtkBaseApi, SearchPaymentsMadeApirtk } from '../../constants/Api/rtkAPI';
import { message } from 'antd';
import { LocalFireDepartment } from '@mui/icons-material';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params, headers }) => {
    console.log(url, method, data, params, headers, baseUrl, "inBaseQuary");
    try {
      const result = await AxiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return result;
    } catch (axiosError) {
      // console.log(axiosError,'result')
      return {
        error: {
          status: axiosError.response?.status,
          data: axiosError.response?.data || 'empty data',
          message:axiosError.response?.data?.message||'something wrong',
        },
      };
    }
  };




export const apiCalls=createApi({
    // baseQuery:fetchBaseQuery({
    //     baseUrl:"https://api.getsetdesigns.com"
    // }),
    reducerPath:'apiCalls',
    baseQuery: axiosBaseQuery({ baseUrl: rtkBaseApi}),
    refetchOnFocus:true,
    refetchOnReconnect:true,
    // keepUnusedDataFor:5,
    tagTypes:['PaymentMade','PO','ArrayOptions'],
    endpoints:(builder)=>({
        getPaymentMade:builder.query({
            query:(data)=>{
              
                return {
                  url:`${getAllPaymentsMadeApi}?startDate=${data?.startDate}&endDate=${data?.endDate}`,
                  method:'GET',
              }
            },
            transformResponse:(response)=>{
              return response?.data
            },
            transformErrorResponse:(response)=>{
              return response
            },
            providesTags:['PaymentMade']
        }),
        deletePayment:builder.mutation({
            query:(data)=>({
                url:deletePaymentsMadeApi,
                method:'DELETE',
                data:{paymentIds:data }
            }),
            invalidatesTags:['PaymentMade']
        }),
        searchPaymentsMadeByword:builder.mutation({
          query:(data)=>({
              url:`${SearchPaymentsMadeApirtk}?word=${data}`,
              method:'GET',
          }),
          transformResponse:(response)=>{
            return response?.data
          },
        })
    })
})


export const {useLazyGetPaymentMadeQuery,useDeletePaymentMutation,useSearchPaymentsMadeBywordMutation }=apiCalls


