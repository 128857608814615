import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generetePurchaseReciveNumber,
  POdetail,
  PurchaseRecive,
} from "../../../redux/features/sellers/sellerPurchaseSlice";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, Menu, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonMultipleFile from "../../common/CommonMultipleFile";
import { errorToast } from "../../common/ToastMessege";

const ReciveOneBill = () => {
  const navigate = useNavigate();
  const formatToDate = (inputDate) => {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      // throw new Error("Invalid date format"); // Handle invalid date input
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  useLayoutEffect(() => {
    dispatch(POdetail(id));
    dispatch(generetePurchaseReciveNumber());
  }, []);
  const { loading, purchaseOrderDetail, uniqePurchaseReciveNumber } =
    useSelector((state) => state.sellerPurchase);
  const [qnReciveDetail, setqnReciveDetail] = useState({});
  const [recivedDate, setRecivedDate] = useState(formatToDate(new Date()));
  const [notes, setNotes] = useState("");

  useEffect(() => {
    let res = {};
    purchaseOrderDetail?.products?.forEach((v) => {
      res[v?.variantId?._id] = v?.quantity - v?.quantityReceived;
    });

    setqnReciveDetail({ ...res });
  }, [purchaseOrderDetail]);

  console.log(purchaseOrderDetail, "222");
  const dataToSendForPurchaseRecive = {
    purchaseOrderNumber: purchaseOrderDetail?.purchaseOrderNumber,
    purchaseReceiveNumber: uniqePurchaseReciveNumber,
    receivedDate: recivedDate,
    products: purchaseOrderDetail?.products?.map((v) => {
      return {
        productId: v?.productId?._id,
        variantId: v?.variantId?._id,
        quantityOrdered: v?.quantity,
        quantityReceived: v?.quantityReceived,
        quantityToReceive: Number(qnReciveDetail[v?.variantId?._id]),
      };
    }),
  };

  const [fileArray, setFileArray] = useState([]);
  const [removedFile, setRemovedFile] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [picURL, setPicURL] = useState([]);

  const removeFile = (val, ind) => {
    setRemovedFile((pre) => {
      return [...pre, val?._id];
    });

    setFileArray((pre) => {
      let res = [...pre];
      let res2 = res.filter((v) => v !== val);
      // console.log(res[0] == val, "ccc");
      return [...res2];
    });
  };

  const propsToSendinCommonFile = {
    removeFile,
    setFileArray,
    setPicURL,
    fileArray,
  };
  const openFileList = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const productsArray = purchaseOrderDetail?.products?.map((v) => {
    return {
      productId: v?.productId?._id,
      variantId: v?.variantId?._id,
      quantityOrdered: v?.quantity,
      quantityReceived: v?.quantityReceived,
      quantityToReceive: Number(qnReciveDetail[v?.variantId?._id]),
    };
  });

  const saveAsReceived = () => {
    const newObj = new FormData();
    newObj.append(
      "purchaseOrderNumber",
      purchaseOrderDetail?.purchaseOrderNumber
    );
    newObj.append("purchaseReceiveNumber", uniqePurchaseReciveNumber);
    newObj.append("receivedDate", recivedDate);
    newObj.append("products", JSON.stringify(productsArray));
    newObj.append("notes", notes);
    newObj.append("removedFiles", JSON.stringify(removedFile));
    // console.log(fileArray, "222");
    if (Array.isArray(fileArray)) {
      fileArray.forEach((file, index) => {
        if (file instanceof File) {
          newObj.append("uploadFiles", file);
        }
      });
    }

    for (let [key, value] of newObj.entries()) {
      console.log(key, value, "222");
    }

    dispatch(
      PurchaseRecive({
        newObj,
        navigate,
      })
    );
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFileList}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fileArray.length > 0 &&
          fileArray?.map((v, i) => (
            <MenuItem
              key={v?._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "30rem",
                fontSize: "1.5rem",
              }}
            >
              <span key={v?._id}>{v?.name}</span>
              <button
                type="button"
                onClick={() => {
                  removeFile(v, i);
                }}
              >
                {/* <FontAwesomeIcon icon={faXmark} /> */}
                <DeleteIcon />
              </button>
            </MenuItem>
          ))}
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
      </Menu>

      {/* <h2>
      <div>VenderName:{purchaseOrderDetail?.supplierId?.name}</div>
      <div>Purchase Order:{purchaseOrderDetail?.purchaseOrderNumber}</div>
      <div>Purchase Receive:{uniqePurchaseReciveNumber}</div>
      <div> Receive Date :{}</div>
    </h2> */}
      <>
        {/* <div className="outr-right-content">
          <div className="outr-right-content splr_frm_cntnt nw_prchs_ordr">
            <div className="splr_frm_ttl">
              <div className="splr_frm_main">
                <form>
                  <div className="splr_frm_flx">
                    <div className="prch_ordtl_flx">
                      <div className="form-group">
                        <div className="td_hdr">
                          <h3>Purchase Receive</h3>
                        </div>

                        <div className="ord_bl_otr">
                          <h4>
                            Supplier Name :
                            {purchaseOrderDetail?.supplierId?.name}{" "}
                          </h4>
                          <h4>
                            Purchase Order :
                            {purchaseOrderDetail?.purchaseOrderNumber}
                          </h4>
                          <h4>Purchase Receive :{uniqePurchaseReciveNumber}</h4>
                          <h4>
                            Received Date:{" "}
                            <input
                              type="date"
                              value={recivedDate}
                              onChange={(e) => {
                                setRecivedDate(formatToDate(e.target.value));
                              }}
                            />
                          </h4>
                        </div>
                      </div>
                      <div className="form-group"></div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="prchs_itm_tbl">
                <table>
                  <thead>
                    <tr>
                      <th>ITEMS &amp; DESCRIPTION</th>
                      <th>ORDERED QUANTITY</th>
                      <th>QUANTITY RECIVED</th>
                      <th>QUANTITY TO RECIVED</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseOrderDetail?.products?.length > 0 &&
                      purchaseOrderDetail?.products
                        ?.filter(
                          (prod) => prod.quantity > prod.quantityReceived
                        )
                        .map((product) => {
                          return (
                            <tr key={product?._id}>
                              <td>
                                {product?.productId.productInfo.name}
                                <br />
                                {`Price: ${product?.variantId.variant.Price}`}{" "}
                                {`Color: ${product?.variantId.variant.Color}`}
                              </td>
                              <td>{product?.quantity}</td>
                              <td>{product?.quantityReceived}</td>
                              <td>
                                <input
                                  type="number"
                                  value={
                                    qnReciveDetail[product?.variantId?._id]
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value >
                                      product?.quantity -
                                        product?.quantityReceived
                                    ) {
                                      alert(
                                        "Quantity recorded cannot be more than quantity ordered."
                                      );
                                    } else {
                                      setqnReciveDetail((pre) => {
                                        return {
                                          ...pre,
                                          [product?.variantId?._id]:
                                            e.target.value,
                                        };
                                      });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              dispatch(
                PurchaseRecive({ dataToSendForPurchaseRecive, navigate })
              );
            }}
            className="edit"
          >
            Save As Received
          </button>
        </div> */}
      </>

      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page">
                <div className="top_drv">
                  <div className="top_billchk_top">
                    <div className="billchk_brdcrm" aria-label="breadcrumb">
                      <ol className="breadcrumb bliiN">
                        <li className="breadcrumb-item">
                          <a href="javascript:void(0);">PO</a>
                        </li>
                        <li className="breadcrumb-item active">
                          <a href="javascript:void(0);">Receive</a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="bills_cnt_otr">
                  <div className="bll_hdr">
                    <h2>Purchase Receive</h2>
                    <span>
                      <FontAwesomeIcon icon={faClose} size="2xl" />
                    </span>
                  </div>
                  <div className="bll_innr_c">
                    <div className="bll_l">
                      <div className="bill_l_cnt">
                        <div className="bill_l_top_bx">
                          <div className="div_row">
                            <label>Supplier Name</label>
                            <div className="inp_srch">
                              <input
                                type="text"
                                disabled
                                name="supplierName"
                                value={purchaseOrderDetail?.supplierId?.name}
                                // className="form-control"
                              />
                            </div>
                          </div>
                          <div className="clum_div">
                            <div className="div_row">
                              <label>Phone No</label>
                              <div className="inp_srch">
                                <input
                                  disabled
                                  type="text"
                                  value={`${purchaseOrderDetail?.supplierId?.countryCode} ${purchaseOrderDetail?.supplierId?.phoneNumber}`}
                                />
                              </div>
                            </div>
                            <div className="div_row">
                              <label>Email</label>
                              <div className="inp_srch">
                                <input
                                  className="eml"
                                  disabled
                                  type="text"
                                  value={purchaseOrderDetail?.supplierId?.email}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="div_row">
                            <label>address</label>
                            <div className="inp_srch">
                              <input
                                type="text"
                                disabled
                                value={`${purchaseOrderDetail?.supplierId?.address[0].street} ${purchaseOrderDetail?.supplierId?.address[0].city} ${purchaseOrderDetail?.supplierId?.address[0].state} ${purchaseOrderDetail?.supplierId?.address[0].zipCode} ${purchaseOrderDetail?.supplierId?.address[0].country}`}
                                placeholder="34 Crosby Drive, Suite 202, Bedford, MA  01730"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Purchase Receive No<span>*</span>
                          </label>
                          <div className="inp_srch">
                            <input
                              type="text"
                              disabled
                              value={uniqePurchaseReciveNumber}
                            />
                          </div>
                        </div>
                        <div className="div_row">
                          <label>
                            Purchase Order No<span>*</span>
                          </label>

                          <div className="inp_srch">
                            <input
                              type="text"
                              disabled
                              value={purchaseOrderDetail?.purchaseOrderNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Receive Date<span>*</span>
                          </label>
                          <div className="inp_srch">
                            <input
                              type="date"
                              name="billDate"
                              value={recivedDate}
                              className="form-control"
                              onChange={(e) => {
                                setRecivedDate(formatToDate(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="div_row">
                        <div className="d-flex">
                          <label>Notes</label>
                          {/* <span className="spon">
                            It will not be shown in PDF
                          </span> */}
                        </div>
                        <div className="inp_srch">
                          <input
                            type="text"
                            value={notes}
                            onChange={(e) => {
                              setNotes(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <CommonMultipleFile pr={propsToSendinCommonFile} />
                    </div>
                    <div className="bll_r">
                      <div className="bll_r_tp_hdr">
                        <div className="bllr_hdr">
                          <h3>Item Table</h3>
                        </div>
                        <div className="bll_tbl_otr">
                          <div className="bll_tbl_innr">
                            <table>
                              <thead>
                                <tr>
                                  <th>Item Details</th>
                                  <th>Quantity Ordered</th>
                                  <th>Quantity Received</th>
                                  <th>Quentity To Receive</th>
                                </tr>
                              </thead>
                              <tbody>
                                {purchaseOrderDetail?.products?.length > 0 &&
                                  purchaseOrderDetail?.products
                                    ?.filter(
                                      (prod) =>
                                        prod.quantity > prod.quantityReceived
                                    )
                                    .map((product, i) => {
                                      return (
                                        <tr>
                                          <td className="img_bll">
                                            <div>
                                              <img
                                                src={
                                                  product?.thumbnailImage
                                                    ? product?.thumbnailImage
                                                    : require("../../../assets/images/bill_imgt.png")
                                                }
                                              />
                                            </div>
                                            <div className="cnvrt_text">
                                              <h5>
                                                {
                                                  product.productId.productInfo
                                                    .name
                                                }
                                                <br />
                                                {`Price: ${product.variantId.variant.Price}`}{" "}
                                                {`Color: ${product.variantId.variant.Color}`}
                                              </h5>
                                            </div>
                                          </td>

                                          <td>
                                            <div>{product.quantity}</div>
                                          </td>
                                          <td>
                                            <div>
                                              {product?.quantityReceived}
                                            </div>
                                          </td>
                                          <td>
                                            <input
                                              className="cnvrt_text_nmbr"
                                              type="number"
                                              value={
                                                qnReciveDetail[
                                                  product?.variantId?._id
                                                ]
                                              }
                                              onChange={(e) => {
                                                if (
                                                  e.target.value >
                                                  product?.quantity -
                                                    product?.quantityReceived
                                                ) {
                                                  errorToast(
                                                    "Quantity recorded cannot be more than quantity ordered."
                                                  );
                                                } else {
                                                  setqnReciveDetail((pre) => {
                                                    return {
                                                      ...pre,
                                                      [product?.variantId?._id]:
                                                        e.target.value,
                                                    };
                                                  });
                                                }
                                              }}
                                            />
                                          </td>
                                          {/* <td className="actn_td">
                                            <div className="actn">
                                              <button
                                                type="button"
                                                className="actn_btn"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faSort}
                                                />
                                              </button>
                                            </div>
                                          </td> */}
                                        </tr>
                                      );
                                    })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="cancel__save">
                          <button
                            type="button"
                            className="srch_btn can"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={saveAsReceived}
                            className="srch_btn"
                          >
                            Save As Received
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ReciveOneBill;
