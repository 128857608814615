import { faGear, faHeadphones } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { HoldBucketMenegAction } from "../../redux/posSlice/HoldBucketMeneg";
import { useDispatch, useSelector } from "react-redux";
import { BucketMenegeAction } from "../../redux/posSlice/BucketMenegeSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { faBell, faUser } from "@fortawesome/free-regular-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  Menu,
  MenuItem,
} from "@mui/material";
import { Bounce, toast } from "react-toastify";
import { logout } from "../../redux/features/sellers/sellerLoginSlice";
import { sellerRoute } from "./SellerSideBar";
import { apiCalls } from "../../redux/reduxNeeded/reduxBase";

const SellerTopBar = () => {
  // const { loggerName } = useSelector((a) => a.loginSeller);
  const nav = useNavigate();
  const dispatch = useDispatch();
  const holdBill = useSelector((a) => a.HoldBilling);
  const {
    firstName,
    lastName,
    phone,
    userId: IdofUser,
  } = useSelector((res) => res.TheCustomer);
  const totalBucket = useSelector((a) => a.BucketControl);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [key, setkey] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const logoutTheApp = () => {
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(logout());
        dispatch(apiCalls.util.resetApiState());
        // console.log(res, "response");
        // localStorage.clear();
        toast.success("logout successfully", {
          className: "toast-message",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // theme: 'dark',
          transition: Bounce,
        });
        navigate("/");
      }
    });
  };
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            logoutTheApp();
            handleClose();
          }}
        >
          <h3>Log Out</h3>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <h3>
            <NavLink style={{ color: "black" }} to={"/update-password"}>
              Update Password
            </NavLink>
          </h3>
        </MenuItem>
      </Menu>
      <>
        <div className="outr_parent_nav">
          <div className="lft_p_nv_ottr">
            <div
              className="tg_br"
              onClick={() => {
                setOpenSideBar(true);
              }}
            >
              <img
                src={require("../../assets/images-pos/mnu_icn.png")}
                alt="img"
              />
            </div>
            <div className="top_nav_r">
              <div className="nav_top cmn_hdr">
                {/* <h2>POS System</h2>
              <p>Simplify Your Sale</p> */}
              </div>
              <div className="tp_u">
                <div className="need_sup">
                  <a href="javascript:void(0);" className="cmn_btn">
                    <span>
                      {/* <i className="fa-solid fa-headphones"></i> */}
                      <FontAwesomeIcon icon={faHeadphones} size="2xl" />
                    </span>{" "}
                    Need Support?
                  </a>
                </div>
                <span className="bell">
                  {/* <i className="fa-solid fa-bell"></i> */}
                  <FontAwesomeIcon icon={faBell} size="2xl" />
                </span>
                <div className="tp_r">
                  <div className="u___details">
                    <span className="tp_uu">
                      {/* <i className="fa-solid fa-user"></i> */}
                      <FontAwesomeIcon icon={faUser} size="2xl" />
                    </span>
                    <div className="cmn_hdr">
                      <h3>{localStorage.getItem("loggerName")}</h3>
                      {/* <h3>David Paul  </h3> */}
                      <p>Admin</p>
                    </div>
                    <div onClick={handleClick}>
                      <img
                        src={require("../../assets/images-pos/doen_i.png")}
                      />
                    </div>
                  </div>
                  <div className="on_c_drop">
                    <ul>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faGear} size="2xl" />
                        </span>
                        Settings
                      </li>
                      <div>
                        <a href="javascript:void(0);" className="cmn_btn">
                          Logout
                        </a>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Drawer
        open={openSideBar}
        onClose={() => {
          setOpenSideBar(false);
        }}
      >
        <>
          {sellerRoute?.map((v, i) => (
            <Accordion
              key={i}
              onChange={() => {
                setkey(i);
              }}
              expanded={key == i}
            >
              <AccordionSummary key={i}>{v?.mainName}</AccordionSummary>
              {v?.sub?.map((vc, vi) => (
                <AccordionDetails
                  onClick={() => {
                    setOpenSideBar(false);
                  }}
                  key={vi}
                  sx={{ fontSize: "2.5rem" }}
                >
                  <NavLink to={vc?.routeLink}>{vc?.subName}</NavLink>
                </AccordionDetails>
              ))}
            </Accordion>
          ))}
        </>
      </Drawer>
    </>
  );
};

export default SellerTopBar;
