import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMagnifyingGlass,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";

import NavBar from "../../common/Nav/NavBar";
import { allPurchaseOrder } from "../../../redux/features/sellers/sellerPurchaseSlice";
import {
  getStock,
  inventoryDetail,
  sellerInventoryAction,
} from "../../../redux/features/sellers/sellerInventorySlice";
import {
  BASE_URL,
  getlistofwarehouse,
  sendingWarehousename,
} from "../../../constants/Api/Api";
import axios from "axios";
import { getTotalWarehouse } from "../../../redux/features/WarehouseSlice";
import LoaderImage from "../../common/LoaderImage";

const InventorySummery = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [listofwarehouse, setlistofwarehouse] = useState([]);

  const { listofwarehouse } = useSelector((a) => a.Warehouse);
  const [searchBy, setSearchBy] = useState("");

  useEffect(() => {
    dispatch(getTotalWarehouse());
  }, []);

  const [modal, setModal] = useState(false);

  const { loading, inventory, stock, indexx, mainPageLoading } = useSelector(
    (state) => state.sellerInventory
  );

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const searchedInventory = inventory.filter((v, i) => {
    console.log(v, "vvvvvvvvvv");
    return v?.productId?.productInfo?.name
      .toLowerCase()
      .includes(searchBy.toLowerCase());
    //   ||
    // v?.variant?.sku.includes(searchBy.toLowerCase())

    // return (
    //   v?.productInfo?.name?.toLowerCase().includes(searchBy.toLowerCase()) ||
    //   v?.productInfo?.brand?.name
    //     .toLowerCase()
    //     .includes(searchBy.toLowerCase()) ||
    //   v?.productInfo?.category?.name
    //     .toLowerCase()
    //     .includes(searchBy.toLowerCase())
    // );
  });

  const records = searchedInventory?.slice(firstIndex, lastIndex);

  const npage =
    inventory?.length > 0 && Math.ceil(inventory.length / recordsPerPage);

  const numbers = [...Array(npage + 1).keys()].slice(1);
  console.log(numbers, "numbers");

  useEffect(() => {
    dispatch(inventoryDetail(""));
  }, []);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
    window.scrollTo(0, 0);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="outr-right-content">
        <div className="oder_history">
          <div className="order_hdr">
            <div className="ordre_lft">
              <h6>Inventory Summary</h6>
            </div>

            <div className="div_row new_dev_row ">
              <div className="drv_rght_rte">
                <input
                  type="search"
                  placeholder="Search vendor name"
                  onChange={(e) => {
                    setSearchBy(e.target.value.trim());
                  }}
                />
              </div>
            </div>

            <select
              onChange={(e) => {
                console.log(e.target.value, "abc");
                dispatch(inventoryDetail(e.target.value));
              }}
              style={{ fontSize: "25px" }}
            >
              <option value="" selected disabled>
                Select warehouse
              </option>
              <option value="">All Warehouse</option>
              {listofwarehouse.map((v) => (
                <option key={v._id} value={v._id}>
                  {v?.warehouseName}
                </option>
              ))}
            </select>
            <div className="ordre_rght"></div>
          </div>
          <div className="orders">
            <div className="ordr_tbl">
              <table>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>SKU</th>
                    <th>Reorder Level</th>
                    <th>Quantity Ordered</th>
                    <th>Stock On Hand</th>
                  </tr>
                </thead>
                <tbody>
                  {records &&
                    records.length > 0 &&
                    records.map((invent, i) => {
                      console.log(invent, "chekk");

                      return (
                        <tr key={invent.variant.sku}>
                          <td>
                            <div className="div1">
                              <div className="o_div_txt">
                                <h5>
                                  {invent?.productId?.productInfo?.name}
                                  <br />
                                  {`Price: ${invent?.variant?.Price} Color: ${invent.variant.Color}`}
                                </h5>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>{invent.variant.sku}</h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>{invent.variant.Reordered_Level}</h5>
                            </div>
                          </td>
                          <td>
                            <div className="div2">
                              <h5>{invent.quantityOrdered}</h5>
                            </div>
                          </td>
                          {/* <td>
                                  <div className="div2">
                                    <h5>{invent.quantityIn}</h5>
                                  </div>
                                </td> */}
                          {/* <td>
                                  <div className="div2">
                                    <h5>{invent.quantityOut}</h5>
                                  </div>
                                </td> */}
                          <td>
                            <div className="div2">
                              {invent.variant.Reordered_Level >
                              invent.quantity ? (
                                <span
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <h5
                                    style={{
                                      // color: "red",
                                      marginRight: "30px",
                                    }}
                                  >
                                    {invent.quantity}
                                  </h5>
                                  <h5 style={{ color: "red" }}>(Low Stock)</h5>
                                </span>
                              ) : (
                                <h5>
                                  {mainPageLoading
                                    ? "loading..."
                                    : invent.quantity}
                                </h5>
                              )}
                            </div>
                          </td>
                          {/* <td>
                              <select
                                className="fs-3"
                                onChange={async (e) => {
                                  dispatch(
                                    sellerInventoryAction.clearStockData(i)
                                  );
                                  dispatch(
                                    getStock({
                                      warehouseid: e.target.value,
                                      variantId: invent._id,
                                      productId: invent.productId._id,
                                    })
                                  );
                                }}
                              >
                                <option value="" disabled selected>
                                  Select form drop down
                                </option>
                                {listofwarehouse.map((v) => (
                                  <option key={v._id} value={v._id}>
                                    {v?.warehouseName}
                                  </option>
                                ))}
                              </select>
                              <br />

                              <h4>
                                {stock[invent?._id] >= 0
                                  ? loading && i == indexx
                                    ? "wait..."
                                    : "stock :" +
                                      `${
                                        stock[invent?._id] <= 0
                                          ? "Empty stock"
                                          : `${
                                              stock[invent?._id]
                                            } items available`
                                      }`
                                  : loading && i == indexx
                                  ? "wait..."
                                  : null}
                              </h4>
                            </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination */}
          <div className="pagination">
            <a href="javascript:void(0);" onClick={prePage}>
              «
            </a>
            {numbers.map((n, i) => (
              <a
                href="javascript:void(0);"
                key={i}
                onClick={() => changeCPage(n)}
                className={`${currentPage === n ? "active" : ""}`}
              >
                {n}
              </a>
            ))}

            {/* <a href="javascript:void(0);" className="active">
                    02
                  </a>
                  <a href="javascript:void(0);">03</a>
                  <a href="javascript:void(0);">04</a> */}
            <a href="javascript:void(0);" onClick={nextPage}>
              »
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventorySummery;
