import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Pagination, Stack } from "@mui/material";

import React, { useState } from "react";

const CommonPaginateSection = ({ pr }) => {
  const { setPageNumber, setPerPage, totalPageNumber } = pr;
  const [showPaginateDropDown, setShowPaginateDropDown] = useState(false);
  const [paginateText, setPaginateText] = useState("20 per page");
  return (
    <>
      <div className="bttm_drv">
        <div className="main_add_po" style={{ width: "17rem" }}>
          <div className="ul-main-div">
            <div
              style={{ fontFamily: "source_sans_proregular" }}
              className="ul__li_box"
              tabIndex={0}
              onFocus={() => {
                setShowPaginateDropDown((pre) => !pre);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setShowPaginateDropDown(false);
                }, 150);
              }}
            >
              {paginateText}
              <span className="drop----dwn">
                <i className="fa-solid fa-caret-down"></i>
                {/* <FontAwesomeIcon icon={faCaretDown} /> */}
              </span>
            </div>
            {showPaginateDropDown && (
              <>
                <ul className="ul-box">
                  {/* <li
                    onClick={(e) => {
                      setPaginateText("5 per page ");
                      setPerPage(5);
                    }}
                  >
                    5 per page
                  </li> */}
                  <li
                    onClick={(e) => {
                      setPaginateText("20 per page ");
                      setPerPage(20);
                    }}
                  >
                    20 per page
                  </li>
                  <li
                    onClick={(e) => {
                      setPaginateText("50 per page ");
                      setPerPage(50);
                    }}
                  >
                    50 per page
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>

        {/* <div className="bttm_drv_lft">
          <div className="bttm_drv_pg_slct">
            <select
              onChange={(e) => {
                setPerPage(e.target.value);
                setPageNumber(1);
              }}
            >
              <option value={5}>5 per page</option>
              <option value={20}>20 per page</option>
              <option value={50}>50 per page</option>
            </select>
          </div>
        </div> */}
        <div className="bttm_drv_rght">
          <div aria-label="Page navigation example">
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Stack
                spacing={2}
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Pagination
                  count={totalPageNumber}
                  onChange={(e, v) => {
                    setPageNumber(v);
                  }}
                  sx={{
                    "& .MuiPaginationItem-root": {
                      fontSize: "1.2rem",
                      fontWeight: "520",
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#59acac",
                      },
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#59acac !important",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#59acac",
                      },
                    },
                  }}
                />
              </Stack>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonPaginateSection;
