import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {  add_bill, add_supplier, create_purchase_order, genertePurchaseOrderNumberApi, get_suppliers, get_warehouse, getAllPO, purchase_order_detail, PurchaseReciveApi, update_status} from "../../../constants/Api/Api";
// import { Bounce, toast } from "react-toastify";
import swal from "sweetalert";
import { ErrorRounded } from "@mui/icons-material";
import { Bounce, toast } from "react-toastify";

// import { Bounce, toast } from "react-toastify";





const initialState = {
  loading: false,
  error: null,
  suppliers: [],
  warehouse: [],
  POList: [],
  purchaseOrderDetail: undefined,
  currency:undefined,
  uniqePurchaseReciveNumber:''
};


//ADD SUPPLIER
export const addSupplierInfo = createAsyncThunk("addSupplier", async({ supplierInfo, navigate }, {dispatch}) => {
    try {
    const supplierInfoAdded = await axios.post(add_supplier, supplierInfo,  {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
// console.log(supplierInfoAdded, 'supplierInfoAdded')
if (supplierInfoAdded?.data?.success) {
  swal("Done!", supplierInfoAdded.data.message, "success");
  dispatch(allSuppliers())
  navigate("/purchase/supplier-list")
} else {
  
}
    } catch (error) {
//console.log(error, 'error')
    }
  }
);

//GET SUPPLIER LIST
export const allSuppliers = createAsyncThunk("allSuppliers", async() =>{
    try{
        const suppliersList = await axios.get(get_suppliers, {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            
        })
  //console.log(suppliersList, 'suppliersList')
        return suppliersList.data.data
    }catch(error){
  //console.log(error)
    }
})

//UPDATE STATUS
export const updateStatus = createAsyncThunk("updateStatus", async(data, {dispatch}) =>{
  console.log(data, 'data')
  try{
    const resStatus = await axios.put(`${update_status}/${data.id}`, {isActive: !data.statusActive}, {
       headers: { Authorization: 'Bearer ' + localStorage.getItem('token') ,
        'Content-Type': 'multipart/form-data',
       },
       params: {
        process: "status"
       }
    })

dispatch(allSuppliers())
  }catch(error){
// console.log(error)
  }
})

//CREATE PURCHASE ORDER
export const createPurchaseOrder = createAsyncThunk("createPurchaseOrder", async({newObj, navigate,typee}, thunkAPI) =>{
  console.log(newObj, 'newObj54654')
  
  try {
    const resForPo = await axios.post(`${create_purchase_order}?isDraft=${typee}`, newObj, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
      if (resForPo?.data?.success) {
        swal("Done!", resForPo.data.message, "success");
         await thunkAPI.dispatch(allPurchaseOrder())
        navigate("/purchase/purchase-order-list")
      } else {
        console.warn(resForPo?.data);
        
      }
  }catch(error){
    toast.error(error.response.data.message, {
        className: "toast-message",
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
// console.log(error, 'error1234')
  }
})

//GET ALL PURCHASE ORDERS
export const allPurchaseOrder = createAsyncThunk("allPurchaseOrder", async(data,thunkAPI)=>{
  try{
    const resPO = await axios.get(getAllPO, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
// console.log(resPO?.data?.currency,'eeee')

    return {po: resPO.data.data,curr:resPO?.data?.currency}

  }catch(error){
console.log(error)
return thunkAPI.rejectWithValue({po:[]})
}
})

//GET PURCHASE ORDER DETAIL
export const POdetail = createAsyncThunk("POdetail", async(POId) =>{
  try{
    const resPOdetail = await axios.get(`${purchase_order_detail}/${POId}`, {
       headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
// console.log(resPOdetail?.data?.data, 'aaa')
    return resPOdetail.data.data

  }catch(error){
// console.log(error)
  }
})

//FOR ADD BILL
export const addBill = createAsyncThunk("addBill", async({ newObj, navigate }) =>{
  try{
    const resForAddBill = await axios.post(add_bill, newObj,{
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
// console.log(resForAddBill, 'resForAddBill')
    swal("Done!", resForAddBill.data.message, "success");
    navigate("/purchase/purchase-order-list");

  }catch(error){
    toast.error(error.response.data.message, {
        className: "toast-message",
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
// console.log(error, 'errorAddBill')

  }
})



export const generetePurchaseReciveNumber=createAsyncThunk('generetePurchaseReciveNumber',async(data,thunkAPI)=>{

  try {    
    const res=await axios.get(genertePurchaseOrderNumberApi,{
      headers:{Authorization:'Bearer '+localStorage.getItem('token')}
    })
    return res?.data
  } catch (error) {
    console.warn(error); 
    return thunkAPI.rejectWithValue(error?.message);   
  }


})


export const PurchaseRecive = createAsyncThunk("PurchaseRecive", async({newObj,navigate}, thunkAPI) =>{
  
console.log(newObj,'222')

  try {
    const res= await axios.post(PurchaseReciveApi, newObj, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
    if (res?.data.success) {
      navigate('/purchase/purchase-order-list')
      swal("Done!", res.data.message, "success");
    }
     
  }catch(error){
    toast.error(error.response.data.message, {
        className: "toast-message",
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
      return thunkAPI.rejectWithValue(error.response.data.message);   
  
  }
})


const sellerPurchaseSlice = createSlice({
  name: "sellerPurchase",
  initialState,

  
 
  extraReducers: (builder) => {
    builder
    //ADD SUPPLIER
      .addCase(addSupplierInfo.pending, (state) =>{
        state.loading = true
  })
      .addCase(addSupplierInfo.fulfilled, (state) => {
          state.loading = false
  })
      .addCase(addSupplierInfo.rejected, (state, action) => {
       state.loading = false
        state.error = action.payload
  })

  //GET SUPPLIER
     .addCase(allSuppliers.pending, (state) =>{
        state.loading = true
  })
      .addCase(allSuppliers.fulfilled, (state, action) => {
          state.loading = false
          state.suppliers = action.payload
  })
      .addCase(allSuppliers.rejected, (state, action) => {
       state.loading = false
        state.error = action.payload
  })

  //GET ALL PURCHASE ORDER
     .addCase(allPurchaseOrder.pending, (state) =>{
        state.loading = true
  })
      .addCase(allPurchaseOrder.fulfilled, (state, action) => {
          state.loading = false
          state.POList = action.payload?.po
          state.currency=action.payload?.curr
  })
      .addCase(allPurchaseOrder.rejected, (state, action) => {
       state.loading = false
        // state.error = action.payload
        state.POList=[]
  })

  //PURCHASE ORDER DETAIL
       .addCase(POdetail.pending, (state) =>{
        state.loading = true
  })
      .addCase(POdetail.fulfilled, (state, action) => {
          state.loading = false
          state.purchaseOrderDetail = action.payload
  })
      .addCase(POdetail.rejected, (state, action) => {
       state.loading = false
        state.error = action.payload
  })

  .addCase(generetePurchaseReciveNumber.pending, (state) =>{
})
  .addCase(generetePurchaseReciveNumber.fulfilled, (state, action) => {
      state.uniqePurchaseReciveNumber = action.payload?.data
})
  .addCase(generetePurchaseReciveNumber.rejected, (state, action) => {
})
 
  },
});


export default sellerPurchaseSlice.reducer;



