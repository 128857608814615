export const rtkBaseApi = "https://api.getsetdesigns.com";

export const getAllPaymentsMadeApi = `/api/supplierPayment/getAllPayments`;

export const deletePaymentsMadeApi = `/api/supplierPayment/deletePaymentsByIds`;

export const SearchPaymentsMadeApirtk = `/api/supplierPayment/searchPayments`;

export const PaymentMadeToExcelApirtk = `/api/supplierPayment/downloadSupplierPaymentListExcel`;

export const DownLoadPOInExcelApirtk = `/api/po/downloadPOlistExcel`;

export const DownLoadBillInExcelApirtk = `/api/bill/downloadBillListInExcel`;

export const DownLoadPRInExcelApirtk = `/api/pr/downloadPRInExcel`;

export const PrintPoInPdfApirtk = `/api/po/downloadMultiplePO`;

export const PrintBillInPdfApirtk = `/api/bill/downloadMultipleBills`;

export const PrintPRInPdfApirtk = `/api/pr/downloadPRsInPDF`;

export const filteredPOApirtk = `/api/po/getAllPO`;

export const SearchPOByWordApirtk = `/api/po/getPOBySearch`;

export const getBillBySuppIdApirtk = "/api/bill/getBillsBySupplierId/";

export const generatePaymentNumberApirtk =
  "/api/supplierPayment/showPaymentNumber";

export const createNewPaymentMadeApirtk =
  "/api/supplierPayment/addPaymentForMultipleBills";

export const viewEachPMDetailApirtk = "/api/supplierPayment/getPaymentById/";

export const paymentTermOrTypeOptionsApirtk = `/api/sellerSetting/getSellerSettingById`;

export const updateOptionsArrayApirtk = `/api/sellerSetting/updateSellerSettingById`;

export const getAllSupplierApirtk = `/api/supplier`;

export const printSupplierApirtk = `/api/supplierTransaction/getSupplierTransactionBySupplierId/`;

export const printPaymentsMadeApirtk = `/api/supplierPayment/downloadMultiplePaymentsInPdf`;
