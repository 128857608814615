import { createNewPaymentMadeApirtk, generatePaymentNumberApirtk, getBillBySuppIdApirtk, viewEachPMDetailApirtk } from "../../constants/Api/rtkAPI";
import { apiCalls } from "./reduxBase";

const allCreateNewPaymentMade=apiCalls.injectEndpoints({
    endpoints:(builder)=>({
        getBillBySuppId:builder.query({
            query:(id)=>{
                return {
                    url:`${getBillBySuppIdApirtk}${id}`,
                    method:'GET',
                }
            }
        }),
        generatePaymentNumber:builder.query({
            query:()=>{
                return {
                    url:generatePaymentNumberApirtk,
                    method:'GET'
                }
            },
            transformResponse:(res)=>{
                return res?.data
            }
        }),
        createNewPaymentMade:builder.mutation({
            query:(data)=>{
                return {
                    url:createNewPaymentMadeApirtk,
                    method:'POST',
                    data
                }
            }
        }),
        viewEachPaymentMade:builder.query({
            query:(id)=>{
                return {
                    url:`${viewEachPMDetailApirtk}${id}`
                }
            },
            transformResponse:(res)=>{
                return res?.data
            }
        })
    })
})

export const { useLazyGetBillBySuppIdQuery,useLazyGeneratePaymentNumberQuery,useCreateNewPaymentMadeMutation, useLazyViewEachPaymentMadeQuery }=allCreateNewPaymentMade