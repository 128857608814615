import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AllPaymentMadeListApi, deletePaymentApi, PaymentToExcelApi, SearchPaymentsMadeApi } from "../../constants/Api/Api";
import { errorToast, successToast } from "../../components/common/ToastMessege";




export const PaymentMadeList=createAsyncThunk('PaymentMadeList',async(data,thunkAPI)=>{
    try {
        const res=await axios.get(AllPaymentMadeListApi,{
            headers:{Authorization:"Bearer "+localStorage.getItem('token')}
        })
        // console.log(res)
        if (res?.data.success) {
            // swal('Done ',res?.data?.message??'success','success')            
            return res?.data
        } else {}
    } catch (error) {
        console.warn(error);
        swal('Opps ',error?.response?.data?.message??'Something wrong','error')
        
    }
})


export const seachPaymentsMade=createAsyncThunk("seachPaymentsMade",async(data,thunkAPI)=>{
    try {
        const res=await axios.get(`${SearchPaymentsMadeApi}${data}`,{
            headers:{Authorization:"Bearer "+localStorage.getItem('token')}
        })
        console.log(res?.data?.data)
        return res?.data?.data
    } catch (error) {
        console.warn(error);
        return thunkAPI.rejectWithValue()
    }
})


export const deletePayment=createAsyncThunk('deletePayment',async(data,thunkAPI)=>{
    try {
        const res=await axios.delete(deletePaymentApi,{headers:{
            Authorization:'Bearer '+localStorage.getItem('token')
        },data:{paymentIds:data}})
        if (res?.data?.success) {
            // successToast(res?.data?.message) 
            swal('Done',res?.data?.message,'success')
            await thunkAPI.dispatch(PaymentMadeList())
         }else{
                errorToast(res?.data?.message)
            }
    } catch (error) {
        console.warn(error);
        errorToast(error.response?.data?.message)
        return thunkAPI.rejectWithValue()
    }
})

export const paymentToExcel=createAsyncThunk('paymentToExcel',async(data,thunkAPI)=>{
    try {
        const res = await axios.post(PaymentToExcelApi,{paymentIds:data},{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })
        console.log(res?.data)

        if (res?.data?.success) {
            const fileURL = res.data.fileUrl;
        const link = document.createElement('a');
        link.href = fileURL;
        const fileName = fileURL.split('/').pop();
        link.download = fileName;
        link.click();
        }

    } catch (error) {
        console.warn(error);
        return thunkAPI.rejectWithValue()
        
    }
})

export const AllPaymentMadeListSlice=createSlice({
    name:'AllPaymentMadeListSlice',
    initialState:{
        loading:false,
        paymentMadeList:[],
        paymentTypingLoading:false,
        searchPaymentList:[]
    },
    extraReducers: (builder) => {
        builder
          .addCase(PaymentMadeList.pending, (state) => {
            state.loading = true;
          })
          .addCase(PaymentMadeList.fulfilled, (state, action) => {
            state.loading = false;
            state.paymentMadeList = action.payload.data 
          })
          .addCase(PaymentMadeList.rejected, (state, action) => {
            state.loading = false;
          })

          builder
          .addCase(seachPaymentsMade.pending, (state) => {
            state.paymentTypingLoading = true;
          })
          .addCase(seachPaymentsMade.fulfilled, (state, action) => {
            state.paymentTypingLoading = false;
            state.searchPaymentList = action.payload
          })
          .addCase(seachPaymentsMade.rejected, (state, action) => {
            state.paymentTypingLoading = false;
            state.searchPaymentList = []
          })
      },
})