import { paymentTermOrTypeOptionsApirtk, updateOptionsArrayApirtk } from "../../constants/Api/rtkAPI";
import { apiCalls } from "./reduxBase";

const CommonApi=apiCalls.injectEndpoints({
    endpoints:(builder)=>({


        getOptions:builder.query({
            query:()=>({
                url:paymentTermOrTypeOptionsApirtk,
                method:"GET"
            }),
            transformResponse:(response)=>{
                return response?.data
            },
            providesTags:['ArrayOptions']
        }),
        updateOptionsArray:builder.mutation({
            query:(data)=>{
                console.log(data)
                return {
                    url:updateOptionsArrayApirtk,
                    method:"PUT",
                    data:data
                }
            },
            invalidatesTags:['ArrayOptions']
        }),
        updatePaymentTypeArray:builder.mutation({
            query:(data)=>{
                console.log(data)
                return {
                    url:updateOptionsArrayApirtk,
                    method:"PUT",
                    data:data
                }
            },
            invalidatesTags:['ArrayOptions']
        }),
    })
})


export const { useLazyGetOptionsQuery,useUpdateOptionsArrayMutation,useUpdatePaymentTypeArrayMutation } = CommonApi