import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DeletePurchaseOrederAPI } from "../../../constants/Api/Api";
import { allPurchaseOrder } from "../../../redux/features/sellers/sellerPurchaseSlice";
import { SentimentDissatisfiedSharp } from "@mui/icons-material";
import { ForFilterInPurchaseOrderList } from "../../../redux/features/sellers/FilterPurchaseOrderList";

export const DeleteingPurchaseOrder = createAsyncThunk(
  "DeleteingPurchaseOrder",
  async ({ arr, filterCredential }, thunkAPI) => {
    console.log(arr, filterCredential, "222");
    try {
      const res = await axios.delete(DeletePurchaseOrederAPI, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        data: { purchaseOrderIds: arr },
      });
      console.log(res?.data);
      if (res?.data?.success) {
        swal(res?.data?.message, { icon: "success" });
        await thunkAPI.dispatch(ForFilterInPurchaseOrderList(filterCredential));
      }
    } catch (error) {
      console.warn(error);
      swal("Sorry !", error?.response?.data?.message, "error");
    }
  }
);
