import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderImage from "../../common/LoaderImage";
import {
  PaymentMadeList,
  paymentToExcel,
  seachPaymentsMade,
} from "../../../redux/aboutPaymentsMade/AllPaymentMade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faSort,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SearchingDropdownForFilter from "../../common/SearchingDropdownForFilter";
import CommonPaginateSection from "../../common/CommonPaginateSection";
import { Checkbox } from "antd";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";

import { Popover, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import FilterByDate from "../../common/FilterByDate";
import { errorToast } from "../../common/ToastMessege";
import {
  useDeletePaymentMutation,
  useLazyGetPaymentMadeQuery,
  useSearchPaymentsMadeBywordMutation,
} from "../../../redux/reduxNeeded/reduxBase";
import {
  useDownloadInExcelMutation,
  usePrintInPdfMutation,
} from "../../../redux/reduxNeeded/PrintAndDownload";
import {
  PaymentMadeToExcelApirtk,
  printPaymentsMadeApirtk,
} from "../../../constants/Api/rtkAPI";

const PaymentsMade = () => {
  // const { data, error, isLoading, isFetching, isError, refetch } =
  //   useGetPaymentMadeQuery();
  const [
    triggerGetAllPayments,
    {
      data: paymentMadeList,
      isError: paymentMadeStatus,
      isLoading,
      error: paymentMadeError,
    },
  ] = useLazyGetPaymentMadeQuery();
  const [deletePayment, { isLoading: deleteLoading }] =
    useDeletePaymentMutation();

  const [
    searchPM,
    {
      isLoading: paymentTypingLoading,
      isError: isSearchPMError,
      error: searchPMError,
      data: searchPaymentList,
    },
  ] = useSearchPaymentsMadeBywordMutation();

  const [
    downloadInExcelFormat,
    { isLoading: excelLoading, isError: excelIsError, error: excelError },
  ] = useDownloadInExcelMutation();

  const [printPaymentsMadeFn, { isLoading: isPrintLoading }] =
    usePrintInPdfMutation();

  // const { loading, searchPaymentList, paymentTypingLoading } = useSelector(
  //   (a) => a.AllPaymentMadeLists
  // );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [word, setWord] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedBill, setSelectedBill] = useState([]);
  const [toggleAllBillSelect, setToggleAllBillSelect] = useState(false);
  const [filteringData, setFilteringData] = useState({
    // poStatus: "",
    // word: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    triggerGetAllPayments(filteringData);
  }, [filteringData]);

  useEffect(() => {
    setSelectedBill((pre) => {
      let res = [];
      if (toggleAllBillSelect) {
        paginateddata.forEach((element) => {
          res.push(element?._id);
        });
      } else {
        res = [];
      }
      return res;
    });
  }, [toggleAllBillSelect]);

  useEffect(() => {
    // dispatch(seachPaymentsMade(word));
    searchPM(word);
  }, [word]);

  const dataToSendForFilterByWord = {
    word,
    setWord,
    showDataOfSearch: !isSearchPMError ? searchPaymentList : searchPMError,
    typingLoading: paymentTypingLoading,
    viewPage: "/viewPayment/",
  };
  const paginateddata = paymentMadeList?.slice(
    (pageNumber - 1) * perPage,
    perPage * pageNumber
  );

  const totalPageNumber = Math.ceil(paymentMadeList?.length / perPage);
  const dataToSendInPaginateSection = {
    setPageNumber,
    setPerPage,
    totalPageNumber,
  };

  return (
    <>
      {isLoading || deleteLoading || excelLoading || isPrintLoading ? (
        <LoaderImage />
      ) : (
        <>
          <main>
            <section className="total_parent_element bl_scrn_sec new_purchase">
              <div className="right_parent_element">
                <div className="outr-right-content">
                  <div className="bIlls_page">
                    <div className="top_drv">
                      <div className="top_billchk_top">
                        <div className="billchk_brdcrm" aria-label="breadcrumb">
                          <ol className="breadcrumb bliiN">
                            <li className="breadcrumb-item">
                              <a href="javascript:void(0);">Bills</a>
                            </li>
                            <li className="breadcrumb-item active">
                              <a href="javascript:void(0);">All Payments</a>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blls_drive new_bills_drive">
                    <div className="blls_drive_lft nw_blls_drv_lft">
                      {/* <FilterByStatus
                        setStatus={StatusOfFilteringData}
                        statusOption={POStatusOption}
                      /> */}

                      <SearchingDropdownForFilter
                        pr={dataToSendForFilterByWord}
                      />

                      {selectedBill?.length > 0 && (
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <>
                              <button
                                className="drv_lst_img"
                                variant="contained"
                                {...bindTrigger(popupState)}
                              >
                                <span className="drv_lst_pic">
                                  <FontAwesomeIcon icon={faEllipsisV} />
                                </span>
                              </button>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Typography
                                  onClick={() => {
                                    // dispatch(deletePayment(selectedBill));
                                    deletePayment(selectedBill);
                                    setToggleAllBillSelect(false);
                                    popupState.close();
                                  }}
                                  sx={{ fontSize: "17px", p: 1 }}
                                >
                                  Delete
                                </Typography>
                                <Typography
                                  onClick={() => {
                                    // dispatch(printTheBill(selectedBill));
                                    printPaymentsMadeFn({
                                      path: printPaymentsMadeApirtk,
                                      id: selectedBill,
                                      key: "paymentIds",
                                    });
                                    popupState.close();
                                  }}
                                  sx={{ fontSize: "17px", p: 1 }}
                                >
                                  Print
                                </Typography>
                                <Typography
                                  onClick={() => {
                                    downloadInExcelFormat({
                                      path: PaymentMadeToExcelApirtk,
                                      id: selectedBill,
                                      key: "paymentIds",
                                    });
                                    // dispatch(paymentToExcel(selectedBill));
                                    popupState.close();
                                  }}
                                  sx={{ fontSize: "17px", p: 1 }}
                                >
                                  To excel format
                                </Typography>
                              </Popover>
                            </>
                          )}
                        </PopupState>
                      )}
                    </div>

                    <div className="blls_drive_rght listsss">
                      <div className="drive_rght_flx">
                        <div className="drv_rght_lft">
                          <NavLink
                            to={"/createNewPaymentMade"}
                            className="nw_cstmr"
                          >
                            Create New
                            <span>+</span>
                          </NavLink>
                        </div>
                        <FilterByDate pr={setFilteringData} />
                        <div className="drv_lst_lst">
                          <button
                            className="drv_lst_img"
                            onClick={() => {
                              if (selectedBill.length > 0) {
                                printPaymentsMadeFn({
                                  path: printPaymentsMadeApirtk,
                                  id: selectedBill,
                                  key: "paymentIds",
                                });
                                // dispatch(
                                //   DownLoadInExcelFormat(selectedBill)
                                // );
                              } else {
                                errorToast("Please select one item");
                              }
                            }}
                          >
                            <span className="drv_lst_pic">
                              <img
                                src={require("../../../assets/images/prnt.png")}
                                alt=""
                              />
                            </span>
                          </button>
                          <button
                            className="drv_lst_img"
                            onClick={() => {
                              if (selectedBill.length > 0) {
                                // dispatch(paymentToExcel(selectedBill));
                                downloadInExcelFormat({
                                  path: PaymentMadeToExcelApirtk,
                                  id: selectedBill,
                                  key: "paymentIds",
                                });
                              } else {
                                errorToast("Please select one item");
                              }
                            }}
                          >
                            <span className="drv_lst_pic">
                              <img
                                src={require("../../../assets/images/bl_cp.png")}
                                alt=""
                              />
                            </span>
                          </button>
                          <button className="drv_lst_img">
                            <span className="drv_lst_pic">
                              <img
                                src={require("../../../assets/images/bl_stng.png")}
                                alt=""
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="blls_drive_tbl_ottr">
                      <table className="blls_drive_tbl_main">
                        <thead>
                          <tr>
                            <th
                              style={{ textAlign: "left", paddingLeft: "7rem" }}
                            >
                              <Checkbox
                                style={{ marginRight: "10px" }}
                                onChange={() => {
                                  setToggleAllBillSelect((pre) => !pre);
                                }}
                              />
                              Date
                            </th>
                            <th>Payment number</th>
                            <th>Supplier Name</th>
                            <th>Bill number</th>
                            <th>Payment mode</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!paymentMadeStatus ? (
                            paginateddata?.map((each) => {
                              return (
                                <tr
                                  style={{ cursor: "pointer" }}
                                  key={each?._id}
                                  onClick={() => {
                                    navigate(`/viewPayment/${each?._id}`);
                                  }}
                                >
                                  <td
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                  >
                                    <div className="bl_no_flx">
                                      <div className="bl_no_chkbx_otr">
                                        <Checkbox
                                          style={{ marginRight: "10px" }}
                                          checked={selectedBill.includes(
                                            each?._id
                                          )}
                                          onChange={(e) => {
                                            e.stopPropagation();
                                            setSelectedBill((pre) => {
                                              const res = [...pre];
                                              const indexofId = res.indexOf(
                                                each?._id
                                              );
                                              // console.log(indexofId, "abc");
                                              if (indexofId === -1) {
                                                return [...res, each?._id];
                                              } else {
                                                res.splice(indexofId, 1);
                                                return res;
                                              }
                                            });
                                          }}
                                        />
                                      </div>
                                      <span className="bl_no_spn">
                                        {each?.paymentDate.slice(0, 10)}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {each?.paymentNumber}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {each?.supplierId?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {/* {each?.billNumber} */}
                                      {each?.bills?.map(
                                        (eachBillNum, eachind) => (
                                          <h4
                                            key={eachind}
                                            style={{
                                              fontFamily:
                                                "source_sans_proregular",
                                            }}
                                          >
                                            {`${eachBillNum?.billNumber}${
                                              eachind ===
                                              each?.bills?.length - 1
                                                ? ""
                                                : ","
                                            } `}
                                            <br />
                                          </h4>
                                        )
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {each?.paymentMode}
                                    </span>
                                  </td>{" "}
                                  <td>
                                    <span className="bl_no_spn">
                                      {each?.paymentAmount}
                                    </span>
                                  </td>
                                  <td
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="drv_tbl_icns dropdown">
                                      <button
                                        className="dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <FontAwesomeIcon icon={faSort} />
                                      </button>
                                      <ul className="dropdown-menu">
                                        <li>
                                          <button
                                            className="dpdn_btn_icns trash"
                                            onClick={() => {
                                              deletePayment([each?._id]);
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faTrash} />
                                            Delete
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <h2>{paymentMadeError?.message}</h2>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {paginateddata && paginateddata.length > 0 && (
                      <CommonPaginateSection pr={dataToSendInPaginateSection} />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default PaymentsMade;
