import { faCaretDown, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Typography } from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { formatToDate } from "../sellers/purchase/ConvertToBill";

const FilterByDate = ({ pr }) => {
  const firstDateOfMonth = new Date();
  firstDateOfMonth.setDate(1);
  // console.log(firstDateOfMonth, "abc");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // console.log(startDate, endDate, "abc");
  const todayy = new Date();

  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "Select Date";
    }
    const date = new Date(timestamp); // Convert timestamp to Date object
    const options = { month: "short", day: "numeric" }; // Define format
    return date.toLocaleDateString("en-US", options); // Convert to "Dec 4" format
  };
  // const [dateStatus, setDateStatus] = useState("This month");

  useEffect(() => {
    if (startDate && endDate) {
      pr((pre) => {
        let res = JSON.parse(JSON.stringify(pre));
        res.startDate = formatToDate(startDate);
        res.endDate = formatToDate(endDate);

        return res;
      });
    }
  }, [startDate, endDate]);
  const shortcutsItems = [
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    // {
    //   label: "Next Month",
    //   getValue: () => {
    //     const today = dayjs();
    //     const startOfNextMonth = today.endOf("month").add(1, "day");
    //     return [startOfNextMonth, startOfNextMonth.endOf("month")];
    //   },
    // },
    // { label: "Reset", getValue: () => [null, null] },
  ];
  return (
    <>
      <div className="drv_rght_rte">
        <div className="dt_rnge_ttl">
          <span className="dt_rng">Select date range :</span>
          {/* <button  >Date Range</button> */}
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <>
                <button
                  className="date_filter"
                  variant="contained"
                  {...bindTrigger(popupState)}
                >
                  {startDate == "" ? <>Start Date</> : formatDate(startDate)}
                  <> to </>
                  {endDate == "" ? <>End Date</> : formatDate(endDate)}
                  {/* {`${formatDate(startDate)} -  ${formatDate(endDate)}`} */}
                  {/* <span className="drv_lst_pic">
                    Date Range <FontAwesomeIcon icon={faCaretDown} />
                  </span> */}
                </button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      border: "3px solid #59acac", // Customize border color and width
                      borderRadius: "8px", // Optional: round the corners
                      boxShadow: "#59acac", // Optional: add shadow for better effect
                    },
                  }}
                >
                  <Typography
                    onClick={() => {
                      // popupState.close();
                    }}
                    sx={{
                      width: "750px",
                      height: "430px",
                      backgroundColor: "#FAFBFF",
                      p: 1,
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDateRangePicker
                        onChange={(e) => {
                          setStartDate(new Date(e[0]?.$d).getTime());
                          setEndDate(new Date(e[1]?.$d).getTime());
                          setTimeout(() => {
                            if (e[0]?.$d && e[1]?.$d) {
                              popupState.close();
                            }
                          }, 100);
                        }}
                        slotProps={{
                          shortcuts: {
                            items: shortcutsItems,
                          },
                          actionBar: { actions: [] },
                        }}
                        calendars={2}
                        sx={{
                          fontSize: "23px",
                          "& .MuiTypography-root": {
                            fontSize: "1.5rem",
                          },
                          "& .MuiPickersDay-root": {
                            fontSize: "1.25rem",
                          },
                          "& .MuiPickersCalendarHeader-root": {
                            fontSize: "1.5rem",
                          },
                          "& .MuiButtonBase-root": {
                            fontSize: "1.2rem",
                          },
                          backgroundColor: "#FAFBFF",
                          borderRadius: "20px",
                        }}
                      />
                    </LocalizationProvider>
                  </Typography>
                </Popover>
              </>
            )}
          </PopupState>
          {/* <select
            id="filterBillDate"
            onChange={(e) => {
              let sd = new Date();
              let ed = new Date();
              ed.setHours(23, 59, 59, 999);
              switch (e.target.value) {
                case "today":
                  setStartDate(sd);
                  setEndDate(ed);

                  break;
                case "thisWeek":
                  sd.setDate(todayy.getDate() - todayy.getDay());
                  sd.setHours(0, 0, 0, 0);
                  setStartDate(sd);
                  setEndDate(ed);
                  break;
                case "thisMonth":
                  sd.setDate(1);
                  sd.setHours(0, 0, 0, 0);
                  setStartDate(sd);
                  setEndDate(ed);
                  break;
                case "30":
                  sd.setDate(todayy.getDate() - 30);
                  sd.setHours(0, 0, 0, 0);
                  setStartDate(sd);
                  setEndDate(ed);
                  break;
                case "custom":
                  setStartDate(new Date(1900, 1, 1));
                  setDateDialog(true);
                  break;
                case "7":
                  sd.setDate(todayy.getDate() - 7);
                  sd.setHours(0, 0, 0, 0);
                  setStartDate(sd);
                  setEndDate(ed);
                  break;
                case "reset":
                  sd.setFullYear(1900);
                  setStartDate("");
                  setEndDate("");
                  break;

                default:
                  break;
              }
            }}
            className="dt_rnge_optn"
          >
            <option value="">Date range</option>
            <option value="thisMonth">This month</option>
            <option value="lastMonth">Last month</option>
            <option value="currentQuater">This month</option>
            <option value="thisMonth">This month</option>
            <option value="custom">Custom</option>
          </select> */}
        </div>
      </div>

      {/* <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography
          onClick={() => {
            // dispatch(deleteMultipleBiils([...selectedBill]));
            popupState.close();
          }}
          sx={{ fontSize: "17px", p: 1 }}
        >
          Delete
        </Typography>
        <Typography
          onClick={() => {
            popupState.close();
          }}
          sx={{ fontSize: "17px", p: 1 }}
        >
          Option 2
        </Typography>
      </Popover> */}
    </>
  );
};

export default FilterByDate;
