
import axios from "axios";
import { errorToast, successToast } from "../../components/common/ToastMessege";

export const AxiosInstance = axios.create({
  //   baseURL: "http://localhost:3000",
  headers: {
    "Content-Type": "application/json",
    Authorization:"Bearer "+localStorage.getItem('token')
  },
});

AxiosInstance.interceptors.request.use(
  (config) => {
    console.log(config, "req.succ");
    return config;
  },
  (error) => {
    // error.config.data = [{ value: "error", completed: false, id: 8 }];
    console.log(error, "req.error");
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.message) {
      successToast(response?.data?.message);
        // swal('Done',response?.data?.message??'success','success')
    }
    return response;
  },
  (error) => {
    errorToast(error.response?.data?.message);
    console.log(error, "res.error");
    return Promise.reject(error);
  }
);
