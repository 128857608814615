import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { getlistofwarehouse } from "../../constants/Api/Api"
import { act } from "react"

const initialState={
    listofwarehouse:[],
    loading:false
}

export const getTotalWarehouse=createAsyncThunk('getTotalWarehouse',async(data,thunkAPI)=>{
    try {
        const res=await axios.get(getlistofwarehouse,{
            headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        })
        // console.log(res.data.data,'warehouse')
        return res.data.data

    } catch (error) {
  //console.log(error)
  return thunkAPI.rejectWithValue()
    }
})

export const WarehouseSlice=createSlice({
    name:'Warehouse',
    initialState,

    extraReducers: (builder) => {
        builder
    
    //GET INVENTORY DETAILS
          .addCase(getTotalWarehouse.pending, (state) =>{
            state.loading = true
      })
          .addCase(getTotalWarehouse.fulfilled, (state, action) => {
              state.loading = false
              state.listofwarehouse=action.payload
      })
          .addCase(getTotalWarehouse.rejected, (state, action) => {
           state.loading = false
           state.listofwarehouse=[]
            
      })
      },
})