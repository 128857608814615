import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faEllipsisV,
  faEye,
  faMagnifyingGlass,
  faPlus,
  faSort,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";

import NavBar from "../../common/Nav/NavBar";
import { allPurchaseOrder } from "../../../redux/features/sellers/sellerPurchaseSlice";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { DeleteingPurchaseOrder } from "./DeletePurchaseOrder";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { Popover, Typography } from "@mui/material";
import LoaderImage from "../../common/LoaderImage";
import CommonPaginateSection from "../../common/CommonPaginateSection";
import SearchingDropdownForFilter from "../../common/SearchingDropdownForFilter";
import FilterByStatus from "../../common/FilterByStatus";
import FilterByDate from "../../common/FilterByDate";
import {
  filterByVendorNameAndPoNo,
  ForFilterInPurchaseOrderList,
} from "../../../redux/features/sellers/FilterPurchaseOrderList";
import RightSideCommonModal from "../../common/RightSideCommonModal";
import { printPurchaseOrderAPI } from "../../../constants/Api/Api";
import { PrintPurchaseOrder } from "../../../redux/features/sellers/PrintPurchaseOrder";
import { Checkbox } from "antd";
import { use } from "react";
// import { DownLoadInExcelFormat } from "../../../redux/features/sellers/DownLoadInExcelFromat";
import { errorToast } from "../../common/ToastMessege";
import { Label } from "reactstrap";
import { deleteMultipleBiils } from "../../../redux/GetAllBill";
import {
  useDownloadInExcelMutation,
  usePrintInPdfMutation,
  // useDownLoadPOInExcelFormatMutation,
} from "../../../redux/reduxNeeded/PrintAndDownload";
import {
  DownLoadPOInExcelApirtk,
  PrintPoInPdfApirtk,
} from "../../../constants/Api/rtkAPI";
import {
  useLazyFilteredPOQuery,
  useSearchPOListByWordMutation,
} from "../../../redux/reduxNeeded/PO";

export const POStatusOption = [
  { label: "All PO", value: "" },
  { label: "Billed", value: "billed" },
  { label: " Not Billed", value: "notBilled" },
  { label: "Received", value: "received" },
  { label: "Not Received", value: "notReceived" },
  { label: " Partially Received", value: "partiallyReceived" },
  { label: "Issued", value: "issued" },
  { label: "Draft", value: "draft" },
  { label: "Closed", value: "closed" },
];

const PurchaseOrderList = ({ pendingPoData }) => {
  // console.log(pendingPoData, "222");
  const { pendiingPurchaseOrder, tabs, setTabFun } = pendingPoData
    ? pendingPoData
    : {};

  const [
    fetchFilteredPO,
    {
      data: filteredPOData,
      isLoading: filteredPOLoading,
      isError: isFilteredPOError,
      error: filteredPOError,
    },
  ] = useLazyFilteredPOQuery();
  const { filteredPurchaseOrderList, currency } = filteredPOData || {};

  // console.log(filteredPOError, "filteredPOError");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const [modal, setModal] = useState(false);
  const [rightSideModal, setRightSideModal] = useState(false);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [toggleAllSelect, setToggleAllSelect] = useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState([]);
  const [filteringData, setFilteringData] = useState({
    poStatus: "",
    word: "",
    startDate: "",
    endDate: "",
  });
  const [word, setWord] = useState("");

  const { loading, POList } = useSelector((state) => state.sellerPurchase);
  const {
    // filteredPurchaseOrderList,
    // PurchaseOrderListLoading,
    showDataOfSearch,
    typingLoading,
    // currency,
  } = useSelector((a) => a.FilterPurchaseOrderListt);

  const [
    downloadPOInExcelFromatfuntion,
    {
      isLoading: downloadLoading,
      isError: downloadIsError,
      error: downloadError,
    },
  ] = useDownloadInExcelMutation();

  const [printInPdffn, { isLoading: printInPdfLoading }] =
    usePrintInPdfMutation();

  const [
    searchPOByWordfn,
    {
      isLoading: isSearchBy,
      data: searchedPO,
      isError: isSearchedPOError,
      error: searchedPOError,
    },
  ] = useSearchPOListByWordMutation();

  // console.log(filteredPurchaseOrderList, "test");
  // const [searchBy, setSearchBy] = useState("");
  let searchedRecords = [];
  if (pendiingPurchaseOrder) {
    searchedRecords = filteredPurchaseOrderList?.filter(
      (v) => v?.isReceived !== "received" && v?.status != "draft"
    );
    // searchedRecords = pendiingPurchaseOrder?.filter((v, i) => {
    //   return (
    //     v?.purchaseOrderNumber
    //       ?.toLowerCase()
    //       .includes(searchBy.toLowerCase()) ||
    //     v?.supplierId?.name?.toLowerCase().includes(searchBy.toLowerCase())
    //   );
    // });
  } else {
    // searchedRecords = POList?.filter((v, i) => {
    //   return (
    //     v?.purchaseOrderNumber
    //       ?.toLowerCase()
    //       .includes(searchBy.toLowerCase()) ||
    //     v?.supplierId?.name?.toLowerCase().includes(searchBy.toLowerCase())
    //   );
    // });

    searchedRecords = filteredPurchaseOrderList;
  }

  // let searchedRecords = POList?.filter((v, i) => {
  //   return (
  //     v?.purchaseOrderNumber?.toLowerCase().includes(searchBy.toLowerCase()) ||
  //     v?.supplierId?.name?.toLowerCase().includes(searchBy.toLowerCase())
  //   );
  // });

  // const filtredByDate = searchedRecords?.filter(
  //   (e, i) =>
  //     new Date(e?.dateOfPurchaseOrder).getTime() > startDate &&
  //     new Date(e?.dateOfPurchaseOrder).getTime() < endDate
  // );
  // if (endDate) {
  //   searchedRecords = filtredByDate;
  // }

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  let records = searchedRecords?.slice(firstIndex, lastIndex);
  // console.log(records, "records");

  const npage =
    searchedRecords?.length > 0 &&
    Math.ceil(searchedRecords.length / recordsPerPage);
  // console.log(npage, "npage");
  const numbers = [...Array(npage + 1).keys()].slice(1);
  // console.log(numbers, "numbers");

  // useEffect(() => {
  //   dispatch(allPurchaseOrder());
  // }, []);
  useEffect(() => {
    // dispatch(ForFilterInPurchaseOrderList(filteringData));
    // console.log(filteringData, "ddd");
    fetchFilteredPO(filteringData);
  }, [filteringData]);

  useEffect(() => {
    // dispatch(filterByVendorNameAndPoNo(word));
    searchPOByWordfn(word);
  }, [word]);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
    window.scrollTo(0, 0);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };

  const paginatedList = searchedRecords?.slice(
    (pageNumber - 1) * perPage,
    perPage * pageNumber
  );

  const totalPageNumber = Math.ceil(searchedRecords?.length / perPage);

  const dataToSendInPaginateSection = {
    setPageNumber,
    setPerPage,
    totalPageNumber,
  };

  const dataToSendToRightSideModal = { rightSideModal, setRightSideModal };

  useEffect(() => {
    setSelectedPurchaseOrder((pre) => {
      let res = [];
      if (toggleAllSelect) {
        paginatedList.forEach((element) => {
          res.push(element?._id);
        });
      } else {
        res = [];
      }
      return res;
    });
  }, [toggleAllSelect]);
  useEffect(() => {
    setToggleAllSelect(false);
  }, [perPage, filteredPurchaseOrderList, pendiingPurchaseOrder]);

  const StatusOfFilteringData = (val) => {
    setFilteringData((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res.poStatus = val;
      return res;
    });
  };

  const dataToSendForFilterByWord = {
    word,
    setWord,
    showDataOfSearch: isSearchedPOError ? [] : searchedPO,
    typingLoading: isSearchBy,
    viewPage: "/purchase/purchase-order-detail/",
  };
  return (
    <>
      {downloadLoading || printInPdfLoading || filteredPOLoading ? (
        <LoaderImage />
      ) : (
        <main>
          <section className="total_parent_element bl_scrn_sec new_purchase">
            <div className="right_parent_element">
              <div className="outr-right-content">
                <div className="bIlls_page">
                  <div className="top_drv">
                    <div className="top_billchk_top">
                      <div className="billchk_brdcrm" aria-label="breadcrumb">
                        <ol className="breadcrumb bliiN">
                          <li className="breadcrumb-item">
                            <a href="javascript:void(0);">Bills</a>
                          </li>
                          <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">
                              {pendiingPurchaseOrder
                                ? "Pending purchase orders"
                                : "List Of Purchase Order"}
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>
                    {tabs && (
                      <div style={{ display: "flex", gap: "2rem" }}>
                        <div
                          className="record_pmnt"
                          onClick={() => {
                            // navigate(`/purchaseRecives/pendingPO`);
                            setTabFun("PendingPO");
                          }}
                        >
                          <a
                            href="javascript:void(0);"
                            className={`${
                              tabs == "PendingPO" ? "edit" : "edit unSelected"
                            }`}
                          >
                            Pending order
                          </a>
                        </div>
                        <div
                          className="record_pmnt"
                          onClick={() => {
                            // navigate(`/purchaseRecives`);
                            setTabFun("AllPR");
                          }}
                        >
                          <a
                            href="javascript:void(0);"
                            className={`${
                              tabs == "AllPR" ? "edit" : "edit unSelected"
                            }`}
                          >
                            Goods Received
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="top_bill_postn">
                  <div className="bill_postn_flx">
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Total Payables</span>
                      <h5 className="bill_prt_hdng"></h5>
                    </div>
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Due Today</span>
                      <h5 className="bill_prt_hdng"></h5>
                    </div>
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Due within 30 days</span>
                      <h5 className="bill_prt_hdng"></h5>
                    </div>
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Over Due Bills</span>
                      <h5 className="bill_prt_hdng"></h5>
                    </div>
                  </div>
                </div> */}
                <div className="blls_drive new_bills_drive">
                  <div className="blls_drive_lft nw_blls_drv_lft">
                    <FilterByStatus
                      setStatus={StatusOfFilteringData}
                      statusOption={POStatusOption}
                    />

                    <SearchingDropdownForFilter
                      pr={dataToSendForFilterByWord}
                    />
                    {/* {word.length >= 3 && (
                      <div className="div_row_drops">
                        <div className="div_row_drops_inr">
                          <ul
                            style={{
                              position: "static",
                              backgroundColor: "#f8f9fd",
                            }}
                          >
                            {typingLoading ? (
                              <h1>loading ...</h1>
                            ) : showDataOfSearch.length > 0 ? (
                              showDataOfSearch?.map((v) => (
                                <li
                                  className="eachLIOfSearchingDropdown"
                                  key={v?._id}
                                >
                                  {`${v?.supplierId?.name} ${v?.purchaseOrderNumber}`}
                                </li>
                              ))
                            ) : (
                              <h1>No result matched</h1>
                            )}
                          </ul>
                        </div>
                      </div>
                    )} */}

                    {/* <div className="drv_rght_rte">
                    <input
                      type="search"
                      placeholder="Search vendor name"
                      onChange={(e) => {
                        if (e.target.value.trim().length >= 3) {
                          setWord(e.target.value);
                        } else {
                          setWord("");
                        }
                        // setSearchBy(e.target.value.trimStart());
                      }}
                    />
                  </div> */}

                    {/* {word.length >= 3 && (
                    <ul style={{ position: "static" }}>
                      {!typingLoading ? (
                        showDataOfSearch?.map((v) => (
                          <li key={v?._id}>
                            {v?.supplierId?.name}
             s     <br /> {v?.purchaseOrderNumber}
                          </li>
                        ))
                      ) : (
                        <li>Loading ...</li>
                      )}
                    </ul>
                  )} */}

                    {selectedPurchaseOrder?.length > 0 && (
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <>
                            <button
                              className="drv_lst_img"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <span className="drv_lst_pic">
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </span>
                            </button>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <Typography
                                onClick={() => {
                                  dispatch(
                                    DeleteingPurchaseOrder({
                                      arr: [...selectedPurchaseOrder],
                                      filterCredential: filteringData,
                                    })
                                  );
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                Delete
                              </Typography>
                              <Typography
                                onClick={() => {
                                  // dispatch(
                                  //   PrintPurchaseOrder(selectedPurchaseOrder)
                                  // );

                                  printInPdffn({
                                    path: PrintPoInPdfApirtk,
                                    id: selectedPurchaseOrder,
                                    key: "poIds",
                                  });
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                Print
                              </Typography>
                              <Typography
                                onClick={() => {
                                  // dispatch(
                                  //   DownLoadInExcelFormat(selectedPurchaseOrder)
                                  // );
                                  downloadPOInExcelFromatfuntion({
                                    path: DownLoadPOInExcelApirtk,
                                    id: selectedPurchaseOrder,
                                    key: "poIds",
                                  });
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                To excel format
                              </Typography>
                            </Popover>
                          </>
                        )}
                      </PopupState>
                    )}
                  </div>
                  <div className="blls_drive_rght listsss">
                    <div className="drive_rght_flx">
                      <div className="drv_rght_lft">
                        <NavLink
                          to={"/purchase/purchase-order/new"}
                          className="nw_cstmr"
                        >
                          Create New PO
                          <span>+</span>
                        </NavLink>
                      </div>
                      <FilterByDate pr={setFilteringData} />
                      <div className="drv_lst_lst">
                        <button
                          className="drv_lst_img"
                          onClick={() => {
                            if (selectedPurchaseOrder.length > 0) {
                              // dispatch(
                              //   PrintPurchaseOrder(selectedPurchaseOrder)
                              // );
                              printInPdffn({
                                path: PrintPoInPdfApirtk,
                                id: selectedPurchaseOrder,
                                key: "poIds",
                              });
                            } else {
                              errorToast("Please select one item");
                            }
                          }}
                        >
                          <span className="drv_lst_pic">
                            <img
                              src={require("../../../assets/images/prnt.png")}
                              alt=""
                            />
                          </span>
                        </button>
                        <button
                          className="drv_lst_img"
                          onClick={() => {
                            if (selectedPurchaseOrder.length > 0) {
                              // dispatch(
                              //   DownLoadInExcelFormat(selectedPurchaseOrder)
                              // );
                              downloadPOInExcelFromatfuntion({
                                path: DownLoadPOInExcelApirtk,
                                id: selectedPurchaseOrder,
                                key: "poIds",
                              });
                            } else {
                              errorToast("Please select one item");
                            }
                          }}
                        >
                          <span className="drv_lst_pic">
                            <img
                              src={require("../../../assets/images/bl_cp.png")}
                              alt=""
                            />
                          </span>
                        </button>
                        <button className="drv_lst_img">
                          <span className="drv_lst_pic">
                            <img
                              src={require("../../../assets/images/bl_stng.png")}
                              alt=""
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="blls_drive_tbl_ottr">
                    <table className="blls_drive_tbl_main">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>
                            <Checkbox
                              style={{ marginRight: "10px" }}
                              checked={toggleAllSelect}
                              onChange={() => {
                                setToggleAllSelect((pre) => !pre);
                              }}
                            />
                            Date
                            {/* <button
                            type="button"
                            onClick={(e) => {
                              setModal(true);
                            }}
                            className="btn btn-primary fs-4"
                          >
                            filter
                          </button> */}
                            {/* <div>{selectedPurchaseOrder.length} item selected</div> */}
                          </th>
                          <th>Purchase Order</th>
                          <th> Supplier Name</th>
                          <th>Payment Terms</th>
                          <th>Bill Status</th>
                          <th>Amount</th>
                          <th>Expected Delivery Date</th>
                          <th>Received Status</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      {!isFilteredPOError ? (
                        <tbody>
                          {paginatedList && paginatedList.length > 0 ? (
                            paginatedList?.map((po) => {
                              return (
                                <tr
                                  style={{ cursor: "pointer" }}
                                  key={po?._id}
                                  onClick={() => {
                                    navigate(
                                      `/purchase/purchase-order-detail/${po._id}`
                                    );
                                  }}
                                >
                                  <td
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                  >
                                    <div className="bl_no_flx">
                                      <Checkbox
                                        checked={selectedPurchaseOrder.includes(
                                          po?._id
                                        )}
                                        onChange={(event) => {
                                          event.stopPropagation();
                                          setSelectedPurchaseOrder((pre) => {
                                            let res = [...pre];
                                            if (event.target.checked) {
                                              return [...res, po?._id];
                                            } else {
                                              return res.filter(
                                                (v) => v != po._id
                                              );
                                            }
                                          });
                                        }}
                                      />
                                      {/* <input
                                      type="checkbox"
                                      className="bl_no_inpt"
                                      checked={selectedPurchaseOrder.includes(
                                        po?._id
                                      )}
                                      onChange={(event) => {
                                        event.stopPropagation();
                                        setSelectedPurchaseOrder((pre) => {
                                          let res = [...pre];
                                          if (event.target.checked) {
                                            return [...res, po?._id];
                                          } else {
                                            return res.filter(
                                              (v) => v != po._id
                                            );
                                          }
                                        });
                                      }}
                                    /> */}
                                      <span className="bl_no_spn">
                                        {po?.dateOfPurchaseOrder
                                          .slice(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("/")}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {po?.purchaseOrderNumber}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {po?.supplierId?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {po?.paymentTerm}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {po.isBilled ? (
                                        <h3 style={{ color: "#008080" }}>
                                          Billed
                                        </h3>
                                      ) : (
                                        <h3 style={{ color: "red" }}>
                                          Not Billed
                                        </h3>
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {`${currency}` + `${po?.totalAmount}`}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {po?.expectedDeliveryDate
                                        .slice(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("/")}
                                      <h4 style={{ color: "red" }}>
                                        {po?.overdueStatus != "" &&
                                          ` (${po?.overdueStatus})`}
                                      </h4>
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className="bl_no_spn"
                                      style={{
                                        color:
                                          po?.isReceived == "notReceived"
                                            ? "red"
                                            : po?.isReceived == "received"
                                            ? "green"
                                            : "orange",
                                      }}
                                    >
                                      {po?.isReceived == "received"
                                        ? "Received"
                                        : po?.isReceived == "partiallyReceived"
                                        ? "Partially Received"
                                        : "Not Received"}
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    <span
                                      className="bl_no_spn"
                                      style={{
                                        color:
                                          po?.status == "issued"
                                            ? "blue"
                                            : po?.status == "closed"
                                            ? "green"
                                            : "gray",
                                      }}
                                    >
                                      {po?.status}
                                    </span>
                                  </td>
                                  <td
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="drv_tbl_icns dropdown">
                                      <button
                                        className="dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <FontAwesomeIcon icon={faSort} />
                                      </button>
                                      <ul className="dropdown-menu">
                                        <li>
                                          <button
                                            className="dpdn_btn_icns trash"
                                            onClick={() => {
                                              dispatch(
                                                DeleteingPurchaseOrder({
                                                  arr: [po?._id],
                                                  filterCredential:
                                                    filteringData,
                                                })
                                              );
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faTrash} />
                                            Delete
                                          </button>
                                        </li>
                                        {/* 
                                <li>
                                  <NavLink
                                    to={`/purchase/purchase-order-detail/${po._id}`}
                                  >
                                    <button className="dpdn_btn_icns eye">
                                      <FontAwesomeIcon icon={faEye} />
                                      View
                                    </button>
                                  </NavLink>
                                </li> */}

                                        {!po.isBilled &&
                                          po?.status != "draft" && (
                                            <li>
                                              <NavLink
                                                to={`/purchase/bill/${po._id}`}
                                              >
                                                <button className="dpdn_btn_icns eye">
                                                  <FontAwesomeIcon
                                                    icon={faArrowRight}
                                                  />
                                                  Bill now
                                                </button>
                                              </NavLink>
                                            </li>
                                          )}
                                        {po?.isReceived !== "received" &&
                                          po?.status != "draft" && (
                                            <li>
                                              <NavLink
                                                to={`/reciveBill/${po?._id}`}
                                              >
                                                <button className="dpdn_btn_icns eye">
                                                  <FontAwesomeIcon
                                                    icon={faArrowLeft}
                                                  />
                                                  Receive
                                                </button>
                                              </NavLink>
                                            </li>
                                          )}
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <h1>No purchase order found</h1>
                          )}
                        </tbody>
                      ) : (
                        <h1> {filteredPOError?.message || "error"} </h1>
                      )}
                    </table>
                  </div>
                  {paginatedList && paginatedList.length > 0 && (
                    <CommonPaginateSection pr={dataToSendInPaginateSection} />
                  )}
                </div>

                {/* <div className="oder_history">
                  <div className="order_hdr">
                    <div className="ordre_lft">
                      <h6>
                        {pendiingPurchaseOrder
                          ? "Pending purchase orders"
                          : "List Of Purchase Order"}
                      </h6>
                    </div>
                  </div>

                  <div className="newOrderBox">
                    <div className="drv_rght_rte">
                      <input
                        onChange={(e) => {
                          setSearchBy(e.target.value.trimStart());
                        }}
                        type="search"
                        placeholder="Search supplier name "
                      />
                    </div>
                    <NavLink to={"/purchase/purchase-order"}>
                      <button type="button" className="edit">
                        Create new Purchase order
                      </button>
                    </NavLink>

                    {selectedPurchaseOrder.length >= 1 && (
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <>
                            <button
                              className="drv_lst_img"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <span className="drv_lst_pic">
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </span>
                            </button>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <Typography
                                onClick={() => {
                                  dispatch(
                                    DeleteingPurchaseOrder(
                                      selectedPurchaseOrder
                                    )
                                  );
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                Delete
                              </Typography>
                              <Typography
                                onClick={() => {
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                Option 2
                              </Typography>
                            </Popover>
                          </>
                        )}
                      </PopupState>
                    )}

                    <h2
                      style={{
                        position: "absolute",
                        right: "37%",
                      }}
                    >
                      {dayjs(new Date(endDate)).format("MMM D, YYYY") !==
                        "Invalid Date" &&
                        endDate !== null &&
                        dayjs(new Date(startDate)).format("MMM D, YYYY") +
                          " to " +
                          dayjs(new Date(endDate)).format("MMM D, YYYY")}
                    </h2>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        </main>
      )}
      {/* <Drawer
        // open={modal}
        onClose={(e) => {
          // setModal(false);
        }}
      >
        <Box sx={{ width: 850 }} role="presentation">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              onChange={(e) => {
                setStartDate(new Date(e[0]?.$d).getTime());
                setEndDate(new Date(e[1]?.$d).getTime() + 86399998);
              }}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                actionBar: { actions: [] },
              }}
              calendars={2}
            />
          </LocalizationProvider>
        </Box>
        <button
          className={` fs-1 btn btn-success w-25   `}
          disabled={!endDate}
          onClick={(e) => {
            setModal(false);
          }}
        >
          Search
        </button>
      </Drawer> */}
      {/* <button
        type="button"
        onClick={() => {
          setRightSideModal(true);
        }}
      >
        open test modal
      </button> */}
      {/* <RightSideCommonModal pr={dataToSendToRightSideModal} /> */}
    </>
  );
};

export default PurchaseOrderList;
