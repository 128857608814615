import { faClose, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import SearchingSupplier from "../../common/SearchingSupplier";
import {
  useCreateNewPaymentMadeMutation,
  useLazyGeneratePaymentNumberQuery,
  useLazyGetBillBySuppIdQuery,
} from "../../../redux/reduxNeeded/CreatePaymentMade";
import { Autocomplete, Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { GenerateBillNoForPayment } from "../../../redux/features/sellers/GenereteBillNumberForPayment";
import { useFieldArray, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { formatToDate } from "../purchase/ConvertToBill";
import CommonPaymentModeDropDown from "../../common/CommonPaymentModeDropDown";
import CommonMultipleFile from "../../common/CommonMultipleFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { errorToast } from "../../common/ToastMessege";
import { useNavigate } from "react-router-dom";

const CreateNewPaymentMade = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fileArray, setFileArray] = useState([]);
  const [removedFile, setRemovedFile] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [suppID, setSuppId] = useState("");

  const [
    createNewPaymentMadeFn,
    {
      data: creaateNewPMData,
      isLoading: createNewPMLoading,
      isError: isCreateNewPMError,
      error: createNewPMError,
      isSuccess: isCreateNewPMSuccess,
    },
  ] = useCreateNewPaymentMadeMutation();

  const [getPaymentNumfn, { data: paymentNum }] =
    useLazyGeneratePaymentNumberQuery();
  const newPaymentMadeForm = useForm({
    defaultValues: async () => {
      return {
        // supplierId: "",
        // paymentNumber: "",
        // paymentAmount: 0,
        paymentDate: formatToDate(new Date()),
        paymentMode: "Cash",
        notes: "",
        billIds: [
          {
            billNumber: "SAT-2025-bill-1",
            billId: "6780df4b0898bbf712186114",
            amountPaid: 0,
          },
        ],
      };
    },
    mode: "all",
  });
  const {
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
  } = newPaymentMadeForm;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "billIds",
  });
  const [
    fetchUnpaidBillsFn,
    {
      isLoading: isLoadingFetchUnpaidBills,
      isFetching: isFetchingFetchUnpaidBills,
      isError: fetchingUnpaidBillsError,
      error,
      data: { unpaidBills } = {},
    },
  ] = useLazyGetBillBySuppIdQuery();

  const setSupplierId = (id) => {
    reset();
    setFileArray([]);
    setSuppId(id);
    // setValue("supplierId", id);
  };
  useEffect(() => {
    if (suppID != "") {
      fetchUnpaidBillsFn(suppID);
    }
  }, [suppID]);
  let unpaidSatateArr;

  useEffect(() => {
    if (unpaidBills?.length > 0) {
      unpaidSatateArr = unpaidBills?.reduce((acc, eachObj) => {
        return [
          ...acc,
          {
            billNumber: eachObj?.billNumber,
            billId: eachObj?._id,
            amountPaid: 0,
          },
        ];
      }, []);
      setValue("billIds", [...unpaidSatateArr]);
      // console.log(watch(), "2222");
    }
  }, [unpaidBills]);

  useEffect(() => {
    getPaymentNumfn();
    // if (paymentNum) {
    //   setValue("paymentNumber", paymentNum, {
    //     shouldValidate: true,
    //   });
    // }
  }, [getPaymentNumfn]);
  const dataToSendPaymentMode = {
    initialPaymentMode: watch("paymentMode"),
    setPaymentModefn: (data) => {
      return setValue("paymentMode", data, { shouldValidate: true });
    },
  };

  const removeFile = (val, ind) => {
    setRemovedFile((pre) => {
      return [...pre, val?._id];
    });

    setFileArray((pre) => {
      let res = [...pre];
      let res2 = res.filter((v) => v !== val);
      return [...res2];
    });
  };

  const propsToSendinCommonFile = {
    removeFile,
    setFileArray,
    fileArray,
  };
  const openFileList = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveNewPayment = () => {
    const newObj = new FormData();
    const paymentAmount = watch("billIds")?.reduce((acc, eachObj) => {
      return (acc += Number(eachObj?.amountPaid));
    }, 0);

    newObj.append("paymentAmount", paymentAmount);
    newObj.append("paymentDate", watch("paymentDate"));
    newObj.append("paymentMode", watch("paymentMode"));
    newObj.append("notes", watch("notes"));
    newObj.append("billIds", JSON.stringify(watch("billIds")));
    newObj.append("paymentNumber", paymentNum);
    newObj.append("supplierId", suppID);
    newObj.append("removedFiles", JSON.stringify(removedFile));
    if (Array.isArray(fileArray)) {
      fileArray.forEach((file, index) => {
        if (file instanceof File) {
          newObj.append("uploadFiles", file);
        }
      });
    }

    // for (let [key, value] of newObj.entries()) {
    //   console.log(key, value, "222");
    // }
    createNewPaymentMadeFn(newObj)
      .unwrap()
      .then((res) => {
        navigate("/paymentsMade");

        // if (isCreateNewPMSuccess) {
        //   navigate("/paymentsMade");
        // }
      })
      .catch((err) => {
        // errorToast(err?.data?.message);
        swal("Opps", err?.data?.message, "error");
      });
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFileList}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fileArray.length > 0 &&
          fileArray?.map((v, i) => (
            <MenuItem
              key={v?._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "30rem",
                fontSize: "1.5rem",
              }}
            >
              <span key={v?._id}>{v?.name}</span>
              <button
                type="button"
                onClick={() => {
                  removeFile(v, i);
                }}
              >
                <DeleteIcon />
              </button>
            </MenuItem>
          ))}
      </Menu>

      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // submitFromm(e, "no");
                  }}
                  className="bills_cnt_otr"
                >
                  <div className="bll_hdr">
                    <h2>New Payment </h2>
                    <span
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} size="2xl" />
                    </span>
                  </div>

                  <div className="bll_innr_c">
                    <div className="bll_l">
                      <div className="bill_l_cnt">
                        <div className="bill_l_top_bx">
                          <SearchingSupplier setSupplierId={setSupplierId} />

                          {/* {isFetchingFetchUnpaidBills && (
                            <h1 className="loader"></h1>
                          )} */}
                        </div>
                      </div>

                      {unpaidBills?.length > 0 && !fetchingUnpaidBillsError && (
                        <>
                          <div className="div_row">
                            <label>Payment Number</label>
                            <div className="inp_srch">
                              <input type="text" disabled value={paymentNum} />
                            </div>
                          </div>
                          <div className="div_row">
                            <label>Payment Date</label>
                            <div className="inp_srch">
                              <input type="date" {...register("paymentDate")} />
                            </div>
                          </div>
                          <div className="div_row">
                            <label>
                              Payment Mode <span>*</span>
                            </label>
                            <CommonPaymentModeDropDown
                              {...dataToSendPaymentMode}
                            />
                          </div>

                          <div className="div_row">
                            <div className="d-flex">
                              <label>Notes</label>
                              <span className="spon">
                                It will not be shown in PDF
                              </span>
                            </div>
                            <div className="inp_srch">
                              <input type="text" {...register("notes")} />
                            </div>
                          </div>
                          <CommonMultipleFile pr={propsToSendinCommonFile} />
                        </>
                      )}
                    </div>
                    {!fetchingUnpaidBillsError && unpaidBills?.length > 0 && (
                      <div className="bll_r">
                        <div className="bll_r_tp_hdr">
                          <div className="bllr_hdr">
                            <h3>Item Table</h3>
                          </div>
                          <div className="bll_tbl_otr">
                            <div className="bll_tbl_innr">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Bill Number</th>
                                    <th>Bill Amount </th>
                                    <th>Amount Due </th>
                                    <th>Payment </th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields?.map((EachPO, poind) => {
                                    return (
                                      <tr>
                                        <td>
                                          {unpaidBills[poind]?.billDate?.slice(
                                            0,
                                            10
                                          )}
                                        </td>
                                        <td>
                                          {unpaidBills[poind]?.billNumber}
                                        </td>
                                        <td>
                                          {unpaidBills[poind]?.totalAmount}
                                        </td>
                                        <td>
                                          {unpaidBills[poind]?.balanceDue}
                                        </td>
                                        <td>
                                          <input
                                            className="cnvrt_text_nmbr"
                                            type="number"
                                            max={unpaidBills[poind]?.balanceDue}
                                            min={0}
                                            {...register(
                                              `billIds.${poind}.amountPaid`,
                                              {
                                                max: {
                                                  value:
                                                    unpaidBills[poind]
                                                      ?.balanceDue,
                                                  message: `Amount cannot exceed then amount due.`,
                                                },
                                                min: {
                                                  value: 0,
                                                  message:
                                                    "Amount cannot be less than 0.",
                                                },
                                              }
                                            )}
                                          />
                                          <span
                                            className="payInFull"
                                            onClick={() => {
                                              setValue(
                                                "billIds." +
                                                  poind +
                                                  ".amountPaid",
                                                unpaidBills[poind]?.balanceDue
                                              );
                                            }}
                                          >
                                            Pay in full
                                          </span>
                                        </td>
                                        {watch("billIds")?.length > 1 && (
                                          <td
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              remove(poind);
                                            }}
                                            className="actn_td actn_td_crs text-center"
                                          >
                                            <FontAwesomeIcon icon={faXmark} />
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="cancel__save">
                            {/* <button
                              type="button"
                              className="srch_btn"
                              onClick={() => {
                                const prevBillids = watch("billIds");
                                const prevAllFields = {
                                  paymentDate: formatToDate(new Date()),
                                  paymentMode: "Cash",
                                  notes: "df",
                                  billIds: prevBillids,
                                };
                                reset(prevAllFields);
                              }}
                            >
                              Reset
                            </button> */}
                            <button
                              type="button"
                              className="srch_btn can"
                              onClick={() => {
                                navigate(-1);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={saveNewPayment}
                              className="srch_btn"
                              disabled={createNewPMLoading}
                            >
                              {createNewPMLoading ? "Loading..." : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <button
        type="button"
        onClick={() => {
          console.log(watch('paymen'), "222222");
        }}
      >
        {" "}
        see all fields{" "}
      </button> */}
      <DevTool control={control} />
    </>
  );
};

export default CreateNewPaymentMade;
