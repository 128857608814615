import { faClose, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchingSupplier from "../../common/SearchingSupplier";
import CommonMultipleFile from "../../common/CommonMultipleFile";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewPurchaseReceive,
  pendingPoByName,
} from "../../../redux/features/sellers/FilterPReceive";
import { errorToast } from "../../common/ToastMessege";
import { Autocomplete, Menu, MenuItem, Paper, TextField } from "@mui/material";
import { formatToDate } from "./ConvertToBill";
import { generetePurchaseReciveNumber } from "../../../redux/features/sellers/sellerPurchaseSlice";
import DeleteIcon from "@mui/icons-material/Delete";

const CreateNewPR = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [supplierid, setSupplierId] = useState("");
  const [fileArray, setFileArray] = useState([]);
  const [removedFile, setRemovedFile] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [picURL, setPicURL] = useState([]);
  const [allData, setAllData] = useState({
    purchaseOrderNumber: null,
    purchaseReceiveNumber: "",
    receivedDate: formatToDate(new Date()),
    notes: "",
  });
  const [productsArr, setProductsArr] = useState([]);

  useEffect(() => {
    dispatch(generetePurchaseReciveNumber());
  }, []);
  const {
    pendingPoList,
    pendingPoStatus,
    detailsViaNameInPR,
    creatingNewPRLoading,
  } = useSelector((a) => a.FilteredPurchaseReceiveList);
  const { uniqePurchaseReciveNumber } = useSelector(
    (state) => state.sellerPurchase
  );

  useEffect(() => {
    setAllData({
      purchaseOrderNumber: null,
      purchaseReceiveNumber: uniqePurchaseReciveNumber,
      receivedDate: formatToDate(new Date()),
      notes: "",
    });
    setFileArray([]);
    setRemovedFile([]);
    setAnchorEl(null);
    if (supplierid != "") {
      dispatch(pendingPoByName(supplierid));
    }
  }, [supplierid]);
  useEffect(() => {
    if (pendingPoList?.length < 1 && supplierid != "") {
      errorToast("No pending purchase order found");
    }
  }, [pendingPoList]);
  const purchaseOrderOptions = pendingPoList.map(
    (eachPO) => eachPO.purchaseOrderNumber
  );

  useEffect(() => {
    if (uniqePurchaseReciveNumber != "") {
      setAllData((pre) => {
        let res = { ...pre };
        res.purchaseReceiveNumber = uniqePurchaseReciveNumber;
        return res;
      });
    }
  }, [uniqePurchaseReciveNumber]);

  const selectProductsArr = (PoNum) => {
    const proArr = pendingPoList?.filter(
      (eachPO) => eachPO.purchaseOrderNumber == PoNum
    );
    const addQtyToReceive = proArr[0]?.products?.reduce((acc, eachrow) => {
      return [
        ...acc,
        {
          ...eachrow,
          qtyToReceive: eachrow.quantity - eachrow.quantityReceived,
        },
      ];
    }, []);
    // setProductsArr(proArr[0]?.products);
    setProductsArr(addQtyToReceive);
  };
  const removeFile = (val, ind) => {
    setRemovedFile((pre) => {
      return [...pre, val?._id];
    });

    setFileArray((pre) => {
      let res = [...pre];
      let res2 = res.filter((v) => v !== val);
      return [...res2];
    });
  };
  const openFileList = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const propsToSendinCommonFile = {
    removeFile,
    setFileArray,
    setPicURL,
    fileArray,
  };

  const saveAsReceived = () => {
    const newObj = new FormData();
    for (const key in allData) {
      newObj.append(key, allData[key]);
    }
    newObj.append("removedFiles", JSON.stringify(removedFile));
    if (Array.isArray(fileArray)) {
      fileArray.forEach((file, index) => {
        if (file instanceof File) {
          newObj.append("uploadFiles", file);
        }
      });
    }

    const proArrDetails = productsArr.reduce((acc, eachrow) => {
      return [
        ...acc,
        {
          productId: eachrow.productId,
          variantId: eachrow.variantId._id,
          quantityOrdered: eachrow.quantity,
          quantityReceived: eachrow.quantityReceived,
          quantityToReceive: eachrow.qtyToReceive,
          unitCost: eachrow.unitCost,
          totalCost: eachrow.totalCost,
        },
      ];
    }, []);
    newObj.append("products", JSON.stringify(proArrDetails));
    for (let [key, value] of newObj.entries()) {
      console.log(key, value, "222");
    }

    dispatch(createNewPurchaseReceive({ newObj, navigate }));
  };

  console.log(detailsViaNameInPR?.address, "222222");

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFileList}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fileArray.length > 0 &&
          fileArray?.map((v, i) => (
            <MenuItem
              key={v?._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "30rem",
                fontSize: "1.5rem",
              }}
            >
              <span key={v?._id}>{v?.name}</span>
              <button
                type="button"
                onClick={() => {
                  removeFile(v, i);
                }}
              >
                <DeleteIcon />
              </button>
            </MenuItem>
          ))}
      </Menu>
      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // submitFromm(e, "no");
                  }}
                  className="bills_cnt_otr"
                >
                  <div className="bll_hdr">
                    <h2>Create new Purchase receive </h2>
                    <span
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} size="2xl" />
                    </span>
                  </div>

                  <div className="bll_innr_c">
                    <div className="bll_l">
                      <div className="bill_l_cnt">
                        <div className="bill_l_top_bx">
                          <SearchingSupplier setSupplierId={setSupplierId} />

                          {pendingPoStatus && <h1 className="loader"></h1>}

                          {detailsViaNameInPR && !pendingPoStatus && (
                            <>
                              <div className="clum_div">
                                <div className="div_row">
                                  <label>Phone No</label>
                                  <div className="inp_srch">
                                    <input
                                      disabled
                                      type="text"
                                      value={`${detailsViaNameInPR?.countryCode}  ${detailsViaNameInPR?.phoneNumber}`}
                                    />
                                  </div>
                                </div>
                                <div className="div_row">
                                  <label>Email</label>
                                  <div className="inp_srch">
                                    <input
                                      className="eml"
                                      disabled
                                      type="text"
                                      value={detailsViaNameInPR?.email}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="div_row">
                                <label>address</label>
                                <div className="inp_srch">
                                  <input
                                    type="text"
                                    disabled
                                    value={`${detailsViaNameInPR?.address[0]?.street} ${detailsViaNameInPR?.address[0]?.city} ${detailsViaNameInPR?.address[0]?.state} ${detailsViaNameInPR?.address[0]?.zipCode} ${detailsViaNameInPR?.address[0]?.country}`}
                                    placeholder="34 Crosby Drive, Suite 202, Bedford, MA  01730"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      {pendingPoList?.length > 0 && supplierid != "" && (
                        <div className="clum_div">
                          <div className="div_row w-100">
                            <label>
                              Purchase order number<span>*</span>
                            </label>
                            <Autocomplete
                              onBlur={(e) => {
                                if (
                                  !allData.purchaseOrderNumber ||
                                  allData.purchaseOrderNumber == ""
                                ) {
                                  setAllData((pre) => {
                                    let res = JSON.parse(JSON.stringify(pre));
                                    res.purchaseOrderNumber = "";
                                    return res;
                                  });
                                }
                              }}
                              options={purchaseOrderOptions}
                              onChange={(e, v) => {
                                setFileArray([]);
                                selectProductsArr(v);
                                setAllData((pre) => {
                                  let res = JSON.parse(JSON.stringify(pre));
                                  res.purchaseOrderNumber = v;
                                  res.notes = "";
                                  res.receivedDate = formatToDate(new Date());
                                  return res;
                                });
                              }}
                              value={allData.purchaseOrderNumber}
                              label="Please select purchase order number"
                              getOptionSelected={(option, value) =>
                                option == value
                              }
                              renderInput={(params) => (
                                <TextField
                                  placeholder="Please select purchase order number"
                                  {...params}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    borderRadius: "16px",
                                    fontFamily: "source_sans_proregular",
                                    zIndex: 1,
                                  }}
                                />
                              )}
                              ListboxProps={{
                                sx: {
                                  fontSize: "1.5rem",
                                },
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {allData.purchaseOrderNumber && (
                        <>
                          <div className="clum_div ">
                            <div className="div_row">
                              <label>
                                Purchase receive number<span>*</span>
                              </label>
                              <div className="inp_srch">
                                <input
                                  type="text"
                                  value={allData.purchaseReceiveNumber}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="div_row">
                              <label>
                                Purchase receive date<span>*</span>
                              </label>
                              <div className="inp_srch">
                                <input
                                  type="date"
                                  value={allData.receivedDate}
                                  onChange={(e) => {
                                    setAllData((pre) => {
                                      let res = { ...pre };
                                      res.receivedDate = formatToDate(
                                        e.target.value
                                      );
                                      return res;
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="div_row">
                            <div className="d-flex">
                              <label>Notes</label>
                              <span className="spon">
                                It will not be shown in PDF
                              </span>
                            </div>
                            <div className="inp_srch">
                              <input
                                type="text"
                                value={allData.notes}
                                onChange={(e) => {
                                  setAllData((pre) => {
                                    let res = { ...pre };
                                    res.notes = e.target.value;
                                    return res;
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <CommonMultipleFile pr={propsToSendinCommonFile} />
                        </>
                      )}
                    </div>
                    {allData.purchaseOrderNumber && productsArr.length > 0 && (
                      <div className="bll_r">
                        <div className="bll_r_tp_hdr">
                          <div className="bllr_hdr">
                            <h3>Item Table</h3>
                          </div>
                          <div className="bll_tbl_otr">
                            <div className="bll_tbl_innr">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Items & Description</th>
                                    <th>Ordered</th>
                                    <th>Received</th>
                                    <th>Quantity to Receive </th>
                                    <th>Action </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productsArr?.map((product, proind) => {
                                    const thumbnillPIc =
                                      product?.variantId?.productPictures?.filter(
                                        (eachpic) => eachpic?.thumbnail
                                      );
                                    return (
                                      <tr>
                                        <td className="img_bll">
                                          <div>
                                            <img
                                              src={
                                                thumbnillPIc[0]?.url ??
                                                require("../../../assets/images/bill_imgt.png")
                                              }
                                            />
                                          </div>
                                          <div className="cnvrt_text">
                                            <h5>
                                              {product?.productDetails?.name}
                                              <br />
                                              {`Price: ${product?.unitCost}`}
                                              {`Color: ${product?.productDetails?.color}`}
                                            </h5>
                                          </div>
                                        </td>
                                        <td>
                                          <div>{product.quantity}</div>
                                        </td>
                                        <td>
                                          <div>{product?.quantityReceived}</div>
                                        </td>
                                        <td>
                                          <input
                                            className="cnvrt_text_nmbr"
                                            type="number"
                                            min={1}
                                            value={product?.qtyToReceive}
                                            onChange={(e) => {
                                              setProductsArr((pre) => {
                                                let res = JSON.parse(
                                                  JSON.stringify(pre)
                                                );
                                                if (
                                                  product?.quantity -
                                                    product?.quantityReceived <
                                                  Number(e.target.value)
                                                ) {
                                                  errorToast(
                                                    "Quantity recorded cannot be more than quantity ordered"
                                                  );
                                                } else {
                                                  res[proind].qtyToReceive =
                                                    Number(e.target.value);
                                                }
                                                return res;
                                              });
                                            }}
                                          />
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setProductsArr((pre) => {
                                              let res = JSON.parse(
                                                JSON.stringify(pre)
                                              );
                                              res.splice(proind, 1);
                                              return res;
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faXmark} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="cancel__save">
                            <button
                              type="button"
                              className="srch_btn can"
                              onClick={() => {
                                navigate(-1);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={saveAsReceived}
                              className="srch_btn"
                            >
                              {creatingNewPRLoading
                                ? "submitting ..."
                                : "Save As Received"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default CreateNewPR;
