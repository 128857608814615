import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { printPurchaseOrderAPI } from "../../../constants/Api/Api";


//  export const downloadPDF = (fileUrl) => {
//     // Create an anchor element
//     const link = document.createElement('a');
//     link.href = fileUrl;
//     // Set the 'download' attribute to force download
//     link.download = fileUrl.split('/').pop(); // Optional: Set a specific filename if needed
//     // Append the anchor to the body
//     document.body.appendChild(link);
//     // Trigger the click event
//     link.click();
//     // Remove the anchor from the document
//     document.body.removeChild(link);
//   };

  export const downloadPDF = (fileUrl) => {
    // Create an anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank'; // Open the file in a new tab
    // Append the anchor to the body
    document.body.appendChild(link);
    // Trigger the click event
    link.click();
    // Remove the anchor from the document
    document.body.removeChild(link);
  };

export const PrintPurchaseOrder=createAsyncThunk('PrintPurchaseOrder',async(data,thunkAPI)=>{
    try {
        const res= await axios.post(printPurchaseOrderAPI,{poIds:[...data]},{
            headers:{Authorization:'Bearer '+localStorage.getItem('token'),
                // Accept: 'application/pdf',
                // 'Content-Type': 'application/pdf',
            }
         })  
         if (res?.data?.success) {
            // console.log(res?.data,'222')
        //     const fileURL = res.data.fileUrl;
        // const link = document.createElement('a');
        // link.href = fileURL;
        // const fileName = fileURL.split('/').pop();
        // link.download = fileName;
        // link.click();
            // window.open(res.data.fileUrl, '_blank');

            downloadPDF(res?.data?.fileUrl)
         }
    } catch (error) {
        console.warn(error);        
    }
})