import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { filterByVendorNameAndPurchase, ForFilterPurchaseOrderListAPI } from "../../../constants/Api/Api";



export const ForFilterInPurchaseOrderList=createAsyncThunk('ForFilterInPurchaseOrderList',async({poStatus,word,startDate,endDate},thunkAPI)=>{
    try {
        const res=await axios.get(`${ForFilterPurchaseOrderListAPI}?poStatus=${poStatus}&startDate=${startDate}&endDate=${endDate}`,{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })
        console.log(res?.data,'aaa')
        return res.data
    } catch (error) {
     console.warn(error);
     return thunkAPI.rejectWithValue()
        
    }
}) 

export const filterByVendorNameAndPoNo=createAsyncThunk('filterByVendorNameAndPoNo',async(data,thunkAPI)=>{
    try {
        console.log(data,'test')
        const res=await axios.get(`${filterByVendorNameAndPurchase}?word=${data}`,{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })
        console.log(res?.data,'test')
        return res.data
    } catch (error) {
     console.warn(error,'aaa');
     return thunkAPI.rejectWithValue()  
    }
}) 





export const FilterPurchaseOrderListSlice=createSlice({
    name:'FilterPurchaseOrderListSlice',
    initialState:{
        PurchaseOrderListLoading:false,
        typingLoading:false,
        filteredPurchaseOrderList:[],
        showDataOfSearch:[],
        currency:undefined
    },
    extraReducers:(builder)=>{
        builder.addCase(ForFilterInPurchaseOrderList.pending,(state,action)=>{
            state.PurchaseOrderListLoading=true
        }).addCase(ForFilterInPurchaseOrderList.fulfilled,(state,action)=>{
            state.PurchaseOrderListLoading=false
            state.filteredPurchaseOrderList=action.payload.data
            state.currency=action.payload.currency
        }).addCase(ForFilterInPurchaseOrderList.rejected,(state,action)=>{
            state.PurchaseOrderListLoading=false
            state.filteredPurchaseOrderList=[]
        })

        builder.addCase(filterByVendorNameAndPoNo.pending,(state,action)=>{
            state.typingLoading=true
        }).addCase(filterByVendorNameAndPoNo.fulfilled,(state,action)=>{
            state.typingLoading=false
            state.showDataOfSearch=action.payload.data
        }).addCase(filterByVendorNameAndPoNo.rejected,(state,action)=>{
            state.typingLoading=false
            state.showDataOfSearch=[]
        })
    }
    
})


