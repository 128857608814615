import { downloadPDF } from "../features/sellers/PrintPurchaseOrder";
import { apiCalls } from "./reduxBase";

const printAndDwonload=apiCalls.injectEndpoints({
    endpoints:(builder)=>({
        downloadInExcel:builder.mutation({
            query:({path,id,key})=>{
                // console.log(path,id,key)
                return {
                    url:path,
                    method:'POST',
                    data:{[key]:id}
                }
            },
            transformResponse:(res)=>{
                console.log(res,'res')
                if (res?.success) {
                    const fileURL = res.fileUrl;
                    const link = document.createElement('a');
                    link.href = fileURL;
                    const fileName = fileURL.split('/').pop();
                    link.download = fileName;
                    link.click();                    
                }else{
                       console.warn(res);
                }
            }
        }),

        printInPdf:builder.mutation({
            query:({path,id,key})=>{
                console.log(path,id,key,'res')
                return {
                    url:path,
                    method:'POST',
                    data:{[key]:id}
                }
            },
            transformResponse:(res)=>{
                console.log(res,'resssssssssssss')
                if (res?.success) {
                    downloadPDF(res?.fileUrl)
                    // const link = document.createElement('a');
                    // link.href = res?.fileUrl;
                    // link.target = '_blank';
                    // document.body.appendChild(link);
                    // link.click();
                    // document.body.removeChild(link);                    
                }else{
                       console.warn(res);
                }
            }
        })
    })
})


export const { useDownloadInExcelMutation,usePrintInPdfMutation  }=printAndDwonload