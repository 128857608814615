import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { add_bill } from "../../../constants/Api/Api";


export const generateBill=createAsyncThunk('generateBill', async({bodydata,withPO,navigate,receive},thunkAPI)=>{
    // for (let [key, value] of bodydata.entries()) {
    //   console.log(key, value, "aaa");
    // }
    // console.log(pos,'abc')

    try {
      let res
      if (receive) {
        res=await axios.post(`${add_bill}?withPO=${withPO}&receive=yes`,bodydata,{
          headers:{Authorization:'Bearer '+localStorage.getItem('token')}  
        })
      } else {
         res=await axios.post(`${add_bill}?withPO=${withPO}`,bodydata,{
          headers:{Authorization:'Bearer '+localStorage.getItem('token')}  
        })
      }
        //    console.log(res,'abc')            
           if (res?.data?.success) {
               swal( "Done",res?.data?.message,"success");
             navigate("/purchase/listOfBill")
           }else{
        swal( "Oops" , res?.data?.message ,  "error" )
           }
    } catch (error) {
        console.log(error)
        swal( "Oops" , error?.response?.data?.message ,  "error" )
        
    }
})