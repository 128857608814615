import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMagnifyingGlass,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/Sidebar";

import NavBar from "../../common/Nav/NavBar";
import {
  allPurchaseOrder,
  POdetail,
} from "../../../redux/features/sellers/sellerPurchaseSlice";
import LoaderImage from "../../common/LoaderImage";

import MiniList from "../../common/MiniList";
import FilterByStatus from "../../common/FilterByStatus";
import { ForFilterInPurchaseOrderList } from "../../../redux/features/sellers/FilterPurchaseOrderList";
import { PrintPurchaseOrder } from "../../../redux/features/sellers/PrintPurchaseOrder";
import { POStatusOption } from "./PurchaseOrderList";
import CommonFileViewPage from "../../common/CommonFileViewPage";
// import { purchaseOrderDetail } from "../../common/RightSideCommonModal";
const PurchaseOrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [idOfItem, setIdOfItem] = useState(id);

  // const [totalAmount, setTotalAmount] = useState(0);

  const { loading, purchaseOrderDetail } = useSelector(
    (state) => state.sellerPurchase
  );
  console.log(purchaseOrderDetail, "222");
  const [filteringData, setFilteringData] = useState({
    poStatus: "",
    word: "",
    startDate: "",
    endDate: "",
  });
  const setStatuss = (val) => {
    setFilteringData((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res.poStatus = val;
      return res;
    });
  };
  useEffect(() => {
    dispatch(ForFilterInPurchaseOrderList(filteringData));
    console.log(filteringData, "ddd");
  }, [filteringData]);
  const {
    filteredPurchaseOrderList,
    PurchaseOrderListLoading,
    showDataOfSearch,
    typingLoading,
    currency,
  } = useSelector((a) => a.FilterPurchaseOrderListt);
  // console.log(filteredPurchaseOrderList, "222");
  useEffect(() => {
    dispatch(POdetail(idOfItem)).then((res) => {
      console.log(res, "res");
      // const { products, totalAmount: tt } = res.payload;
      // setTotalAmount(tt);
    });
  }, [idOfItem]);

  const dataToSendMiniList = {
    listData: filteredPurchaseOrderList,
    activeItem: idOfItem,
    loading: loading,
    currtype: currency,
    changeItem: (val) => {
      setIdOfItem(val);
    },
    destination: "/purchase/purchase-order-detail/",
  };
  console.log(filteringData, "222f");

  return (
    <>
      <div className="ttl_mdl"></div>
      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page bill_details_page">
                <div className="bills_cnt_otr">
                  <div className="bll_hdr">
                    <h2>Purchase Order Details</h2>
                  </div>
                  <div className="bill_screen_top">
                    {/* select box */}
                    <FilterByStatus
                      newButtonName={"PO"}
                      newButtonPath={"/purchase/purchase-order/new"}
                      setStatus={setStatuss}
                      statusOption={POStatusOption}
                    />
                    {/* <div className="blls_drive_lft bill_select">
                          <select>
                            <option value="bill">All Bills</option>
                            <option value="bill">All Bills</option>
                            <option value="bill">All Bills</option>
                          </select>
                        </div> */}
                    {/* select box */}
                    <div className="bill_dwnld">
                      <div
                        className="bill_icon"
                        onClick={() => {
                          dispatch(PrintPurchaseOrder([id]));
                        }}
                      >
                        <span>
                          <img
                            src={require("../../../assets/images/bill_print.png")}
                          />
                        </span>
                      </div>
                      <div className="bill_icon">
                        <span>
                          <img
                            src={require("../../../assets/images/bill_download.png")}
                          />
                        </span>
                      </div>
                      {!purchaseOrderDetail?.isBilled &&
                        purchaseOrderDetail?.isReceived != "received" && (
                          <div
                            className="bill_icon"
                            onClick={() => {
                              navigate(`/purchase/purchase-order/${idOfItem}`);
                            }}
                          >
                            <span>
                              <img
                                src={require("../../../assets/images/bill_edit.png")}
                              />
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="bill_screen">
                    <MiniList pr={dataToSendMiniList} />

                    {loading ? (
                      <>
                        <h1
                          style={{
                            textAlign: "center",
                            width: "70%",
                            marginTop: "10%",
                          }}
                        >
                          Please wait data is fetching...
                        </h1>
                      </>
                    ) : (
                      <div className="bill_details bill_dtls_right">
                        <div className="seller_or_buyer">
                          <div className="sell_div">
                            <div className="tp_hdr">
                              <div className="s-text">
                                <h2>Supplier details</h2>
                              </div>
                            </div>
                            <div className="sllr_cntnt">
                              <h3>{purchaseOrderDetail?.supplierId?.name}</h3>
                              {/* <p>ABC Seller</p> */}
                              <p>
                                {purchaseOrderDetail?.supplierId?.phoneNumber}
                              </p>
                              <p>
                                {
                                  purchaseOrderDetail?.supplierId?.address?.[0]
                                    ?.street
                                }
                                ,
                                {
                                  purchaseOrderDetail?.supplierId?.address?.[0]
                                    ?.city
                                }
                              </p>
                              <p>
                                {
                                  purchaseOrderDetail?.supplierId?.address?.[0]
                                    ?.country
                                }
                              </p>
                              <a href="javascript:void(0);">
                                {purchaseOrderDetail?.supplierId?.email}
                              </a>
                            </div>
                          </div>
                          <div className="sell_div byuyerr">
                            <div className="tp_hdr">
                              {/* <div className="sllr_img">
                      <img src={require("../../../assets/images/user_img.png")} alt="user-image" />
                    </div> */}
                              <div className="s-text">
                                <h2>Buyer details</h2>
                              </div>
                            </div>
                            <div className="sllr_cntnt">
                              <h3>
                                {
                                  purchaseOrderDetail?.warehouseId
                                    ?.warehouseName
                                }
                              </h3>
                              <p> {purchaseOrderDetail?.warehouseId?.phone}</p>
                              <p>
                                {
                                  purchaseOrderDetail?.warehouseId?.address
                                    ?.street
                                }
                                ,
                                {
                                  purchaseOrderDetail?.warehouseId?.address
                                    ?.city
                                }
                              </p>
                              {/* <p>Street, City</p> */}
                              <p>
                                {
                                  purchaseOrderDetail?.warehouseId?.address
                                    ?.country
                                }
                              </p>
                              <a href="javascript:void(0);">
                                {purchaseOrderDetail?.warehouseId?.email}
                              </a>
                            </div>
                          </div>
                          <div
                            className="seller_cross"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            {/* <i className="fa-solid fa-xmark" /> */}
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="sllr_dtl-tbl">
                          <div className="byr_div">
                            <div className="slb_div">
                              <h4>PO Number:</h4>
                              <h4>
                                <span>
                                  {purchaseOrderDetail?.purchaseOrderNumber}
                                </span>
                              </h4>
                            </div>
                            <div className="slb_div">
                              <h4>PO Date:</h4>
                              <h4>
                                <span>
                                  {" "}
                                  {/* {purchaseOrderDetail?.dateOfPurchaseOrder
                                      ?.slice(0, 10)
                                      .split("-")
                                      .reverse()
                                      .join("/")} */}
                                  {purchaseOrderDetail?.dateOfPurchaseOrder?.slice(
                                    0,
                                    10
                                  )}
                                </span>
                              </h4>
                            </div>
                            <div className="slb_div">
                              <h4>due Date::</h4>
                              <h4>
                                <span>
                                  {purchaseOrderDetail?.expectedDeliveryDate?.slice(
                                    0,
                                    10
                                  )}
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div className="byr_div">
                            <div className="slb_div">
                              <h4>Bill Number:</h4>
                              <h4>
                                <span>{purchaseOrderDetail?.billNumber}</span>
                              </h4>
                            </div>
                            {/* <div className="slb_div">
                                <h4>PR Number:</h4>
                                <h4>
                                  <span>SAY-2024-1</span>
                                </h4>
                              </div> */}
                            <div className="slb_div">
                              <h4>payment Term:</h4>
                              <h4>
                                <span className="status">
                                  {purchaseOrderDetail?.paymentTerm}
                                </span>
                              </h4>
                            </div>
                            {/* <div className="slb_div">
                                <h4>tax Type::</h4>
                                <h4>
                                  <span>TCS</span>
                                </h4>
                              </div> */}
                          </div>
                        </div>
                        <div className="sllr_dtl-tbl btm">
                          <div className="btmm_hdr">
                            <h4>ITEMS</h4>
                            <h4>DESCRIPTION</h4>
                            <h4>QTY</h4>
                            <h4>RATE</h4>
                            <h4>Amount</h4>
                          </div>
                          {purchaseOrderDetail?.products?.length > 0 &&
                            purchaseOrderDetail?.products?.map((product) => {
                              return (
                                <div className="btm_cntt">
                                  <div className="div-clmn name-box">
                                    <h5>
                                      {product?.productId?.productInfo?.name}
                                    </h5>
                                  </div>
                                  <div className="div-clmn">
                                    {/* <h5>Description goes here</h5> */}
                                    <h5>
                                      Color:
                                      <span className="totaaal">
                                        {/* <span className="back_color" /> */}
                                        {product?.variantId?.variant?.Color}
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="div-clmn">
                                    {/* Extra gap before this column */}
                                    <h5>{product.quantity}</h5>
                                  </div>
                                  <div className="div-clmn">
                                    <h5>{`${purchaseOrderDetail?.currency}  ${product?.unitCost} `}</h5>
                                  </div>
                                  <div className="div-clmn">
                                    <h5>{`${purchaseOrderDetail?.currency}  ${product?.totalCost} `}</h5>
                                  </div>
                                </div>
                              );
                            })}

                          <div className="footer-bottm">
                            <div className="div-clmn">
                              <h5>Total</h5>
                            </div>
                            <div className="div-clmn">
                              <h5 className="amount">
                                {`${purchaseOrderDetail?.currency}  ${purchaseOrderDetail?.totalAmount} `}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <CommonFileViewPage
                          fileArray={purchaseOrderDetail?.files}
                        />
                        {purchaseOrderDetail?.isReceived !== "received" &&
                          purchaseOrderDetail?.status !== "draft" && (
                            <div
                              className="record_pmnt"
                              onClick={() => {
                                navigate(`/reciveBill/${id}`);
                              }}
                            >
                              <a href="javascript:void(0);" className="edit">
                                Receive
                              </a>
                            </div>
                          )}
                        {!purchaseOrderDetail?.isBilled &&
                          purchaseOrderDetail?.status !== "draft" && (
                            <div
                              className="record_pmnt"
                              onClick={() => {
                                navigate(`/purchase/bill/${id}`);
                              }}
                            >
                              <a href="javascript:void(0);" className="edit">
                                Convet to Bill
                              </a>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* {!loading ? (
        <>
     

        
        </>
      ) : (
        <>
          <LoaderImage />
        </>
      )} */}
    </>
  );
};

export default PurchaseOrderDetail;
