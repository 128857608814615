import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allSuppliers } from "../../redux/features/sellers/sellerPurchaseSlice";
import CreateNewSupplier from "./openModalForm/CreateNewSupplier";

const SearchingSupplier = ({ setSupplierId, supname }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allSuppliers());
  }, []);
  const { suppliers } = useSelector((state) => state.sellerPurchase);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [supplierName, setsupplierName] = useState("");
  const [openNewSupplierForm, setOpenNewSupplierForm] = useState(false);

  const activeSupplier = suppliers?.filter((v) => v?.status?.isActive);
  const filterSupplierbySearch = activeSupplier?.filter((v) => {
    return v?.name.toLowerCase().includes(supplierName?.toLocaleLowerCase());
  });

  useEffect(() => {
    if (supname) {
      setsupplierName(supname);
    }
  }, [supname]);

  const funtionToSend = {
    openNewSupplierForm,
    setOpenNewSupplierForm,
    supplierName,
    setsupplierName,
    setSuppId: (a) => {
      setSupplierId(a);
    },
    fromm: "createNewBill",
  };
  return (
    <>
      <div className="div_row new_dev_row">
        <label>
          Supplier Name
          <span>*</span>
        </label>
        {/* <div className="inp_srch"> */}
        <input
          type="text"
          name="supplierName"
          // placeholder="Please Select a supplier name"
          className="form-control div_row_nw_inpt"
          value={supplierName}
          onClick={() => {
            setOpenSupplier((pre) => !pre);
          }}
          onBlur={() => {
            setTimeout(() => {
              setOpenSupplier(false);
            }, 150);
          }}
          onChange={(e) => {
            setsupplierName(e.target.value);
            if (e.target.value == "") {
              setSupplierId("");
            }
            setOpenSupplier(true);
          }}
        />
        {openSupplier && (
          <div className="div_row_drops">
            <div className="div_row_drops_inr">
              <ul style={{ position: "static" }}>
                <li
                  className="eachLIOfSearchingDropdown"
                  onClick={() => {
                    setOpenNewSupplierForm(true);
                  }}
                >
                  {supplierName != ""
                    ? `+ Add ${supplierName}`
                    : "+ Add new supplier"}
                  {/* <button
                    type="button"
                    onClick={() => {
                      setOpenNewSupplierForm(true);
                    }}
                    className="edit new_dev_row_edit"
                  >
                    {supplierName != ""
                      ? `+ Add ${supplierName}`
                      : "+ Add new supplier"}
                  </button> */}
                </li>
                {filterSupplierbySearch?.map((v) => (
                  <li
                    className="eachLIOfSearchingDropdown"
                    onClick={(e) => {
                      setSupplierId(v?._id);
                      setsupplierName(v?.name);
                    }}
                    key={v?._id}
                  >
                    {v?.name}
                  </li>
                ))}
                {filterSupplierbySearch?.length < 1 && (
                  <li className="eachLIOfSearchingDropdown">No result found</li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
      <CreateNewSupplier pr={funtionToSend} />
    </>
  );
};

export default SearchingSupplier;
