

import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showBillNumer } from '../../../constants/Api/Api';

export const GenereteUniqueBillNumber = createAsyncThunk(
  'GenereteUniqueBillNumber', // A unique action type string
  async (payload, thunkAPI) => {
    
    try {
        const res=await axios.get(`${showBillNumer}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })

        return res?.data
    } catch (error) {
        console.warn(error);
        
    }
  }
);



export const getUniqeBillNumber = createSlice({
  name: 'getUniqeBillNumber',
  initialState: {
    billNumber: null,
    loading: false
  },
  reducers: {
    // Optional: Add synchronous reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(GenereteUniqueBillNumber.pending, (state) => {
        state.loading = true;
      })
      .addCase(GenereteUniqueBillNumber.fulfilled, (state, action) => {
        state.loading = false;
        state.billNumber = action?.payload?.data;
      })
      .addCase(GenereteUniqueBillNumber.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

