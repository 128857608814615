import { Drawer } from "@mui/material";
import React, { useEffect } from "react";
import { validationSchemaa } from "../../sellers/purchase/AddSupplierValidation";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addSupplierInfo } from "../../../redux/features/sellers/sellerPurchaseSlice";
import AddSupplier from "../../sellers/purchase/AddSupplier";
import CreateNewSupplierForm from "../../sellers/supplier/CreateNewSupplierForm";

const CreateNewSupplier = ({ pr }) => {
  // console.log(pr, "abc");
  const {
    openNewSupplierForm,
    setOpenNewSupplierForm,
    supplierName,
    setsupplierName,
    setSuppId,
  } = pr;
  const dispatch = useDispatch();
  const { loading } = useSelector((a) => a.sellerPurchase);

  return (
    <>
      <Drawer
        open={openNewSupplierForm}
        onClose={() => {
          setOpenNewSupplierForm(false);
        }}
        anchor="right"
        sx={{
          "& .MuiDrawer-paper": {
            width: "30%",
            height: "60%",
            marginTop: "5%",
            borderRadius: "3%",
            borderTopLeftRadius: "3%",
            borderTopRightRadius: "3%",
          },
        }}
      >
        {/* <AddSupplier prr={pr} /> */}
        <CreateNewSupplierForm prr={pr} />
      </Drawer>
    </>
  );
};

export default CreateNewSupplier;
