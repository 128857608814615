import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Sidebar from "../../common/Sidebar";

import NavBar from "../../common/Nav/NavBar";
import {
  addBill,
  allSuppliers,
  POdetail,
} from "../../../redux/features/sellers/sellerPurchaseSlice";
import { allWarehouse } from "../../../redux/features/sellers/sellerWarehouseSlice";
import axios from "axios";
import { BASE_URL, showBillNumer } from "../../../constants/Api/Api";
import {
  Autocomplete,
  Icon,
  Menu,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faPercent,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { generateBill } from "../../../redux/features/sellers/GenerateBill";
import CommonMultipleFile from "../../common/CommonMultipleFile";
import DeleteIcon from "@mui/icons-material/Delete";

export const paymentTypeOption = [
  { label: "COD", value: "COD" },
  { label: "Cash", value: "cash" },
  { label: "Credit", value: "credit" },
  { label: "Debit", value: "debit" },
  { label: "Check", value: "check" },
];

export const formatToDate = (inputDate) => {
  const date = new Date(inputDate); // Parse the input date
  if (isNaN(date.getTime())) {
    // throw new Error("Invalid date format"); // Handle invalid date input
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
const ConvertToBill = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const formatToDate = (inputDate) => {
  //   const date = new Date(inputDate); // Parse the input date
  //   if (isNaN(date.getTime())) {
  //     // throw new Error("Invalid date format"); // Handle invalid date input
  //   }

  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");

  //   return `${year}-${month}-${day}`;
  // };
  const today = new Date();
  const [orderDetail, setOrderDetail] = useState({
    supplierId: "",
    purchaseOrderNumber: "",
    billNumber: "",
    paymentTerm: "",
    // billDate: new Date().toISOString().split("T")[0],
    billDate: formatToDate(new Date()),
    dueDate: "",
    products: [],
    // totalAmount: 0,
    picURL: "",
    discountRate: 0,
    taxType: "",
    taxRate: 0,
    notes: "",
    paymentType: "COD",
  });

  const [fetchData, setFetchData] = useState();
  const [fileArray, setFileArray] = useState([]);
  const [removedFile, setRemovedFile] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [picURL, setPicURL] = useState([]);

  const removeFile = (val, ind) => {
    setRemovedFile((pre) => {
      return [...pre, val?._id];
    });

    setFileArray((pre) => {
      let res = [...pre];
      let res2 = res.filter((v) => v !== val);
      // console.log(res[0] == val, "ccc");
      return [...res2];
    });
  };

  const propsToSendinCommonFile = {
    removeFile,
    setFileArray,
    setPicURL,
    fileArray,
  };
  useEffect(() => {
    dispatch(POdetail(id)).then((r) => {
      console.log(r, "aaa");
      setFetchData(JSON.parse(JSON.stringify(r?.payload)));
      setOrderDetail((prev) => {
        let updatedOrderDetail = { ...prev };
        updatedOrderDetail.products = r?.payload?.products.reduce(
          (acc, eachobj) => {
            return [
              ...acc,
              {
                productId: eachobj?.productId._id,
                variantId: eachobj?.variantId._id,
                quantity: eachobj.quantity,
                unitCost: eachobj.unitCost,
                totalCost: eachobj?.totalCost,
              },
            ];
          },
          []
        );

        if (r?.payload.paymentTerm == "COD") {
          updatedOrderDetail.dueDate = formatToDate(today);
        } else {
          updatedOrderDetail.dueDate = formatToDate(
            today.setDate(
              new Date().getDate() + Number(r?.payload?.paymentTerm.slice(-2))
            )
          );
        }
        updatedOrderDetail.supplierId = r?.payload.supplierId._id;
        updatedOrderDetail.purchaseOrderNumber =
          r?.payload?.purchaseOrderNumber;
        updatedOrderDetail.paymentTerm = r?.payload?.paymentTerm;

        // switch (r?.payload.paymentTerm) {
        //   case "Net 60":
        //     updatedOrderDetail.dueDate = formatToDate(
        //       today.setDate(new Date().getDate() + 60)
        //     );
        //     break;
        //   case "Net 45":
        //     updatedOrderDetail.dueDate = formatToDate(
        //       today.setDate(new Date().getDate() + 45)
        //     );
        //     break;
        //   case "Net 30":
        //     updatedOrderDetail.dueDate = formatToDate(
        //       today.setDate(new Date().getDate() + 30)
        //     );
        //     break;

        //   default:
        //     updatedOrderDetail.dueDate = formatToDate(today);
        //     break;
        // }
        return JSON.parse(JSON.stringify(updatedOrderDetail));
      });
    });
  }, []);

  useEffect(() => {
    axios
      // .get("https://api.perfectcreate.com/api/bill/showBillNumber", {
      .get(`${showBillNumer}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        // console.log(res, "aaa");
        setOrderDetail((prev) => {
          let result = JSON.parse(JSON.stringify(prev));
          result.billNumber = res?.data?.data;
          return result;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(orderDetail, "abc");

  console.log(orderDetail, "222");
  const ammontWithoutDiscount = fetchData?.products.reduce((acc, val) => {
    return (acc += val?.totalCost);
  }, 0);
  // const ammontWithoutDiscount = 100;
  const discountAmmount =
    ammontWithoutDiscount * (orderDetail?.discountRate / 100);
  const ammountAfterDiscount = ammontWithoutDiscount - discountAmmount;
  const taxAmmount = ammountAfterDiscount * (orderDetail?.taxRate / 100);
  const finalAmmount = ammountAfterDiscount - taxAmmount;

  const saveOrderBill = (e) => {
    e.preventDefault();
    // console.log(orderDetail, "picc");
    const newObj = new FormData();

    for (const key in orderDetail) {
      if (key == "picURL") {
        continue;
      } else if (key == "products") {
        newObj.append(key, JSON.stringify(orderDetail[key]));
      } else {
        newObj.append(key, JSON.parse(JSON.stringify(orderDetail[key])));
      }
    }
    // newObj.append("picURL", orderDetail.picURL);
    newObj.append("removedFiles", JSON.stringify(removedFile));
    if (Array.isArray(fileArray)) {
      fileArray.forEach((file, index) => {
        if (file instanceof File) {
          newObj.append("uploadFiles", file);
        }
      });
    }
    newObj.append("totalAmount", finalAmmount);
    // console.log(orderDetail, "aaa");
    // console.log(newObj, "aaa");
    // console.log(newObj.entries(), "aaa");

    // for (let [key, value] of newObj.entries()) {
    //   console.log(key, value, "222");
    // }

    dispatch(generateBill({ bodydata: newObj, withPO: "yes", navigate }));

    // dispatch(addBill({ newObj, navigate })).then((res) => {
    //   console.log(res, "aaa");
    //   // navigate("/purchase/purchase-order-list");
    // });
  };

  const openFileList = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFileList}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fileArray.length > 0 &&
          fileArray?.map((v, i) => (
            <MenuItem
              key={v?._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "30rem",
                fontSize: "1.5rem",
              }}
            >
              <span key={v?._id}>{v?.name}</span>
              <button
                type="button"
                onClick={() => {
                  removeFile(v, i);
                }}
              >
                {/* <FontAwesomeIcon icon={faXmark} /> */}
                <DeleteIcon />
              </button>
            </MenuItem>
          ))}
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
      </Menu>
      {/* <main>
        <section className="total_parent_element">
          <div className="left_parent_element">
            <div className="total_upper_left">
              <div className="logo_area">
                <img
                  style={{ width: "82%", height: "50px", margin: "auto" }}
                  src={require("../../../assets/images/bank.png")}
                />
              </div>
              <div className="nav_area">
                <div className="outr_dashboard_nav">
                  <h4>Dashboards</h4>
                  <div className="parent_nav_menu">
                    <Sidebar />
                  </div>
                </div>
              </div>
 
            </div>
          </div>
          <div className="right_parent_element">
            <NavBar /> */}

      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page">
                <div className="top_drv">
                  <div className="top_billchk_top">
                    <div className="billchk_brdcrm" aria-label="breadcrumb">
                      <ol className="breadcrumb bliiN">
                        <li className="breadcrumb-item">
                          <a href="javascript:void(0);">POS</a>
                        </li>
                        <li className="breadcrumb-item active">
                          <a href="javascript:void(0);">Bills</a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="bills_cnt_otr">
                  <div className="bll_hdr">
                    <h2>Convert To Bill</h2>
                    <span>
                      <FontAwesomeIcon icon={faClose} size="2xl" />
                    </span>
                  </div>
                  <div className="bll_innr_c">
                    <div className="bll_l">
                      <div className="bill_l_cnt">
                        <div className="bill_l_top_bx">
                          <div className="div_row">
                            <label>Supplier Name</label>
                            <div className="inp_srch">
                              <input
                                type="text"
                                disabled
                                name="supplierName"
                                value={fetchData?.supplierId?.name}
                                // className="form-control"
                              />
                            </div>
                            {/* <div className="inp_srch">
                                    <select>
                                      <option>Vendor Name</option>
                                      <option>Vendor Name1</option>
                                      <option>Vendor Name2</option>
                                      <option>Vendor Name3</option>
                                    </select>
                                    <button className="srch_btn">Search</button>
                                  </div> */}
                          </div>
                          {/* <div className="div_row">
                                  <label>registration No</label>
                                  <div className="inp_srch">
                                    <input
                                      type="text"
                                      // placeholder={"0214579210"}
                                    />
                                  </div>
                                </div> */}
                          <div className="clum_div">
                            <div className="div_row">
                              <label>Phone No</label>
                              <div className="inp_srch">
                                <input
                                  disabled
                                  type="text"
                                  value={`${fetchData?.supplierId?.countryCode} ${fetchData?.supplierId?.phoneNumber}`}
                                  // placeholder={"0214579210"}
                                />
                              </div>
                            </div>
                            <div className="div_row">
                              <label>Email</label>
                              <div className="inp_srch">
                                <input
                                  // type="email"
                                  // placeholder="email@gmail.com"
                                  className="eml"
                                  disabled
                                  type="text"
                                  value={fetchData?.supplierId?.email}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="div_row">
                            <label>address</label>
                            <div className="inp_srch">
                              <input
                                type="text"
                                disabled
                                value={`${fetchData?.supplierId?.address[0].street} ${fetchData?.supplierId?.address[0].city} ${fetchData?.supplierId?.address[0].state} ${fetchData?.supplierId?.address[0].zipCode} ${fetchData?.supplierId?.address[0].country}`}
                                // placeholder="34 Crosby Drive, Suite 202, Bedford, MA  01730"
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="div_row">
                                <label>address</label>
                                <div className="inp_srch">
                                  <input
                                    type="text"
                                    placeholder="34 Crosby Drive, Suite 202, Bedford, MA  01730"
                                  />
                                </div>
                              </div> */}
                      </div>
                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Bill No<span>*</span>
                          </label>
                          <div className="inp_srch">
                            <input
                              type="text"
                              disabled
                              value={orderDetail?.billNumber}
                            />
                          </div>
                        </div>
                        <div className="div_row">
                          <label>Order No</label>
                          <div className="inp_srch">
                            <input
                              type="text"
                              disabled
                              value={orderDetail?.purchaseOrderNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Bill Date<span>*</span>
                          </label>
                          <div className="inp_srch">
                            <input
                              type="date"
                              name="billDate"
                              value={orderDetail?.billDate}
                              className="form-control"
                              onChange={(e) => {
                                setOrderDetail((prev) => {
                                  let result = JSON.parse(JSON.stringify(prev));
                                  result.billDate = formatToDate(
                                    e.target.value
                                  );

                                  if (result.paymentTerm == "COD") {
                                    result.dueDate = formatToDate(
                                      e.target.value
                                    );
                                  } else {
                                    const newBillDate = new Date(
                                      e.target.value
                                    );
                                    result.dueDate = formatToDate(
                                      newBillDate.setDate(
                                        newBillDate.getDate() +
                                          Number(result.paymentTerm.slice(-2))
                                      )
                                    );
                                  }

                                  return result;
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="div_row">
                          <label>Due Date</label>
                          <div className="inp_srch">
                            <input
                              disabled
                              type="date"
                              name="dueDate"
                              value={orderDetail?.dueDate}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clum_div">
                        <div className="div_row">
                          <label>
                            Payment Terms
                            {/* <span>*</span> */}
                          </label>
                          <div className="inp_srch">
                            <input
                              type="text"
                              value={orderDetail?.paymentTerm}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="div_row">
                          <label>
                            Payment Type<span>*</span>
                          </label>
                          <Autocomplete
                            onBlur={(e) => {
                              if (
                                !orderDetail.paymentType ||
                                orderDetail.paymentType == ""
                              ) {
                                setOrderDetail((pre) => {
                                  let res = JSON.parse(JSON.stringify(pre));
                                  res.paymentType = "COD";
                                  return res;
                                });
                              }
                            }}
                            options={paymentTypeOption}
                            onChange={(e, v) => {
                              // if (!v) {
                              //   return;
                              // }
                              setOrderDetail((pre) => {
                                let res = JSON.parse(JSON.stringify(pre));
                                res.paymentType = v?.value;
                                console.log(res?.paymentType, "222");
                                return res;
                              });
                            }}
                            value={orderDetail.paymentType}
                            label="Select payment type"
                            // getOptionLabel={(op) => op.displayText}
                            getOptionSelected={(option, value) =>
                              option == value
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder="Select payment type"
                                {...params}
                              />
                            )}
                            PaperComponent={(props) => (
                              <Paper
                                {...props}
                                sx={{
                                  borderRadius: "16px",
                                  fontFamily: "source_sans_proregular",
                                  zIndex: 1,
                                }}
                              />
                            )}
                            ListboxProps={{
                              sx: {
                                fontSize: "1.5rem",
                              },
                            }}
                          />
                          {/* <div className="inp_srch">
                            <input
                              type="text"
                              value={orderDetail?.paymentTerm}
                              disabled
                            />
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="div_row">
                              <div className="d-flex">
                                <label>Subject</label>
                                <span className="spon">
                                  Within 250 Charecters
                                </span>
                              </div>
                              <div className="inp_srch">
                                <input type="text" />
                              </div>
                            </div> */}
                      <div className="div_row">
                        <div className="d-flex">
                          <label>Notes</label>
                          <span className="spon">
                            It will not be shown in PDF
                          </span>
                        </div>
                        <div className="inp_srch">
                          <input
                            type="text"
                            value={orderDetail?.notes}
                            onChange={(e) => {
                              setOrderDetail((prev) => {
                                let result = JSON.parse(JSON.stringify(prev));
                                result.notes = e.target.value;
                                return result;
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="frm_row">
                        <label>Attach File to Bill</label>
                        <div className="task_dD attach">
                          <div id="dropArea" className="drag-area">
                            <p>
                              <span>
                                img
                                <img
                                  src={require("../../../assets/images/bill_imgt.png")}
                                />
                              </span>
                              Upload
                            </p>
                          </div>

                          <span
                            id="icon"
                            onClick={() => {
                              document.getElementById("fileInput").click();
                            }}
                            className="brws_btn"
                          >
                            Browse
                          </span>
                          <div>
                            <input
                              multiple
                              type="file"
                              id="fileInput"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                setOrderDetail((prev) => {
                                  let result = JSON.parse(JSON.stringify(prev));
                                  result.picURL = e.target.files[0];
                                  return result;
                                });
                              }}
                            />
                            <div className="preview" id="preview" />
                          </div>
                        </div>
                      </div> */}
                      <CommonMultipleFile pr={propsToSendinCommonFile} />
                    </div>
                    <div className="bll_r">
                      <div className="bll_r_tp_hdr">
                        <div className="bllr_hdr">
                          <h3>Item Table</h3>
                          {/* <h3 className="h_color">
                                  <span>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </span>
                                  Bulk Action
                                </h3> */}
                        </div>
                        <div className="bll_tbl_otr">
                          <div className="bll_tbl_innr">
                            <table>
                              <thead>
                                <tr>
                                  <th>Item Details</th>
                                  {/* <th>Account</th> */}
                                  <th>Quantity</th>
                                  <th>Rate</th>
                                  {/* <th>Customer Details</th> */}
                                  <th>Amount</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {fetchData?.products?.length > 0 &&
                                  fetchData?.products.map((product, i) => {
                                    // console.log(product, "ccc");
                                    return (
                                      <tr>
                                        <td className="img_bll">
                                          <div>
                                            <img
                                              src={
                                                product?.thumbnailImage
                                                  ? product?.thumbnailImage
                                                  : require("../../../assets/images/bill_imgt.png")
                                              }
                                            />
                                          </div>
                                          <div className="cnvrt_text">
                                            <h5>
                                              {
                                                product.productId.productInfo
                                                  .name
                                              }
                                              <br />
                                              {`Price: ${product.variantId.variant.Price}`}{" "}
                                              {`Color: ${product.variantId.variant.Color}`}
                                            </h5>
                                          </div>
                                        </td>

                                        <td>
                                          <div>{product.quantity}</div>
                                        </td>
                                        <td>
                                          <div>{product.unitCost}</div>
                                        </td>

                                        <td>
                                          <div>{product.totalCost}</div>
                                        </td>
                                        <td className="actn_td">
                                          <div className="actn">
                                            <button
                                              type="button"
                                              className="actn_btn"
                                            >
                                              <FontAwesomeIcon icon={faSort} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                          <div className="bll_btm_innr">
                            {/* <button className="add_neew">
                                    Add New Row
                                    <span>
                                      <i className="fa-solid fa-plus" />
                                    </span>
                                  </button> */}
                            <div className="subTOTAL_otr">
                              <div className="subTToal">
                                <div className="sBTL_r">
                                  <div className="neSBta">
                                    <label>Sub Total</label>
                                  </div>
                                  <label>
                                    {ammontWithoutDiscount?.toFixed(2)}
                                  </label>
                                </div>
                                <div className="sBTL_r">
                                  <div className="neSBta">
                                    <label>Discount</label>
                                    <div className="percen">
                                      <input
                                        type="number"
                                        // placeholder={0}
                                        max={100}
                                        value={orderDetail?.discountRate}
                                        onChange={(e) => {
                                          setOrderDetail((prev) => {
                                            let result = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            if (
                                              e.target.value >= 0 &&
                                              e.target.value < 100
                                            ) {
                                              result.discountRate =
                                                e.target.value;
                                            }
                                            return result;
                                          });
                                        }}
                                      />
                                      <span>
                                        <FontAwesomeIcon icon={faPercent} />
                                      </span>
                                    </div>
                                  </div>
                                  <label>{discountAmmount.toFixed(2)} </label>
                                </div>
                                {/* <div className="sBTL_r">
                                  <div className="neSBta">
                                    <div className="rdo_bt">
                                      <input
                                        type="radio"
                                        id="TDS"
                                        name="sub_total"
                                        value="TDS"
                                        onChange={(e) => {
                                          setOrderDetail((prev) => {
                                            let rr = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            rr.taxType = e.target.value;
                                            return rr;
                                          });
                                        }}
                                      />
                                      <label htmlFor="TDS">TDS</label>
                                    </div>
                                    <div className="rdo_bt">
                                      <input
                                        type="radio"
                                        id="TCS"
                                        name="sub_total"
                                        value="TCS"
                                        onChange={(e) => {
                                          setOrderDetail((prev) => {
                                            let aa = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            aa.taxType = e.target.value;
                                            return aa;
                                          });
                                        }}
                                      />
                                      <label htmlFor="TCS">TCS</label>
                                    </div>
                                    {orderDetail?.taxType !== "" && (
                                      <div className="percen">
                                        <input
                                          type="number"
                                          onChange={(e) => {
                                            setOrderDetail((prev) => {
                                              let aa = JSON.parse(
                                                JSON.stringify(prev)
                                              );
                                              aa.taxRate = e.target.value;
                                              return aa;
                                            });
                                          }}
                                        />
                                        <span>
                                          <FontAwesomeIcon icon={faPercent} />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <label>{taxAmmount.toFixed(2)}</label>
                                </div> */}
                              </div>
                              <div className="sBTL_r btm">
                                <div className="neSBta">
                                  <label>Sub Total</label>
                                </div>
                                <label>{finalAmmount.toFixed(2)}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cancel__save">
                          <button
                            type="button"
                            className="srch_btn can"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={saveOrderBill}
                            className="srch_btn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* </div>
        </section>
      </main> */}
    </>
  );
};

export default ConvertToBill;
