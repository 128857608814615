import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BillPaymentApi } from "../../constants/Api/Api";
import { useLazyGetPaymentMadeQuery } from "../reduxNeeded/reduxBase";
// const [getAllpaymentList]=useLazyGetPaymentMadeQuery()




export const PyamentTheBill=createAsyncThunk('PyamentTheBill',async({data,goto},thunkAPI)=>{
    console.log(data,'abc')
    try {
        const res=await axios.post(BillPaymentApi,data,{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })
        // console.log(res?.data)
      if (res?.data) {
       swal('Done !',res?.data.message??"Succcess",'success')   
    //    await thunkAPI.dispatch(PaymentMadeList())
    await goto('/paymentsMade')
    //    await getAllpaymentList()     
      }
    } catch (error) {
        console.warn(error);
        swal('Opps !',error?.response?.data?.message??'something wrong','error')
    }
})