import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { editPurchaseOrderApi } from "../../../constants/Api/Api";
import { Bounce, toast } from "react-toastify";
import { allPurchaseOrder } from "./sellerPurchaseSlice";


export const editPurchaseOrder=createAsyncThunk('editPurchaseOrder',async({newObj,navigate,typee,idOfPO},thunkAPI)=>{
    try {
        const res=await axios.put(`${editPurchaseOrderApi}${idOfPO}?isDraft=${typee}`,newObj,{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })
     if (res?.data?.success) {
        swal("Done!", res.data.message, "success");
         await thunkAPI.dispatch(allPurchaseOrder());
          return res?.data
     }
    } catch (error) {
        console.warn(error,);
        swal("Opps!",error.response.data.message, "error");
        // toast.error(error.response.data.message, {
        //     className: "toast-message",
        //     position: 'top-center',
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: 'dark',
        //     transition: Bounce
        //   });
    }
})
