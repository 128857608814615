import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
export const formatText = (str) => {
  if (!str) return "";
  const formatted = str.replace(/([A-Z])/g, " $1").trim();
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

const FilterByStatus = ({
  setStatus: pr,
  newButtonName,
  statusOption,
  newButtonPath,
}) => {
  const [allbillsdropDown, setAllBillOpenDropdown] = useState(false);
  const [StatusText, setStatusText] = useState(statusOption[0]?.label);
  const navigate = useNavigate();

  return (
    <>
      <div className="main_add_po">
        <div className="ul-main-div">
          <div
            style={{ fontFamily: "source_sans_proregular" }}
            className="ul__li_box"
            tabIndex={0}
            onFocus={() => {
              setAllBillOpenDropdown((pre) => !pre);
            }}
            onBlur={() => {
              setTimeout(() => {
                setAllBillOpenDropdown(false);
              }, 150);
            }}
          >
            {StatusText}
            <span className="drop----dwn">
              <i className="fa-solid fa-caret-down"></i>
            </span>
          </div>
          {allbillsdropDown && (
            <>
              <ul className="ul-box">
                {statusOption?.map((eachOption) => (
                  <li
                    onClick={(e) => {
                      setStatusText(eachOption.label);
                      pr(eachOption.value);
                    }}
                    className={
                      eachOption.label == "All Bills" ||
                      (eachOption.label == "All PO" && "active")
                    }
                    value={eachOption.value}
                  >
                    {eachOption.label}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        {newButtonName && (
          <button
            className="add_po"
            onClick={() => {
              navigate(newButtonPath);
            }}
          >
            <span>+</span> New {newButtonName}
          </button>
        )}
      </div>
      {/* <select
        onChange={(e) => {
          // console.log(e.target.value, "test");
          pr((pre) => {
            let res = JSON.parse(JSON.stringify(pre));
            res.poStatus = e.target.value;

            return res;
          });
        }}
      >
        <option value={""}>Select PO Status</option>
        <option value={"billed"}>Billed</option>
        <option value={"notBilled"}>Not Billed</option>
        <option value="received">Received</option>
        <option value="notReceived">Not Received</option>
        <option value={"partiallyReceived"}>Partially Received</option>
      </select> */}
    </>
  );
};

export default FilterByStatus;
