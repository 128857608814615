import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import parsePhoneNumberFromString from "libphonenumber-js";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import * as yup from "yup";

const CreateNewSupplierForm = () => {
  // const [fullNumber, setFullNumber] = useState("");
  const yupValidationOfAddSuppForm = yup.object().shape({
    firstName: yup
      .string()
      .required("First Name is required")
      .min(3, "First Name must be at least 3 characters"),
    lastName: yup
      .string()
      .required("Last Name is required")
      .min(3, "Last Name must be at least 3 characters"),
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "email must be a valid email"
      )
      .required("Email is required"),
    // countryCode: yup.string().required("Country Code is required"),
    phoneNumber: yup
      .string()
      .required("Phone Number is required")
      .matches(
        /^[0-9]{10}$/,
        "Phone number must be exactly 10 digits and contain only numbers"
      ),
    // type: yup.string().required("Address type is required"),
    // street: yup.string().required("Street is required"),
    // city: yup.string().required("City is required"),
    // state: yup.string().required("State is required"),
    // country: yup.string().required("Country is required"),
    zipCode: yup
      .string()
      .matches(/^[0-9]{6}$/, "ZIP code must be exactly 6 digits"),
  });

  const AddSuppForm = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phoneNumber: "",
      type: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    },
    mode: "all",
    resolver: yupResolver(yupValidationOfAddSuppForm),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState,
    reset,
  } = AddSuppForm;

  const {
    errors,
    touchedFields,
    dirtyFields,
    isDirty,
    isValid,
    isSubmitting,
    isSubmitted,
    isSubmitSuccessful,
    submitCount,
  } = formState;

  const submitForm = (data) => {
    console.log(data);
  };

  const errorForm = (data) => {
    console.log(data);
  };

  // useEffect(() => {
  //   const phoneNumberObj = parsePhoneNumberFromString(fullNumber ?? "");
  //   setValue("phoneNumber", phoneNumberObj?.nationalNumber);
  //   setValue("countryCode", phoneNumberObj?.countryCallingCode);
  // }, [fullNumber]);
  console.log(getValues(), "getValues");
  return (
    <>
      <div className="outr-right-content splr_frm_cntnt nw__sply">
        <div className="splr_frm_ttl new_suplr">
          <div className="splr_frm_main">
            <form onSubmit={handleSubmit(submitForm, errorForm)} noValidate>
              <div className="splr_frm_flx splr_hdng">
                <div className="bll_hdr">
                  <h2>Add Supplier</h2>
                </div>
                <div className="hdng_cross">
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
              <div className="splr_frm_flx">
                <div className="form-group">
                  <label>Supplier First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("firstName")}
                  />
                  <div className="formErrorMessege">
                    {errors.firstName && (
                      <span>{errors.firstName.message}</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>Supplier Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("lastName")}
                  />
                  <div className="formErrorMessege">
                    {errors.lastName && <span>{errors.lastName.message}</span>}
                  </div>
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <PhoneInput
                    defaultCountry="IN"
                    className="countryCode"
                    onChange={(val) => {
                      const phoneNumberObj = parsePhoneNumberFromString(
                        val ?? ""
                      );
                      setValue("phoneNumber", phoneNumberObj?.nationalNumber, {
                        shouldDirty: true,
                        // shouldTouch: true,
                        shouldValidate: true,
                      });
                      setValue(
                        "countryCode",
                        phoneNumberObj?.countryCallingCode,
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        }
                      );
                    }}
                  />

                  {/* {isSubmitted && getValues("phoneNumber")?.length != 10 && (
                    <div className="formErrorMessege">
                      Phone number must be exactly 10 digits .
                    </div>
                  )} */}

                  {/* <input
                    type="number"
                    className="form-control"
                    {...register("phoneNumber")}
                  /> */}

                  <div className="formErrorMessege">
                    {isSubmitted && errors.phoneNumber && (
                      <span>{errors.phoneNumber.message}</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("email")}
                  />
                  <div className="formErrorMessege">
                    {errors.email && <span>{errors.email.message}</span>}
                  </div>
                </div>
                <div className="form-group" style={{ width: "100%" }}>
                  <label>Address Type</label>
                  <input
                    type="text"
                    name="typee"
                    className="form-control"
                    defaultValue=""
                    onchange="{handleChange}"
                    onblur="{handleBlur}"
                  />
                </div>
                <div className="form-group" style={{ width: "32%" }}>
                  <label>Street</label>
                  <input
                    type="text"
                    name="street"
                    className="form-control"
                    defaultValue=""
                    onchange="{handleChange}"
                    onblur="{handleBlur}"
                  />
                </div>
                <div className="form-group" style={{ width: "32%" }}>
                  <label>City</label>
                  <select name="city" id="city" className="form-control">
                    <option value="volvo" disabled="">
                      city
                    </option>
                    <option value="volvo">Kolkata</option>
                    <option value="saab">Howrah</option>
                  </select>
                </div>
                <div className="form-group" style={{ width: "32%" }}>
                  <label>State</label>
                  <select name="state" id="state" className="form-control">
                    <option value="volvo" disabled="">
                      State
                    </option>
                    <option value="volvo">WB</option>
                    <option value="saab">Mumbai</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>ZipCode</label>
                  <input
                    type="text"
                    name="zipCode"
                    className="form-control"
                    defaultValue=""
                    onchange="{handleChange}"
                    onblur="{handleBlur}"
                  />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    name="country"
                    className="form-control"
                    defaultValue=""
                    onchange="{handleChange}"
                    onblur="{handleBlur}"
                  />
                </div>
              </div>
              <div className="suplr_submit">
                <div className="form-group">
                  <div className="sppl_tgls">
                    <div className="sppl_mn_tgl">
                      <span>Inactive</span>
                      <label className="switch">
                        <input
                          name="isActive"
                          type="checkbox"
                          defaultChecked="{values?.isActive}"
                          onchange="{handleChange}"
                          onblur="{handleBlur}"
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                    <div className="sppl_mn_tgl">
                      <span>Active</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex ">
                  <button
                    type="button"
                    href="javascript:void(0);"
                    className="btn_add_supplier "
                    onClick={() => {
                      reset();
                    }}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    href="javascript:void(0);"
                    className="btn_add_supplier "
                  >
                    Add Supplier
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <DevTool control={control} />
    </>
  );
};

export default CreateNewSupplierForm;
