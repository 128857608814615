import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteOnePurchaseReceive,
  PurchaseReciveSlice,
} from "../../../redux/features/sellers/PurchaseReciveSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEllipsisV,
  faEye,
  faPen,
  faSort,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Drawer,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  allPurchaseOrder,
  allSuppliers,
} from "../../../redux/features/sellers/sellerPurchaseSlice";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import LoaderImage from "../../common/LoaderImage";
import PurchaseOrderList from "./PurchaseOrderList";
import FilterByStatus from "../../common/FilterByStatus";
import SearchingDropdownForFilter from "../../common/SearchingDropdownForFilter";
import {
  downloadPRInExcel,
  filteringPurchaseReceive,
  filterPRByNameNo,
  printPR,
} from "../../../redux/features/sellers/FilterPReceive";
import { Checkbox } from "antd";
import CommonPaginateSection from "../../common/CommonPaginateSection";
import FilterByDate from "../../common/FilterByDate";
import { errorToast } from "../../common/ToastMessege";
import {
  useDownloadInExcelMutation,
  usePrintInPdfMutation,
} from "../../../redux/reduxNeeded/PrintAndDownload";
import {
  DownLoadPRInExcelApirtk,
  PrintPRInPdfApirtk,
} from "../../../constants/Api/rtkAPI";

export const PRStatusOption = [
  { label: "All Purchase Receive", value: "" },
  { label: "Billed", value: "billed" },
  { label: "Not Billed", value: "notBilled" },
];

const PurchaseReciveList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading } = useSelector((a) => a.PurchaseReciveList);
  const { dataAfetFiler, PRLoading, PRTypingLoading, PRAfterSearchByWord } =
    useSelector((a) => a.FilteredPurchaseReceiveList);
  const { suppliers } = useSelector((a) => a.sellerPurchase);
  const {
    loading: purchaseOrderloading,
    POList,
    currency,
  } = useSelector((state) => state.sellerPurchase);

  const [downloadPRinExcelfn, { isLoading: downloadPRLoading }] =
    useDownloadInExcelMutation();

  const [printInPdffn, { isLoading: printInPdfLoading }] =
    usePrintInPdfMutation();

  const [selectedBill, setSelectedBill] = useState([]);
  const [openDrower, setOpenDrower] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({
    purchaseReciveNumber: "",
    purchaseOrderNumver: "",
    vendorName: "",
    datee: "",
    billStatus: "",
  });
  const [toggleAllSelect, setToggleAllSelect] = useState(false);
  useEffect(() => {
    setSelectedBill((pre) => {
      let res = [];
      if (toggleAllSelect) {
        paginatedList?.forEach((element) => {
          res.push(element?._id);
        });
      } else {
        res = [];
      }
      return res;
    });
  }, [toggleAllSelect]);

  const [tabs, setTabs] = useState("PendingPO");
  const [filteringData, setFilteringData] = useState({
    PRStatus: "",
    word: "",
    startDate: "",
    endDate: "",
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [word, setWord] = useState("");

  useEffect(() => {
    dispatch(filteringPurchaseReceive(filteringData));
  }, [filteringData]);

  useEffect(() => {
    dispatch(filterPRByNameNo(word));
  }, [word]);

  // console.log(data?.data, "abc");
  useEffect(() => {
    dispatch(PurchaseReciveSlice());
    dispatch(allSuppliers());
    dispatch(allPurchaseOrder());
  }, []);
  const defaultProps = {
    options: suppliers,
    getOptionLabel: (option) => option.name,
  };

  const afterSearch = data?.data?.filter((v) => {
    if (searchCriteria?.billStatus === "") {
      return (
        v?.purchaseOrderNumber
          ?.toLowerCase()
          .includes(searchCriteria?.purchaseOrderNumver?.toLowerCase()) &&
        v?.purchaseReceiveNumber
          ?.toLowerCase()
          .includes(searchCriteria?.purchaseReciveNumber?.toLowerCase()) &&
        v?.supplierId?.name
          ?.toLowerCase()
          .includes(searchCriteria?.vendorName?.toLowerCase())
      );
    } else {
      // console.log(searchCriteria?.billStatus, "abc");
      return (
        v?.purchaseOrderNumber
          ?.toLowerCase()
          .includes(searchCriteria?.purchaseOrderNumver?.toLowerCase()) &&
        v?.purchaseReceiveNumber
          ?.toLowerCase()
          .includes(searchCriteria?.purchaseReciveNumber?.toLowerCase()) &&
        v?.isBilled === Boolean(Number(searchCriteria?.billStatus)) &&
        v?.supplierId?.name
          ?.toLowerCase()
          .includes(searchCriteria?.vendorName?.toLowerCase())
      );
    }
  });
  const pendiingPurchaseOrder = POList?.filter(
    (v) => v?.isReceived !== "received"
  );
  const pendingPoData = { pendiingPurchaseOrder, tabs, setTabFun: setTabs };

  const StatusOfFilteringData = (val) => {
    setFilteringData((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res.PRStatus = val;
      return res;
    });
  };
  const paginatedList = dataAfetFiler?.slice(
    (pageNumber - 1) * perPage,
    perPage * pageNumber
  );

  const totalPageNumber = Math.ceil(dataAfetFiler?.length / perPage);

  const dataToSendInPaginateSection = {
    setPageNumber,
    setPerPage,
    totalPageNumber,
  };

  const dataToSendForFilterByWord = {
    word,
    setWord,
    showDataOfSearch: PRAfterSearchByWord,
    typingLoading: PRTypingLoading,
    viewPage: "/ShowEachPurchaseReceive/",
  };

  return (
    <>
      {tabs == "AllPR" ? (
        <>
          {downloadPRLoading || printInPdfLoading ? (
            <LoaderImage />
          ) : (
            <main>
              <section className="total_parent_element bl_scrn_sec new_purchase">
                <div className="right_parent_element">
                  <div className="outr-right-content">
                    <div className="bIlls_page">
                      <div className="top_drv">
                        <div className="top_billchk_top">
                          <div
                            className="billchk_brdcrm"
                            aria-label="breadcrumb"
                          >
                            <ol className="breadcrumb bliiN">
                              <li className="breadcrumb-item">
                                <a href="javascript:void(0);">Bills</a>
                              </li>
                              <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">
                                  List of Goods Received
                                </a>
                              </li>
                            </ol>
                          </div>
                        </div>
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div
                            className="record_pmnt"
                            onClick={() => {
                              // navigate(`/purchaseRecives/pendingPO`);
                              setTabs("PendingPO");
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${
                                tabs == "PendingPO" ? "edit" : "edit unSelected"
                              }`}
                            >
                              Pending order
                            </a>
                          </div>
                          <div
                            className="record_pmnt"
                            onClick={() => {
                              // navigate(`/purchaseRecives`);
                              setTabs("AllPR");
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${
                                tabs == "AllPR" ? "edit" : "edit unSelected"
                              }`}
                            >
                              Goods Received
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blls_drive new_bills_drive">
                      <div className="blls_drive_lft nw_blls_drv_lft">
                        <FilterByStatus
                          setStatus={StatusOfFilteringData}
                          statusOption={PRStatusOption}
                        />

                        <SearchingDropdownForFilter
                          pr={dataToSendForFilterByWord}
                        />

                        {selectedBill?.length > 0 && (
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <>
                                <button
                                  className="drv_lst_img"
                                  variant="contained"
                                  {...bindTrigger(popupState)}
                                >
                                  <span className="drv_lst_pic">
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                  </span>
                                </button>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Typography
                                    onClick={() => {
                                      dispatch(
                                        DeleteOnePurchaseReceive(selectedBill)
                                      );
                                      popupState.close();
                                    }}
                                    sx={{ fontSize: "17px", p: 1 }}
                                  >
                                    Delete
                                  </Typography>
                                  <Typography
                                    onClick={() => {
                                      // dispatch(printPR(selectedBill));
                                      printInPdffn({
                                        path: PrintPRInPdfApirtk,
                                        id: selectedBill,
                                        key: "purchaseReceiveIds",
                                      });
                                      popupState.close();
                                    }}
                                    sx={{ fontSize: "17px", p: 1 }}
                                  >
                                    Print
                                  </Typography>
                                  <Typography
                                    onClick={() => {
                                      // dispatch(downloadPRInExcel(selectedBill));
                                      downloadPRinExcelfn({
                                        path: DownLoadPRInExcelApirtk,
                                        id: selectedBill,
                                        key: "purchaseReceiveIds",
                                      });
                                      popupState.close();
                                    }}
                                    sx={{ fontSize: "17px", p: 1 }}
                                  >
                                    To excel format
                                  </Typography>
                                </Popover>
                              </>
                            )}
                          </PopupState>
                        )}
                      </div>

                      <div className="blls_drive_rght listsss">
                        <div className="drive_rght_flx">
                          <div className="drv_rght_lft">
                            <NavLink
                              to={"/createNewPurchseReceive"}
                              className="nw_cstmr"
                            >
                              Create New
                              <span>+</span>
                            </NavLink>
                          </div>
                          <FilterByDate pr={setFilteringData} />
                          <div className="drv_lst_lst">
                            <button
                              className="drv_lst_img"
                              onClick={() => {
                                if (selectedBill.length > 0) {
                                  // dispatch(printPR(selectedBill));
                                  printInPdffn({
                                    path: PrintPRInPdfApirtk,
                                    id: selectedBill,
                                    key: "purchaseReceiveIds",
                                  });
                                } else {
                                  errorToast("Please select one item");
                                }
                              }}
                            >
                              <span className="drv_lst_pic">
                                <img
                                  src={require("../../../assets/images/prnt.png")}
                                  alt=""
                                />
                              </span>
                            </button>
                            <button
                              className="drv_lst_img"
                              onClick={() => {
                                if (selectedBill.length > 0) {
                                  // dispatch(downloadPRInExcel(selectedBill));
                                  downloadPRinExcelfn({
                                    path: DownLoadPRInExcelApirtk,
                                    id: selectedBill,
                                    key: "purchaseReceiveIds",
                                  });
                                } else {
                                  errorToast(" Please select one item ");
                                }
                              }}
                            >
                              <span className="drv_lst_pic">
                                <img
                                  src={require("../../../assets/images/bl_cp.png")}
                                  alt=""
                                />
                              </span>
                            </button>
                            <button className="drv_lst_img">
                              <span className="drv_lst_pic">
                                <img
                                  src={require("../../../assets/images/bl_stng.png")}
                                  alt=""
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="blls_drive_tbl_ottr">
                        <table className="blls_drive_tbl_main">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "7rem",
                                }}
                              >
                                {/* <p style={{ color: "#005e5a" }}>
                                {selectedBill?.length} item selected
                              </p> */}
                                <Checkbox
                                  checked={toggleAllSelect}
                                  onChange={() => {
                                    setToggleAllSelect((pre) => !pre);
                                  }}
                                />
                                Date.
                              </th>
                              <th>Purchase Receive.</th>
                              <th>Purchase Order.</th>
                              <th>Vendor Name</th>
                              {/* <th>Receive Status</th> */}
                              <th>Bill Status</th>
                              <th>Quantity</th>
                              <th>Action</th>
                              {/* <th
                              onClick={() => {
                                setOpenDrower(true);
                              }}
                            >
                              <img
                                src={require("../../../assets/images-pos/magnifying-glass.png")}
                              />
                            </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {!PRLoading && paginatedList?.length > 0 ? (
                              paginatedList?.map((eachReceive, eachind) => (
                                <tr
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    navigate(
                                      `/ShowEachPurchaseReceive/${eachReceive?._id}`
                                    );
                                  }}
                                >
                                  <td
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="bl_no_flx">
                                      <Checkbox
                                        checked={selectedBill.includes(
                                          eachReceive?._id
                                        )}
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          setSelectedBill((pre) => {
                                            const res = [...pre];
                                            const indexofId = res.indexOf(
                                              eachReceive?._id
                                            );
                                            if (indexofId === -1) {
                                              return [...res, eachReceive?._id];
                                            } else {
                                              res.splice(indexofId, 1);
                                              return res;
                                            }
                                          });
                                        }}
                                      />

                                      {/* <input
                                      type="checkbox"
                                      className="bl_no_inpt"
                                      checked={selectedBill.includes(
                                        eachReceive?._id
                                      )}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        setSelectedBill((pre) => {
                                          const res = [...pre];
                                          const indexofId = res.indexOf(
                                            eachReceive?._id
                                          );
                                          if (indexofId === -1) {
                                            return [...res, eachReceive?._id];
                                          } else {
                                            res.splice(indexofId, 1);
                                            return res;
                                          }
                                        });
                                      }}
                                    /> */}
                                      <span className="bl_no_spn">
                                        {eachReceive?.receivedDate?.slice(
                                          0,
                                          10
                                        )}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {eachReceive?.purchaseReceiveNumber}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {eachReceive?.purchaseOrderNumber}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {eachReceive?.supplierId?.name}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className="bl_no_spn grn"
                                      style={{
                                        color: eachReceive?.isBilled
                                          ? "green"
                                          : "red",
                                      }}
                                    >
                                      {eachReceive?.isBilled
                                        ? "BILLED"
                                        : "NOT BILLED"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bl_no_spn">
                                      {eachReceive?.quantity}
                                    </span>
                                  </td>
                                  <td
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="drv_tbl_icns dropdown">
                                      <button
                                        className="dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <FontAwesomeIcon icon={faSort} />
                                      </button>
                                      <ul className="dropdown-menu">
                                        <li>
                                          <button
                                            onClick={() => {
                                              dispatch(
                                                DeleteOnePurchaseReceive([
                                                  eachReceive?._id,
                                                ])
                                              );
                                            }}
                                            className="dpdn_btn_icns trash"
                                          >
                                            <FontAwesomeIcon icon={faTrash} />
                                            Delete
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <h1> No purchase receive found </h1>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {paginatedList && paginatedList.length > 0 && (
                        <CommonPaginateSection
                          pr={dataToSendInPaginateSection}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </main>
          )}
        </>
      ) : (
        <PurchaseOrderList pendingPoData={pendingPoData} />
      )}

      {/* {loading ? (
        <>
          <LoaderImage />
        </>
      ) : (
        <>
          <Accordion
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              marginTop: "100px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Purchase Receives
            </AccordionSummary>
            <AccordionDetails>
              {selectedBill?.length > 0 ? (
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <>
                      <button
                        className="drv_lst_img"
                        variant="contained"
                        {...bindTrigger(popupState)}
                      >
                        <span className="drv_lst_pic">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </span>
                      </button>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Typography
                          onClick={() => {
                            dispatch(
                              DeleteOnePurchaseReceive([...selectedBill])
                            );
                            popupState.close();
                          }}
                          sx={{ fontSize: "17px", p: 1 }}
                        >
                          Delete
                        </Typography>
                        <Typography
                          onClick={() => {
                            popupState.close();
                          }}
                          sx={{ fontSize: "17px", p: 1 }}
                        >
                          Option 2
                        </Typography>
                      </Popover>
                    </>
                  )}
                </PopupState>
              ) : (
                <div className="outr-right-content purchase_r">
                  <h1>All Purchase Receives</h1>
                </div>
              )}
              <div className="blls_drive_tbl_ottr">
                <table className="blls_drive_tbl_main">
                  <thead>
                    <tr>
                      <th>
                        Date.
                      </th>
                      <th>Purchase Receive.</th>
                      <th>Purchase Order.</th>
                      <th>Vendor Name</th>
                      <th>Bill Status</th>
                      <th>Quantity</th>
                      <th>Action</th>
                      <th
                        onClick={() => {
                          setOpenDrower(true);
                        }}
                      >
                        <img
                          src={require("../../../assets/images-pos/magnifying-glass.png")}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {afterSearch?.map((eachReceive, eachind) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          navigate(
                            `/ShowEachPurchaseReceive/${eachReceive?._id}`
                          );
                        }}
                      >
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="bl_no_flx">
                            <input
                              type="checkbox"
                              className="bl_no_inpt"
                              checked={selectedBill.includes(eachReceive?._id)}
                              onChange={(e) => {
                                e.stopPropagation();
                                setSelectedBill((pre) => {
                                  const res = [...pre];
                                  const indexofId = res.indexOf(
                                    eachReceive?._id
                                  );
                                  if (indexofId === -1) {
                                    return [...res, eachReceive?._id];
                                  } else {
                                    res.splice(indexofId, 1);
                                    return res;
                                  }
                                });
                              }}
                            />
                            <span className="bl_no_spn">
                              {eachReceive?.receivedDate?.slice(0, 10)}
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className="bl_no_spn">
                            {eachReceive?.purchaseReceiveNumber}
                          </span>
                        </td>
                        <td>
                          <span className="bl_no_spn">
                            {eachReceive?.purchaseOrderNumber}
                          </span>
                        </td>
                        <td>
                          <span className="bl_no_spn">
                            {eachReceive?.supplierId?.name}
                          </span>
                        </td>
                        <td>
                          <span
                            className="bl_no_spn grn"
                            style={{
                              color: eachReceive?.isBilled ? "green" : "red",
                            }}
                          >
                            {eachReceive?.isBilled ? "BILLED" : "NOT BILLED"}
                          </span>
                        </td>
                        <td>
                          <span className="bl_no_spn">
                            {eachReceive?.quantity}
                          </span>
                        </td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="drv_tbl_icns dropdown">
                            <button
                              className="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <FontAwesomeIcon icon={faSort} />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <button
                                  onClick={() => {
                                    dispatch(
                                      DeleteOnePurchaseReceive([
                                        eachReceive?._id,
                                      ])
                                    );
                                  }}
                                  className="dpdn_btn_icns trash"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                  Delete
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Pending purchase order list
            </AccordionSummary>
            <AccordionDetails>
            </AccordionDetails>
          </Accordion>
        </>
      )} */}

      {openDrower && (
        <Drawer
          anchor="top"
          open={openDrower}
          sx={{
            "& .MuiDrawer-paper": {
              width: "40%", // Adjust the width as needed
              margin: "0 auto",
              height: "450px",
            },
          }}
        >
          <h1 className="ms-5">
            <table>
              <tbody>
                <tr>
                  <td>Purchase Receive# : </td>
                  <td>
                    <input
                      name="purchaseReciveNumber"
                      type="search"
                      value={searchCriteria?.purchaseReciveNumber}
                      onChange={(e) => {
                        setSearchCriteria((pre) => {
                          let res = { ...pre };
                          return { ...res, [e.target.name]: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Purchase Order# </td>
                  <td>
                    <input
                      name="purchaseOrderNumver"
                      type="search"
                      value={searchCriteria?.purchaseOrderNumver}
                      onChange={(e) => {
                        setSearchCriteria((pre) => {
                          let res = { ...pre };
                          return { ...res, [e.target.name]: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Vendor :</td>
                  <td>
                    <Autocomplete
                      name="vendorName"
                      onChange={(event, newValue) => {
                        // console.log(newValue?.name, "abc");
                        setSearchCriteria((pre) => {
                          let res = { ...pre };
                          return {
                            ...res,
                            ["vendorName"]: newValue?.name ?? "",
                          };
                        });
                      }}
                      sx={{
                        "& .MuiInputBase-root": { fontSize: "28px" },
                        "& .MuiAutocomplete-option": { fontSize: "28px" },
                      }}
                      {...defaultProps}
                      id="disable-close-on-select"
                      // disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select vendor name"
                          variant="standard"
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Status :</td>
                  <td>
                    <select
                      name="billStatus"
                      onChange={(e) => {
                        setSearchCriteria((pre) => {
                          let res = { ...pre };
                          return {
                            ...res,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                    >
                      <option value="">Select Status</option>
                      <option value={1}>Billed</option>
                      <option value={0}>Not Billed</option>
                    </select>
                  </td>
                </tr>{" "}
                {/* <tr>
                  <td> Receive Date :</td>
                  <td>
                    <input />
                  </td>
                </tr> */}
                <tr>
                  <td colSpan="2" style={{ textAlign: "right" }}>
                    <button
                      className="btn btn-primary fs-1"
                      onClick={() => {
                        setOpenDrower(false);
                      }}
                    >
                      Search
                    </button>
                    <button
                      onClick={() => {
                        setOpenDrower(false);
                        setSearchCriteria({
                          purchaseReciveNumber: "",
                          purchaseOrderNumver: "",
                          vendorName: "",
                          datee: "",
                          billStatus: "",
                        });
                      }}
                      className="btn btn-danger ms-2 fs-1"
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </h1>
        </Drawer>
      )}
    </>
  );
};

export default PurchaseReciveList;

export const PendingPO = () => {
  return <>{/* <PurchaseOrderList pendiingPurchaseOrder={[]} /> */}</>;
};

// <PurchaseOrderList pendiingPurchaseOrder={pendingPurchaseOrder} />
