import { filteredPOApirtk, SearchPOByWordApirtk } from "../../constants/Api/rtkAPI";
import { apiCalls } from "./reduxBase";

const POApi=apiCalls.injectEndpoints({
    endpoints:(builder)=>({
        FilteredPO:builder.query({
            query:({poStatus,word,startDate,endDate})=>{
                return {
                  url:`${filteredPOApirtk}?poStatus=${poStatus}&startDate=${startDate}&endDate=${endDate}`,
                  method:'GET',
              }
            },
            transformResponse:(response)=>{
                console.log(response,'test')
              return {
                filteredPurchaseOrderList:response?.data,
                currency:response?.currency
              }
            },
            transformErrorResponse:(response)=>{
              return response
            },
            providesTags:['PO']
        }),
        searchPOListByWord:builder.mutation({
            query:(word)=>{
                return {
                  url:`${SearchPOByWordApirtk}?word=${word}`,
                  method:'GET',
              }
            },
            transformResponse:(response)=>{
              return response?.data
            },
            transformErrorResponse:(response)=>{
              return response
            },
            // invalidatesTags:['PO']
        })
    })
})

export const { useLazyFilteredPOQuery,useSearchPOListByWordMutation }=POApi