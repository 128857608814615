import React, { useEffect, useState } from "react";
import MiniList from "../../common/MiniList";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetPaymentMadeQuery } from "../../../redux/reduxNeeded/reduxBase";
import { useLazyViewEachPaymentMadeQuery } from "../../../redux/reduxNeeded/CreatePaymentMade";
import { fn } from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDownloadInExcelMutation } from "../../../redux/reduxNeeded/PrintAndDownload";
import LoaderImage from "../../common/LoaderImage";
import { PaymentMadeToExcelApirtk } from "../../../constants/Api/rtkAPI";
import CommonFileViewPage from "../../common/CommonFileViewPage";
const ViewPayment = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [
    downloadInExcelFormat,
    { isLoading: excelLoading, isError: excelIsError, error: excelError },
  ] = useDownloadInExcelMutation();

  const [
    getPaymentMadeFn,
    { data: getPaymentMadeList, isLoading: getPaymenMadeListLoading },
  ] = useLazyGetPaymentMadeQuery();

  const [
    eachPMDetailfn,
    {
      data: eachPMDetail,
      isFetching: eachPMDetailFetching,
      isError: iseachPMDetailError,
      error: eachPMDetailErr,
    },
  ] = useLazyViewEachPaymentMadeQuery();

  console.log(eachPMDetail, "eachPMDetail");

  const [idOfItem, setIdOfItem] = useState(id);

  useEffect(() => {
    eachPMDetailfn(idOfItem);
  }, [idOfItem]);

  useEffect(() => {
    getPaymentMadeFn({
      startDate: "",
      endDate: "",
    });
  }, []);
  const dataToSendMiniList = {
    listData: getPaymentMadeList,
    activeItem: idOfItem,
    loading: getPaymenMadeListLoading,
    // currtype: eachBillData?.currency,
    destination: "/viewPayment/",
    changeItem: (val) => {
      setIdOfItem(val);
    },
    from: "paymentMade",
  };

  return (
    <>
      {excelLoading ? (
        <LoaderImage />
      ) : (
        <>
          <div className="ttl_mdl"></div>
          <main>
            <section className="total_parent_element crt_bl_sec">
              <div className="right_parent_element">
                <div className="outr-right-content">
                  <div className="bIlls_page bill_details_page">
                    <div className="bills_cnt_otr">
                      <div className="bll_hdr">
                        <h2>Payment Details</h2>
                      </div>
                      <div className="bill_screen_top">
                        <button
                          type="button"
                          className="add_po"
                          onClick={() => {
                            navigate("/createNewPaymentMade");
                          }}
                        >
                          <span>+</span> Create New
                        </button>
                        {/* <FilterByStatus
                  newButtonName={"Bill"}
                  newButtonPath={"/CreateNewBill"}
                  setStatus={setStatus}
                  statusOption={statusOption}
                /> */}

                        <div className="bill_dwnld">
                          <div
                            className="bill_icon"
                            onClick={() => {
                              // dispatch(printTheBill([id]));
                            }}
                          >
                            <span>
                              <img
                                src={require("../../../assets/images/bill_print.png")}
                              />
                            </span>
                          </div>
                          <div
                            className="bill_icon"
                            onClick={() => {
                              downloadInExcelFormat({
                                path: PaymentMadeToExcelApirtk,
                                id: [idOfItem],
                                key: "paymentIds",
                              });
                            }}
                          >
                            <span>
                              <img
                                src={require("../../../assets/images/bill_download.png")}
                              />
                            </span>
                          </div>
                          {/* {!purchaseOrderDetail?.isBilled &&
                    purchaseOrderDetail?.isReceived != "received" && (
                      <div
                        className="bill_icon"
                        onClick={() => {
                          navigate(`/purchase/purchase-order/${idOfItem}`);
                        }}
                      >
                        <span>
                          <img
                            src={require("../../../assets/images/bill_edit.png")}
                          />
                        </span>
                      </div>
                    )} */}
                        </div>
                      </div>
                      <div className="bill_screen">
                        <MiniList pr={dataToSendMiniList} />
                        {eachPMDetailFetching ? (
                          <>
                            <h1
                              style={{
                                textAlign: "center",
                                width: "70%",
                                marginTop: "10%",
                              }}
                            >
                              Please wait data is fetching...
                            </h1>
                          </>
                        ) : (
                          <>
                            <div className="bill_details bill_dtls_right">
                              <div className="seller_or_buyer">
                                <div className="sell_div">
                                  <div className="tp_hdr">
                                    <div className="s-text">
                                      <h2>Supplier details</h2>
                                    </div>
                                  </div>
                                  <div className="sllr_cntnt">
                                    <h3>{eachPMDetail?.supplierId?.name}</h3>

                                    <p>
                                      {eachPMDetail?.supplierId?.phoneNumber}
                                    </p>
                                    <p>
                                      {
                                        eachPMDetail?.supplierId?.address?.[0]
                                          ?.street
                                      }
                                    </p>
                                    <p>
                                      {`${eachPMDetail?.supplierId?.address?.[0]?.city} ,${eachPMDetail?.supplierId?.address?.[0]?.country}`}
                                    </p>

                                    <a href="javascript:void(0);">
                                      {eachPMDetail?.supplierId?.email}
                                    </a>
                                  </div>
                                </div>
                                <div className="sell_div byuyerr">
                                  <div className="tp_hdr">
                                    <div className="s-text">
                                      <h2>Store details</h2>
                                    </div>
                                  </div>
                                  <div className="sllr_cntnt">
                                    <h3>
                                      {
                                        eachPMDetail?.sellerId
                                          ?.businessInformation?.name
                                      }
                                    </h3>
                                    <p>
                                      {
                                        eachPMDetail?.sellerId
                                          ?.contactInformation?.name
                                      }
                                    </p>
                                    <p>
                                      {
                                        eachPMDetail?.sellerId
                                          ?.contactInformation?.phone
                                      }
                                    </p>
                                    <p>
                                      {
                                        eachPMDetail?.sellerId
                                          ?.contactInformation?.businessAddress
                                      }

                                      {/* {
                                    eachPMDetail?.sellerId?.contactInformation
                                      ?.address?.city
                                  } */}
                                    </p>
                                    <p>
                                      {
                                        eachPMDetail?.sellerId
                                          ?.contactInformation?.address?.country
                                      }
                                    </p>
                                    <a href="javascript:void(0);">
                                      {
                                        eachPMDetail?.sellerId
                                          ?.contactInformation?.email
                                      }
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="seller_cross"
                                  onClick={() => {
                                    navigate(-1);
                                  }}
                                >
                                  {/* <i className="fa-solid fa-xmark" /> */}
                                  <FontAwesomeIcon icon={faXmark} />
                                </div>
                              </div>
                              <div className="sllr_dtl-tbl">
                                <div className="byr_div">
                                  <div className="slb_div">
                                    <h4>Payment Number:</h4>
                                    <h4>
                                      <span>{eachPMDetail?.paymentNumber}</span>
                                    </h4>
                                  </div>
                                  <div className="slb_div">
                                    <h4>Payment Date:</h4>
                                    <h4>
                                      <span>
                                        {eachPMDetail?.paymentDate?.slice(
                                          0,
                                          10
                                        )}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                                <div className="byr_div">
                                  {/* <div className="slb_div">
                                <h4>Bill Number:</h4>
                                <h4>
                                  {eachPMDetail?.bills?.map((bill) => {
                                    return <span>{bill?.billNumber} , </span>;
                                  })}
                                </h4>
                              </div> */}
                                  <div className="slb_div">
                                    <h4>payment Mode:</h4>
                                    <h4>
                                      <span className="status">
                                        {eachPMDetail?.paymentMode}
                                      </span>
                                    </h4>
                                  </div>
                                  {/* <div className="slb_div">
                                <h4>payment Type:</h4>
                                <h4>
                                  <span className="status">
                                    {eachBillData?.paymentType}
                                  </span>
                                </h4>
                              </div> */}
                                </div>
                              </div>
                              <div className="sllr_dtl-tbl btm">
                                <div className="btmm_hdr">
                                  <h4>Bill Number</h4>
                                  <h4>Bill Date</h4>
                                  <h4>Bill Amount </h4>
                                  <h4>Payment Amount</h4>
                                </div>
                                {eachPMDetail?.bills?.length > 0 &&
                                  eachPMDetail?.bills?.map((eachBill) => {
                                    return (
                                      <>
                                        <div className="btm_cntt">
                                          <div className="div-clmn name-box">
                                            <h5>{eachBill?.billNumber}</h5>
                                          </div>
                                          <div className="div-clmn">
                                            <h5>
                                              <span className="totaaal">
                                                {eachBill?.billId?.billDate?.slice(
                                                  0,
                                                  10
                                                )}
                                              </span>
                                            </h5>
                                          </div>
                                          <div className="div-clmn">
                                            <h5>{`${eachPMDetail?.currency}  ${eachBill?.billId?.totalAmount} `}</h5>
                                          </div>
                                          <div className="div-clmn">
                                            <h5>{`${eachPMDetail?.currency}  ${eachBill?.amountPaid} `}</h5>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}

                                <div className="footer-bottm">
                                  <div className="div-clmn">
                                    <h5>Total</h5>
                                  </div>
                                  <div className="div-clmn">
                                    <h5 className="amount">
                                      {`${eachPMDetail?.currency}  ${eachPMDetail?.paymentAmount} `}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <CommonFileViewPage
                                fileArray={eachPMDetail?.files}
                              />
                              {/* {eachBillData?.status !== "paid" && (
                            <div
                              onClick={() => {
                                navigate(
                                  `/recordPaymentForm/${id}/${eachBillData?.balanceDue}`
                                );
                              }}
                              className="record_pmnt"
                            >
                              <a href="javascript:void(0);" className="edit">
                                Record Payment
                              </a>
                            </div>
                          )} */}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default ViewPayment;
