import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ShowEachPurchaseRecive } from "../../../redux/features/sellers/PurchaseReciveSlice";
import LoaderImage from "../../common/LoaderImage";
import {
  downloadPRInExcel,
  filteringPurchaseReceive,
  printPR,
} from "../../../redux/features/sellers/FilterPReceive";
import MiniList from "../../common/MiniList";
import { PRStatusOption } from "./PurchaseReciveList";
import FilterByStatus from "../../common/FilterByStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import CommonFileViewPage from "../../common/CommonFileViewPage";

const SeeOnePurchaseReceive = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [idOfItem, setIdOfItem] = useState(id);
  const [filteringData, setFilteringData] = useState({
    PRStatus: "",
    word: "",
    startDate: "",
    endDate: "",
  });
  const setStatuss = (val) => {
    console.log(val, "2222");
    setFilteringData((pre) => {
      let res = JSON.parse(JSON.stringify(pre));
      res.PRStatus = val;
      return res;
    });
  };
  const { dataOfEachPurchaseRecive, loading } = useSelector(
    (a) => a.PurchaseReciveList
  );
  useEffect(() => {
    dispatch(ShowEachPurchaseRecive(idOfItem));
  }, [id]);

  const { dataAfetFiler, PRLoading, PRTypingLoading, PRAfterSearchByWord } =
    useSelector((a) => a.FilteredPurchaseReceiveList);
  useEffect(() => {
    dispatch(filteringPurchaseReceive(filteringData));
  }, [filteringData]);

  const dataToSendMiniList = {
    listData: dataAfetFiler,
    activeItem: idOfItem,
    loading: loading,
    // currtype: currency,
    changeItem: (val) => {
      setIdOfItem(val);
    },
    destination: "/ShowEachPurchaseReceive/",
    from: "purchaseReceive",
  };

  console.log(dataOfEachPurchaseRecive, "222");

  return (
    <>
      <div className="ttl_mdl"></div>
      <main>
        <section className="total_parent_element crt_bl_sec">
          <div className="right_parent_element">
            <div className="outr-right-content">
              <div className="bIlls_page bill_details_page">
                <div className="bills_cnt_otr">
                  <div className="bll_hdr">
                    <h2>Goods Receive Details</h2>
                  </div>
                  <div className="bill_screen_top">
                    {/* select box */}
                    <FilterByStatus
                      newButtonName={"Purchase Receive"}
                      newButtonPath={"/createNewPurchseReceive"}
                      setStatus={setStatuss}
                      statusOption={PRStatusOption}
                    />
                    <div className="bill_dwnld">
                      <div
                        className="bill_icon"
                        onClick={() => {
                          dispatch(printPR([id]));
                        }}
                      >
                        <span>
                          <img
                            src={require("../../../assets/images/bill_print.png")}
                          />
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          dispatch(downloadPRInExcel([id]));
                        }}
                        className="bill_icon"
                      >
                        <span>
                          <img
                            src={require("../../../assets/images/bill_download.png")}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bill_screen">
                    <MiniList pr={dataToSendMiniList} />

                    {loading ? (
                      <>
                        <h1
                          style={{
                            textAlign: "center",
                            width: "70%",
                            marginTop: "10%",
                          }}
                        >
                          Please wait data is fetching...
                        </h1>
                      </>
                    ) : (
                      <>
                        <div className="bill_details bill_dtls_right">
                          <div className="seller_or_buyer">
                            <div className="sell_div">
                              <div className="tp_hdr">
                                <div className="s-text">
                                  <h2>Supplier details</h2>
                                </div>
                              </div>
                              <div className="sllr_cntnt">
                                <h3>
                                  {dataOfEachPurchaseRecive?.supplierId?.name}
                                </h3>
                                <p>
                                  {
                                    dataOfEachPurchaseRecive?.supplierId
                                      ?.phoneNumber
                                  }
                                </p>
                                <p>
                                  {
                                    dataOfEachPurchaseRecive?.supplierId
                                      ?.address?.[0]?.street
                                  }
                                  ,
                                  {
                                    dataOfEachPurchaseRecive?.supplierId
                                      ?.address?.[0]?.city
                                  }
                                </p>
                                <p>
                                  {
                                    dataOfEachPurchaseRecive?.supplierId
                                      ?.address?.[0]?.country
                                  }
                                </p>
                                <a href="javascript:void(0);">
                                  {dataOfEachPurchaseRecive?.supplierId?.email}
                                </a>
                              </div>
                            </div>
                            <div className="sell_div byuyerr">
                              <div className="tp_hdr">
                                <div className="s-text">
                                  <h2>Buyer details</h2>
                                </div>
                              </div>
                              <div className="sllr_cntnt">
                                <h3>
                                  {
                                    dataOfEachPurchaseRecive?.warehouseId
                                      ?.warehouseName
                                  }
                                </h3>
                                <p>
                                  {" "}
                                  {dataOfEachPurchaseRecive?.warehouseId?.phone}
                                </p>
                                <p>
                                  {
                                    dataOfEachPurchaseRecive?.warehouseId
                                      ?.address?.street
                                  }
                                  ,
                                  {
                                    dataOfEachPurchaseRecive?.warehouseId
                                      ?.address?.city
                                  }
                                </p>
                                <p>
                                  {
                                    dataOfEachPurchaseRecive?.warehouseId
                                      ?.address?.country
                                  }
                                </p>
                                <a href="javascript:void(0);">
                                  {dataOfEachPurchaseRecive?.warehouseId?.email}
                                </a>
                              </div>
                            </div>
                            <div
                              className="seller_cross"
                              onClick={() => {
                                navigate("/purchaseRecives");
                              }}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                          <div className="sllr_dtl-tbl">
                            <div className="byr_div">
                              <div className="slb_div">
                                <h4>Purchase Receive Number:</h4>
                                <h4>
                                  <span>
                                    {
                                      dataOfEachPurchaseRecive?.purchaseReceiveNumber
                                    }
                                  </span>
                                </h4>
                              </div>
                              <div className="slb_div">
                                <h4> Date:</h4>
                                <h4>
                                  <span>
                                    {dataOfEachPurchaseRecive?.receivedDate?.slice(
                                      0,
                                      10
                                    )}
                                  </span>
                                </h4>
                              </div>
                              {/* <div className="slb_div">
                                <h4>due Date::</h4>
                                <h4>
                                  <span>
                                    {dataOfEachPurchaseRecive?.expectedDeliveryDate?.slice(
                                      0,
                                      10
                                    )}
                                  </span>
                                </h4>
                              </div> */}
                            </div>
                            <div className="byr_div">
                              <div className="slb_div">
                                <h4>Purchase Order Number:</h4>
                                <h4>
                                  <span>
                                    {
                                      dataOfEachPurchaseRecive?.purchaseOrderNumber
                                    }
                                  </span>
                                </h4>
                              </div>
                              <div className="slb_div">
                                <h4>Bill Number:</h4>
                                <h4>
                                  <span className="status">
                                    {dataOfEachPurchaseRecive?.billNumber}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="sllr_dtl-tbl btm">
                            <div className="btmm_hdr">
                              <h4>No. </h4>
                              <h4>ITEMS</h4>
                              <h4>DESCRIPTION</h4>
                              <h4>QTY</h4>
                              {/* <h4>RATE</h4>
                              <h4>Amount</h4> */}
                            </div>
                            {dataOfEachPurchaseRecive?.products?.length > 0 &&
                              dataOfEachPurchaseRecive?.products?.map(
                                (product, proind) => {
                                  return (
                                    <div className="btm_cntt">
                                      <div className="div-clmn name-box">
                                        <h5>{proind + 1}</h5>
                                      </div>
                                      <div className="div-clmn name-box">
                                        <h5>{product?.productDetails?.name}</h5>
                                      </div>
                                      <div className="div-clmn">
                                        <h5>
                                          Color:
                                          <span className="totaaal">
                                            {product?.productDetails?.color}
                                          </span>
                                        </h5>
                                      </div>
                                      <div className="div-clmn">
                                        {/* Extra gap before this column */}
                                        <h5 style={{ marginRight: "2rem" }}>
                                          {product?.quantityToReceive}{" "}
                                        </h5>
                                      </div>
                                      {/* <div className="div-clmn">
                                        <h5>{`${dataOfEachPurchaseRecive?.currency}  ${product?.unitCost} `}</h5>
                                      </div>
                                      <div className="div-clmn">
                                        <h5>{`${dataOfEachPurchaseRecive?.currency}  ${product?.totalCost} `}</h5>
                                      </div> */}
                                    </div>
                                  );
                                }
                              )}

                            <div className="footer-bottm">
                              {/* <div className="div-clmn">
                                <h5>Total</h5>
                              </div>
                              <div className="div-clmn">
                                <h5 className="amount">
                                  {`${dataOfEachPurchaseRecive?.currency}  ${dataOfEachPurchaseRecive?.totalAmount} `}
                                </h5>
                              </div> */}
                            </div>

                            <CommonFileViewPage
                              fileArray={dataOfEachPurchaseRecive?.files}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default SeeOnePurchaseReceive;
