import React from "react";
import ReactDOM from "react-dom";

const LoaderImage = () => {
  return ReactDOM.createPortal(
    <>
      <div className="loaderr"></div>
      {/* <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "#f5f5f5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999, // Ensure it overlays everything
        }}
      >
        <div>
        </div>
      </div> */}
    </>,
    document.body // Render directly into the <body>
  );
};

export default LoaderImage;

// import React from "react";

// const LoaderImage = () => {
//   return (
//     <>
//       {/* <div className="centered-container">
//         <div>
//           <img src={require("../../assets/images/bank.png")} />
//           <h1 className="text-center">Please wait...</h1>
//         </div>
//       </div> */}

//       <div
//         style={{
//           margin: 0,
//           padding: 0,
//           width: "100vw",
//           height: "100vh",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           backgroundColor: "#f5f5f5",
//         }}
//       >
//         <div
//           style={{
//             textAlign: "center",
//             fontSize: "24px",
//             color: "#333",
//             fontFamily: "Arial, sans-serif",
//           }}
//         >
//           Loading ...
//         </div>
//       </div>
//     </>
//   );
// };

// export default LoaderImage;
