import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allWarehouse } from "../../redux/features/sellers/sellerWarehouseSlice";
import CreateNewWareHouseByModal from "../sellers/purchase/CreateNewWareHouseByModal";

const SearchingWarehouseDropdown = ({
  setWarehouseId,
  initialwarehouseName,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allWarehouse());
  }, []);
  const { warehouseDetail } = useSelector((state) => state.sellerWarehouse);
  const [openWareHouse, setOpenWareHouse] = useState(false);
  const [wareHouseName, setWareHouseName] = useState("");
  const [openNewWareHouseForm, setOpenWareHouseForm] = useState(false);

  const filterWareHouseBySearech = warehouseDetail?.filter((v) =>
    v?.warehouseName.toLowerCase().includes(wareHouseName?.toLowerCase())
  );

  useEffect(() => {
    if (initialwarehouseName) {
      setWareHouseName(initialwarehouseName);
    }
  }, [initialwarehouseName]);

  const forSendingtoCreateWarehouse = {
    openNewWareHouseForm,
    setOpenWareHouseForm,
    wareHouseName,
    setWareHouseName,
    wfromm: "createNewBill",
    setWarehoue: (a) => {
      setWarehouseId(a);
    },
  };
  return (
    <>
      <div className="div_row new_dev_row">
        <label>Warehouse</label>
        <input
          type="text"
          name="supplierName"
          value={wareHouseName}
          // placeholder="Search Warehouse "
          onBlur={() => {
            setTimeout(() => {
              setOpenWareHouse(false);
            }, 150);
          }}
          onClick={() => {
            setOpenWareHouse((pre) => !pre);
          }}
          onChange={(e) => {
            setWareHouseName(e.target.value);
            setOpenWareHouse(true);
          }}
          className="form-control div_row_nw_inpt"
        />
        {openWareHouse && warehouseDetail.length > 0 && (
          <div className="div_row_drops">
            <div className="div_row_drops_inr">
              <ul style={{ position: "static" }}>
                <li
                  className="eachLIOfSearchingDropdown"
                  onClick={() => {
                    setOpenWareHouseForm(true);
                  }}
                >
                  {wareHouseName == ""
                    ? `+ Add new warehouse`
                    : `+ Add ${wareHouseName}`}
                  {/* <button
                    type="button"
                    onClick={() => {
                      setOpenWareHouseForm(true);
                    }}
                    className="edit new_dev_row_edit"
                  >
                    {wareHouseName == ""
                      ? `+ Add new warehouse`
                      : `+ Add ${wareHouseName}`}
                  </button> */}
                </li>
                {filterWareHouseBySearech?.length > 0 ? (
                  filterWareHouseBySearech?.map((v) => (
                    <li
                      className="eachLIOfSearchingDropdown"
                      onClick={() => {
                        setWarehouseId(v?._id);
                        setWareHouseName(v?.warehouseName);
                        // console.log(v, "ddd");
                      }}
                      key={v?._id}
                    >
                      {v?.warehouseName}
                    </li>
                  ))
                ) : (
                  <li className="eachLIOfSearchingDropdown">No result found</li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>

      <CreateNewWareHouseByModal pr={forSendingtoCreateWarehouse} />
    </>
  );
};

export default SearchingWarehouseDropdown;
