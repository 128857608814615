import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { get_inventory_summery, sendingWarehousename} from "../../../constants/Api/Api";
// import { Bounce, toast } from "react-toastify";
import swal from "sweetalert";
import { act } from "react";
// import { Bounce, toast } from "react-toastify";



const initialState = {
  mainPageLoading:false,
  loading: false,
  error: null,
  inventory: [],
  stock:{},
  indexx:undefined
  
};


//GET INVENTORY DETAILS
export const inventoryDetail = createAsyncThunk("inventoryDetail", async(data) =>{
   try{
    const resForInventory = await axios.get(`${get_inventory_summery}${data}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
// console.log(resForInventory, 'resForInventory')
    // console.log(resForInventory.data.data,'chek')
    return resForInventory.data.data
   }catch(error){
// console.log(error)
   }
})

export const getStock=createAsyncThunk('getStock',async (recivedata)=>{
  try {
 // console.log(recivedata,'dd')

     const res=await axios.get(`${sendingWarehousename}?warehouseId=${recivedata.warehouseid}&productId=${recivedata.productId}&variantId=${recivedata.variantId}`,{
      headers:{
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json' 
      }
     })

    //  console.log(res.data.data,'stock')
     return {dataa:res.data.data,name:recivedata.variantId}

  } catch (error) {
    
  }
})



const sellerInventorySlice = createSlice({
  name: "sellerInventory",
  initialState,
 reducers:{
  clearStockData:(st,ac)=>{
    return {...st,indexx:ac?.payload}
  }
 }
  ,

 
  extraReducers: (builder) => {
    builder

//GET INVENTORY DETAILS
      .addCase(inventoryDetail.pending, (state) =>{
        state.mainPageLoading = true
  })
      .addCase(inventoryDetail.fulfilled, (state, action) => {
            
          state.mainPageLoading = false
          state.inventory = action.payload

          
  })
      .addCase(inventoryDetail.rejected, (state, action) => {
       state.mainPageLoading = false
        state.error = action.payload
  })


  builder
  .addCase(getStock.pending, (state) => {
    state.loading = true;
  })
  .addCase(getStock.fulfilled, (state, action) => {
    state.loading = false;
    state.stock={...state.stock,[`${action.payload.name}`]:action.payload.dataa}
  })
  .addCase(getStock.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload
  });
  },
});
const sellerInventoryAction=sellerInventorySlice.actions
export {sellerInventoryAction}
export default sellerInventorySlice.reducer;



