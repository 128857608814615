import { getAllSupplierApirtk, printSupplierApirtk } from "../../constants/Api/rtkAPI";
import { apiCalls } from "./reduxBase";

const allAboutSupplier=apiCalls.injectEndpoints({
    endpoints:(builder)=>({
        getAllSupplierr:builder.query({
            query:()=>({
                url:getAllSupplierApirtk,
                method:'GET'
            }),
            transformResponse:(response)=>{
                return response?.data
            }
        }),
        printSupplier:builder.mutation({
            query:(id)=>({
                url:`${printSupplierApirtk}${id}?pdf=yes`,
                method:'GET'
            }),

            transformResponse:(res)=>{
                console.log(res,'ressssss')
                if (res?.success) {
                    const fileURL = res.fileUrl;
                    // const link = document.createElement('a');
                    // link.href = fileURL;
                    // const fileName = fileURL.split('/').pop();
                    // link.download = fileName;
                    // link.click();                    
                    window.open(fileURL, '_blank', 'noopener,noreferrer');
                }else{
                       console.warn(res);
                }
            }
        })
    })
})


export const { useLazyGetAllSupplierrQuery,usePrintSupplierMutation }=allAboutSupplier