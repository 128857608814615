// export const BASE_URL = "https://api.perfectcreate.com";
// export const BASE_URL = "http://15.206.169.180";
export const BASE_URL = "https://api.getsetdesigns.com";

export const Reg_url = `${BASE_URL}/api/users`;
export const Login_url = `${BASE_URL}/api/users/login`;
export const Seller_Send_otp = `${BASE_URL}/api/sellers/sendOtp`;
export const Seller_login = `${BASE_URL}/api/sellers/login`;
export const Seller_update_password = `${BASE_URL}/api/sellers/updatePassword`;
export const Seller_forgot_password = `${BASE_URL}/api/sellers/forgotPassword`;
export const Seller_reset_password = `${BASE_URL}/api/sellers/resetPassword`;
export const Seller_logout = `${BASE_URL}/api/sellers/logout`;
export const Seller_products = `${BASE_URL}/api/products`;
export const Seller_product_category = `${BASE_URL}/api/category`;
export const all_brand = `${BASE_URL}/api/brands`;
export const productInfo = `${BASE_URL}/api/products`;
export const seller_prodInfo_update = `${BASE_URL}/api/products/update`;
export const get_product_image = `${BASE_URL}/api/variant/getVariantById`;
export const get_allvariants_data = `${BASE_URL}/api/variant/getVariantByProductId`;
export const product_description = `${BASE_URL}/api/products`;
export const product_update = `${BASE_URL}/api/products/update`;

//add supplier info
export const add_supplier = `${BASE_URL}/api/supplier/create`;
//Get all suppliers
export const get_suppliers = `${BASE_URL}/api/supplier`;
//Update status
export const update_status = `${BASE_URL}/api/supplier/update`;

//ADD WAREHOSE
export const add_warehouse = `${BASE_URL}/api/warehouse/create`;

//GET ALL WAREHOUSE LIST
export const get_warehouse = `${BASE_URL}/api/warehouse`;

//CREATE PURCHASE ORDER
export const create_purchase_order = `${BASE_URL}/api/po/create`;

//GET ALL PURCHASE ORDER
export const getAllPO = `${BASE_URL}/api/po/getAllPO`;

//PURCHASE ORDER DETAIL
export const purchase_order_detail = `${BASE_URL}/api/po/getPoById`;

//FOR ADD BILL
export const add_bill = `${BASE_URL}/api/bill/addBill`;

//FOR INVENTORY SUMMERY
export const get_inventory_summery = `${BASE_URL}/api/variant/getAllVariants?warehouseId=`;

export const getlistofwarehouse = `${BASE_URL}/api/warehouse`;

export const sendingWarehousename = `${BASE_URL}/api/warehouse/getVarQtyByWarehouse`;

export const getDetailsbyNumber = `${BASE_URL}/api/users/getUserDataByPhone`;

export const addeduser = `${BASE_URL}/api/users/addUser`;

export const PosLogin = `${BASE_URL}/api/posUser/loginPosUser`;

export const getAllProducts = `${BASE_URL}/api/posUser/getAllProducts`;

export const SearchByNumberAndName = `${BASE_URL}/api/users/getUserDataBySearch`;

export const AddToBucket = `${BASE_URL}/api/bucket/add`;

export const viewBucketItem = `${BASE_URL}/api/bucket/view`;

export const updateBucket = `${BASE_URL}/api/bucket/updateProductQuantity`;

export const removeFromBucket = `${BASE_URL}/api/bucket/remove`;

export const forCheckOut = `${BASE_URL}/api/order/addOrder`;

export const getAllHoldingOrder = `${BASE_URL}/api/bucket/getHoldingBuckets`;

export const putOnHold = `${BASE_URL}/api/bucket/holdBucket`;

export const refillBucket = `${BASE_URL}/api/bucket/getHoldingBucketById`;

export const showpoNumber = `${BASE_URL}/api/po/showPOnumber`;

export const showBillNumer = `${BASE_URL}/api/bill/showBillNumber`;

export const forStoringInRadis = `${BASE_URL}/api/redis/storeBucketDataToRedis`;

export const getprevHoldoredr = `${BASE_URL}/api/redis/getBucketDataFromRedis`;

export const createNewCategory = `${BASE_URL}/api/category`;

export const createNewSubCategory = `${BASE_URL}/api/category/addSubCat`;

export const createNewChildCategory = `${BASE_URL}/api/category/addchildCat`;

export const getAllBillss = `${BASE_URL}/api/bill/getAllBills`;

export const fixStatusBeforeMap = `${BASE_URL}/api/bill/overdueBillStatus`;

export const deleteOneBill = `${BASE_URL}/api/bill/deleteBillById`;

export const forMultipleDelete = `${BASE_URL}/api/bill/deleteMultipleBills`;

export const showEachBillApi = `${BASE_URL}/api/bill/getBillById`;

export const adminLoginApi = `${BASE_URL}/api/admin/login`;

export const addSellerApi = `${BASE_URL}/api/admin/addNewSeller`;

export const genertePurchaseOrderNumberApi = `${BASE_URL}/api/pr/showPRNumber`;

export const PurchaseReciveApi = `${BASE_URL}/api/pr/create`;

export const getPurchaseRecivesListApi = `${BASE_URL}/api/pr/getAllPR`;

export const viewEachPurchaseReciveApi = `${BASE_URL}/api/pr/getPRById/`;

export const DeleteOnePurchaseReceiveApi = `${BASE_URL}/api/pr/deletePRById`;

export const DeletePurchaseOrederAPI = `${BASE_URL}/api/po/deletePOByIds`;

export const GenerateBillNoForPaymentApi = `${BASE_URL}/api/supplierPayment/showPaymentNumber`;

export const BillPaymentApi = `${BASE_URL}/api/supplierPayment/addPayment`;

export const AllPaymentMadeListApi = `${BASE_URL}/api/supplierPayment/getAllPayments`;

export const ForFilterPurchaseOrderListAPI = `${BASE_URL}/api/po/getAllPO`;

export const filterByVendorNameAndPurchase = `${BASE_URL}/api/po/getPOBySearch`;

export const printPurchaseOrderAPI = `${BASE_URL}/api/po/downloadMultiplePO`;

export const editPurchaseOrderApi = `${BASE_URL}/api/po/updatePO/`;

export const downLoadInExcelFormatApi = `${BASE_URL}/api/po/downloadPOlistExcel`;

export const searchBillByWordApi = `${BASE_URL}/api/bill/searchBills?word=`;

export const printBillApi = `${BASE_URL}/api/bill/downloadMultipleBills`;

export const downloadBillInExcelApi = `${BASE_URL}/api/bill/downloadBillListInExcel`;

export const filterPOReceiveApi = `${BASE_URL}/api/pr/getAllPR?`;

export const filterPRByWordApi = `${BASE_URL}/api/pr/searchPurchaseReceive`;

export const downloadPRApi = `${BASE_URL}/api/pr/downloadPRsInPDF`;

export const downloadPRInExcelApi = `${BASE_URL}/api/pr/downloadPRInExcel`;

export const getPONumberAginstVendorNameApi = `${BASE_URL}/api/po/getPOsBySupplierId/`;

export const createNewPRApi = `${BASE_URL}/api/pr/create`;

export const SearchPaymentsMadeApi = `${BASE_URL}/api/supplierPayment/searchPayments?word=`;

export const deletePaymentApi = `${BASE_URL}/api/supplierPayment/deletePaymentsByIds`;

export const PaymentToExcelApi = `${BASE_URL}/api/supplierPayment/downloadSupplierPaymentListExcel`;
