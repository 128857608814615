import { Autocomplete, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  useLazyGetOptionsQuery,
  useUpdateOptionsArrayMutation,
} from "../../redux/reduxNeeded/commonApi";

const CommonPaymentTerm = ({
  initialValue,
  setPaymentTerm,
  id,
  selectedPyamentTerm,
}) => {
  const [getOptions, { data: theOptions, isSuccess: allOptionsFlag }] =
    useLazyGetOptionsQuery();
  const [
    updateOptionArray,
    { isLoading, updateArrayLoading, isSuccess: isArrayUpdateSuccess },
  ] = useUpdateOptionsArrayMutation();
  const [allPaymentTermOptions, setAllPaymentTermOptions] = useState([]);
  useEffect(() => {
    getOptions();
    // .then(async (a) => {
    //   await setAllPaymentTermOptions([...a?.data?.paymentTerms]);
    // });
  }, []);

  useEffect(() => {
    if (allOptionsFlag) {
      setAllPaymentTermOptions([...theOptions?.paymentTerms]);
    }
  }, [theOptions]);

  console.log(allPaymentTermOptions, "allPaymentTermOptions");
  return (
    <>
      <div className="div_row">
        <label>
          Payment Terms<span>*</span>
        </label>

        {/* <div className="inp_srch drp_dn"> */}
        <Autocomplete
          onBlur={(e) => {
            if (initialValue == "") {
              setPaymentTerm("COD");
              if (id != "new") {
                setPaymentTerm("COD");
              } else {
                setPaymentTerm(selectedPyamentTerm);
              }
            }
          }}
          options={[...allPaymentTermOptions, "Create custom payment term"]}
          onChange={(e, v) => {
            if (v === "Create custom payment term") {
              Swal.fire({
                title: "Enter the number of day",
                input: "number",
                inputValue: 120,
                inputAttributes: {
                  autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonText: "Save",
                showLoaderOnConfirm: true,

                preConfirm: async (data) => {
                  try {
                    const res = await updateOptionArray({
                      paymentTerms: [
                        ...allPaymentTermOptions,
                        "Net " + data.trim(),
                      ],
                    }).unwrap();
                    return { data: data, flag: true };
                  } catch (error) {
                    return { flag: false };
                  }
                },
                allowOutsideClick: () => !Swal.isLoading(),
              }).then((result) => {
                console.log(result, "result");
                if (result?.value?.flag) {
                  setPaymentTerm("Net " + result?.value?.data?.trim());
                  Swal.fire({
                    title: ` Created new option Net ${result.value.data}`,
                  });
                } else {
                  Swal.fire({
                    title: `Something went wrong`,
                  });
                }
              });

              // setCustomTermModalOpen(true);
            } else {
              setPaymentTerm(v);
            }
          }}
          value={initialValue}
          label="Select payment terms"
          // getOptionLabel={(op) => op.displayText}
          getOptionSelected={(option, value) => option == value}
          renderInput={(params) => (
            <TextField placeholder="Select payment terms" {...params} />
          )}
          PaperComponent={(props) => (
            <>
              <Paper
                {...props}
                sx={{
                  borderRadius: "16px",
                  fontFamily: "source_sans_proregular",
                  zIndex: 1,
                }}
              />
            </>
          )}
          ListboxProps={{
            sx: {
              fontSize: "1.5rem",
            },
          }}
        />
      </div>
    </>
  );
};

export default CommonPaymentTerm;
