import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { errorToast } from "./ToastMessege";

const CommonMultipleFile = ({ pr }) => {
  const { removeFile, setFileArray, setPicURL, fileArray } = pr;
  const [anchorEl, setAnchorEl] = useState(null);
  const openFileList = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {fileArray.length > 0 && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openFileList}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {fileArray?.map((v, i) => (
            <MenuItem
              key={v?._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "30rem",
                fontSize: "1.5rem",
              }}
            >
              <span key={v?._id}>{v?.name}</span>
              <button
                type="button"
                onClick={() => {
                  removeFile(v, i);
                }}
              >
                <DeleteIcon />
              </button>
            </MenuItem>
          ))}
        </Menu>
      )}
      <div className="frm_row">
        <label>Attach File to Bill</label>
        <div className="task_dD attach">
          <div
            onClick={() => {
              document.getElementById("fileInput").click();
            }}
            id="dropArea"
            className="drag-area"
          >
            <p>
              <span>
                <img src={require("../../assets/images/upld.png")} />
              </span>
              Upload
            </p>
          </div>
          <br />
          <br />
          <span
            id="icon"
            onClick={(event) => {
              if (fileArray.length > 0) {
                setAnchorEl(event.currentTarget);
              }
            }}
            className="brws_btn"
          >
            <FontAwesomeIcon icon={faPaperclip} />
            {`   (${fileArray.length})`}
          </span>
          <div>
            <input
              multiple
              name="images"
              type="file"
              id="fileInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                // console.log(e.target.files.length, "22222222");
                if (e.target.files.length + fileArray?.length > 5) {
                  swal(
                    "Sorry ",
                    "You can select a maximum of 5 files",
                    "error"
                  );
                  // errorToast("You can select a maximum of 5 files");
                } else {
                  // setPicURL((pre) => [
                  //   ...pre,
                  //   ...Object.values(e.target.files),
                  // ]);
                  setFileArray((pre) => [
                    ...pre,
                    ...Object.values(e.target.files),
                  ]);
                }
              }}
            />
            <div className="preview" id="preview" />
          </div>
        </div>
        <p>You can upload a maximum of 5 files</p>
      </div>
    </>
  );
};

export default CommonMultipleFile;
