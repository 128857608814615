import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const PosPrivate = () => {
  // const navigate = useNavigate();

  const exptime = localStorage.getItem("exp");

  // setInterval(() => {
  //   // console.log(Number(localStorage.getItem("exp")) - new Date().getTime());

  //   if (!exptime && Number(exptime) - new Date().getTime() < 0) {
  //     dispatch(logout());
  //     navigate("/login/seller");
  //     localStorage.clear();
  //   }
  // }, 1000);

  const user = localStorage.getItem("user");

  const authToken = localStorage.getItem("token");
  // console.log(authToken, "authToken");
  // return user && user == "seller" && authToken ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to="/choseuser" />
  // );

  if (user && user == "pos" && authToken) {
    return <Outlet />;
  } else if (user && user == "user" && authToken) {
    return <Navigate to={"/"} />;
  } else {
    return <Navigate to={"/choseuser"} />;
  }
};

export default PosPrivate;
