import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import regSlice from "./features/regSlice";
import loginSlice from "./features/loginSlice";
import sellerLoginSlice from "./features/sellers/sellerLoginSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import sellerProductSlice from "./features/sellers/sellerProductSlice";
import sellerPurchaseSlice from "./features/sellers/sellerPurchaseSlice";
import sellerWarehouseSlice from "./features/sellers/sellerWarehouseSlice";
import sellerInventorySlice from "./features/sellers/sellerInventorySlice";
import { Warehouse } from "@mui/icons-material";
import { WarehouseSlice } from "./features/WarehouseSlice";
import { EmailPassWord } from "./features/EmailPassWordSlice";
import ProductsSlice from "./posSlice/ProductsSlice";
import { SelectedCustomer } from "./posSlice/SelectedCustomer";
import BucketSlice from "./posSlice/BucketSlice";
import HoldOrderSlice from "./posSlice/HoldOrderSlice";
import { BucketMenegeSlice } from "./posSlice/BucketMenegeSlice";
import { HoldBucketMeneg } from "./posSlice/HoldBucketMeneg";
import { AllBillSlicer } from "./GetAllBill";
import { AboutAdminSlice } from "./AdminSlice/AdminSlicer";
import {ListOfPurchaseRecive} from "./features/sellers/PurchaseReciveSlice";
import { getUniqeBillNumber } from "./features/sellers/GenereteUniqueBillNo";
import { AllPaymentMadeListSlice } from "./aboutPaymentsMade/AllPaymentMade";
import { GeneratePurchaseOrderNoSlicer } from "./features/sellers/GenetatePurchaseOrderNumber";
import { FilterPurchaseOrderListSlice } from "./features/sellers/FilterPurchaseOrderList";
import { purchaseReceiveStates } from "./features/sellers/FilterPReceive";
import { apiCalls } from "./reduxNeeded/reduxBase";
import { setupListeners } from "@reduxjs/toolkit/query";

// const logger=()=>(next)=>(action)=>{
//   console.log('221')
//   next(action)
// }

// const persistConfig = {
//   key: 'root',
//   storage,
//   version: 1,
// };
// const rootReducer = combineReducers({
//    appState: appStateSlice,
//     regUser: regSlice,
//     loginUser: loginSlice,
//     loginSeller: sellerLoginSlice
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    appState: appStateSlice,
    regUser: regSlice,
    loginUser: loginSlice,
    loginSeller: sellerLoginSlice,
    sellerProducts: sellerProductSlice,
    sellerPurchase: sellerPurchaseSlice,
    sellerWarehouse: sellerWarehouseSlice,
    sellerInventory: sellerInventorySlice,
    Warehouse:WarehouseSlice.reducer,
    PosDetail:ProductsSlice,
    TheCustomer:SelectedCustomer.reducer,
    AboutBucket:BucketSlice,
    aboutHoldOrder:HoldOrderSlice,
    BucketControl:BucketMenegeSlice.reducer,
    HoldBilling:HoldBucketMeneg.reducer,
    AllBills:AllBillSlicer.reducer,
    AboutAdminn:AboutAdminSlice.reducer,
    PurchaseReciveList:ListOfPurchaseRecive.reducer,
    CreateBillNumber:getUniqeBillNumber.reducer,
    AllPaymentMadeLists:AllPaymentMadeListSlice.reducer,
    GeneratNewPurchaseOrder:GeneratePurchaseOrderNoSlicer.reducer,
    FilterPurchaseOrderListt:FilterPurchaseOrderListSlice.reducer,
    FilteredPurchaseReceiveList:purchaseReceiveStates.reducer,
    [apiCalls.reducerPath]:apiCalls.reducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    apiCalls.middleware,
  ],
});

setupListeners(store.dispatch);

// export type RootState = ReturnType<typeof store.getState>;
// export const persistor = persistStore(store);





