

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { DeleteOnePurchaseReceiveApi, getPurchaseRecivesListApi, viewEachPurchaseReciveApi } from '../../../constants/Api/Api';

export const PurchaseReciveSlice = createAsyncThunk(
  'PurchaseReciveSlice', // Action type
  async (payload, thunkAPI) => {
    try {
      const res=await axios.get(getPurchaseRecivesListApi,{
        headers:{Authorization:'Bearer '+localStorage.getItem('token')}
      })
      return res?.data

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); 
    }
  }
);

export const ShowEachPurchaseRecive = createAsyncThunk(
  'ShowEachPurchaseRecive', // Action type
  async (payload, thunkAPI) => {
    try {
      const res=await axios.get(`${viewEachPurchaseReciveApi}${payload}`,{
        headers:{Authorization:'Bearer '+localStorage.getItem('token')}
      })
      console.log(res?.data,'abc')
    return res?.data?.data

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); 
    }
  }
);


 export const DeleteOnePurchaseReceive = createAsyncThunk(
  'DeleteOnePurchaseReceive',
  async (payload, thunkAPI) => {
    try {
const confirm=await swal({
  title: "Are you sure?",
  text: "This operation will impact your current stock in inventory. Are you sure about deleting them?",
  icon: "warning",
  buttons: true,
  dangerMode: true,
})

if (confirm) {
  const res = await axios.delete(DeleteOnePurchaseReceiveApi,{data:{purchaseReceiveIds:payload},
    headers:{Authorization:'Bearer '+localStorage.getItem('token')}
  });
if (res?.data.success) {
  window.location.reload();
swal("Done!", "Deleted successfully", "success");
await thunkAPI.dispatch(PurchaseReciveSlice())   
}
}


    } catch (error) {
      console.log(error)
      swal('Sorry',error?.response?.data?.message,'error')
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const ListOfPurchaseRecive = createSlice({
    name: 'data',
    initialState: {
      data: [],
      loading: false, 
      dataOfEachPurchaseRecive:''
    },
    reducers: {
      // You can add synchronous reducers here if needed
    },
    extraReducers: (builder) => {
      builder
        .addCase(PurchaseReciveSlice.pending, (state) => {
            state.loading=true
        })
        .addCase(PurchaseReciveSlice.fulfilled, (state, action) => {
            state.loading=false
            // console.log(action?.payload)
          state.data = action.payload;
        })
        .addCase(PurchaseReciveSlice.rejected, (state, action) => {
            state.loading=false
        })

        .addCase(ShowEachPurchaseRecive.pending, (state) => {
          state.loading=true
      })
      .addCase(ShowEachPurchaseRecive.fulfilled, (state, action) => {
          state.loading=false
          console.log(action?.payload,'222')
        state.dataOfEachPurchaseRecive =JSON.parse(JSON.stringify(action?.payload))
      })
      .addCase(ShowEachPurchaseRecive.rejected, (state, action) => {
          state.loading=false
          console.log(action.payload)
      })

      .addCase(DeleteOnePurchaseReceive.pending, (state) => {
        state.loading=true
    })
    .addCase(DeleteOnePurchaseReceive.fulfilled, (state, action) => {
        state.loading=false
        // console.log(action?.payload)
      // state.dataOfEachPurchaseRecive =JSON.parse(JSON.stringify(action?.payload))
    })
    .addCase(DeleteOnePurchaseReceive.rejected, (state, action) => {
        state.loading=false
        console.log(action.payload)
    })
        
    },
  });
  
