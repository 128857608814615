import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { showpoNumber } from "../../../constants/Api/Api";


export const GeneratePurchaseOrderNumber=createAsyncThunk('GeneratePurchaseOrderNumber',async(data,thunkAPI)=>{
    try {
        const res=await axios.get(showpoNumber,{headers:{Authorization:'Bearer '+localStorage.getItem('token')}})
        // console.log(res?.data,'abcd')
        return res?.data
    } catch (error) {
        console.warn(error);
        return thunkAPI.rejectWithValue({data:''})
    }
})


export const GeneratePurchaseOrderNoSlicer=createSlice({
    name:'GeneratePurchaseOrderNoSlicer',
    initialState:{loading:false,PONum:''},
    extraReducers: (builder) => {
        builder
          .addCase(GeneratePurchaseOrderNumber.pending, (state) => {
            state.loading = true;
          })
          .addCase(GeneratePurchaseOrderNumber.fulfilled, (state, action) => {
            state.loading = false;
            state.PONum = action.payload.data;
          })
          .addCase(GeneratePurchaseOrderNumber.rejected, (state, action) => {
            state.loading = false;
            state.PONum=action.payload
          });
      },
})