import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

const CommonFileViewPage = ({ fileArray }) => {
  console.log(fileArray, "filearray");
  const [anchorEl, setAnchorEl] = useState(null);
  const openFileList = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <br />
      <br />
      <span
        id="icon"
        onClick={(event) => {
          if (fileArray.length > 0) {
            setAnchorEl(event.currentTarget);
          }
        }}
        className="brws_btn"
      >
        <FontAwesomeIcon icon={faPaperclip} />
        {`   (${fileArray?.length})`}
      </span>

      <Menu
        sx={{
          borderRadius: "6rem",
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFileList}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fileArray?.length > 0 &&
          fileArray?.map((v, i) => (
            <MenuItem
              key={v?._id}
              onClick={() => {
                window.open(v?.url, "_blank");
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "30rem",
                fontSize: "1.5rem",
                fontFamily: "source_sans_proregular",
              }}
            >
              <span key={v?._id}>{v?.name}</span>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default CommonFileViewPage;
