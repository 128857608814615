import React, { useEffect, useState } from "react";
import Sidebar from "../components/common/Sidebar";
import NavBar from "../components/common/Nav/NavBar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMultipleBiils,
  DeleteOneBillOfList,
  fetchingAllBils,
  searchBillByWord,
  showEachBill,
  updateStatusBeforeRender,
} from "../redux/GetAllBill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faEllipsis,
  faEllipsisV,
  faPen,
  faSort,
  faSortDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy, faEye } from "@fortawesome/free-regular-svg-icons";
import {
  Box,
  Dialog,
  Drawer,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Checkbox, DatePicker } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import LoaderImage from "../components/common/LoaderImage";
import CommonDropDown from "../components/common/CommonDropDown";
import { getAllBillss } from "../constants/Api/Api";
import FilterByStatus from "../components/common/FilterByStatus";
import CommonPaginateSection from "../components/common/CommonPaginateSection";
import SearchingDropdownForFilter from "../components/common/SearchingDropdownForFilter";
import {
  downloadBillInExcelFormat,
  printTheBill,
} from "../redux/AboutBills/PrintBill";
import {
  useDownLoadBillInExcelFormatFuntionMutation,
  useDownloadInExcelMutation,
  usePrintInPdfMutation,
} from "../redux/reduxNeeded/PrintAndDownload";
import {
  DownLoadBillInExcelApirtk,
  PrintBillInPdfApirtk,
} from "../constants/Api/rtkAPI";
// import "antd/dist/antd.css";

export const statusOption = [
  { label: "All Bills", value: "all" },
  { label: "Open Bills", value: "open" },
  { label: "Paid Bills", value: "paid" },
  { label: "Overdue Bills", value: "overdue" },
  { label: "Partially Paid Bills", value: "partially" },
];

const ListOfBill = () => {
  const { RangePicker } = DatePicker;
  const {
    allBillloading,
    AllBilldata,
    currencySymbol,
    totalPaybles,
    dueToday,
    Due30days,
    overDueBils,
    searchBillByWordLoading,
    billsAfterSearchByWord,
  } = useSelector((a) => a.AllBills);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log(AllBilldata, "abc");

  // const funtionforcalllatter = async () => {
  //   const one = await dispatch(updateStatusBeforeRender());
  //   const two = await dispatch(fetchingAllBils());
  // };
  useEffect(() => {
    const logintime = localStorage.getItem("loginTime");
    const loginDate = new Date(Number(logintime)).getDate();
    const futereDate = new Date();
    futereDate.setDate(loginDate + 1);
    futereDate.setHours(0, 0, 0, 0);
    // console.log(futereDate.getDate(), "444");
    // console.log(new Date().getDate(), "444");
    // console.log(futereDate.getTime() - new Date().getTime(), "444");
    if (futereDate.getTime() - new Date().getTime() <= 0) {
      dispatch(updateStatusBeforeRender()).then((a) => {
        dispatch(fetchingAllBils());
        // console.log(2);
      });
    } else {
      dispatch(fetchingAllBils());
      // console.log(1);
    }
  }, []);
  // console.log(AllBilldata, "iii");
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };

  const [downloadBillInExcelFormatFn, { isLoading: billDownloadLoading }] =
    useDownloadInExcelMutation();

  const [printInPdffn, { isLoading: printInPdfLoading }] =
    usePrintInPdfMutation();

  const [searchBy, setSearchBy] = useState("");
  const [theStatus, setStatus] = useState("all");
  const [perPage, setPerPage] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState("1975-10-10");
  const [endDate, setEndDate] = useState("5080-10-5");
  const [DateDialog, setDateDialog] = useState(false);
  const [selectedBill, setSelectedBill] = useState([]);
  const [toggleAllBillSelect, setToggleAllBillSelect] = useState(false);
  const [allbillsdropDown, setAllBillOpenDropdown] = useState(false);
  const [word, setWord] = useState("");
  // console.log(
  //   new Date(AllBilldata[0]?.billDate).getTime(),
  //   new Date("2024-10-23").getTime(),
  //   "abc"
  // );
  // console.log(new Date(AllBilldata[0]?.billDate), "abc");

  useEffect(() => {
    setSelectedBill((pre) => {
      let res = [];
      if (toggleAllBillSelect) {
        paginateddata.forEach((element) => {
          res.push(element?._id);
        });
      } else {
        res = [];
      }
      return res;
    });
  }, [toggleAllBillSelect]);

  useEffect(() => {
    if (word.length >= 3) {
      dispatch(searchBillByWord(word));
    }
  }, [word]);

  const filteredByDate = AllBilldata?.filter((each) => {
    // console.log(
    //   new Date(each?.createdAt).getTime(),
    //   new Date(startDate).getTime(),
    //   "abc"
    // );
    return (
      new Date(each?.createdAt).getTime() >= new Date(startDate).getTime() &&
      new Date(each?.createdAt).getTime() <= new Date(endDate).getTime()
    );
  });
  // console.log(filteredByDate, "222");

  const filtredByStatus = filteredByDate?.filter((val, ind) => {
    if (theStatus === "all") {
      return val;
    } else {
      return val?.status?.split(" ")[0] == theStatus;
    }
  });
  const totalPageNumber = Math.ceil(filtredByStatus.length / perPage);
  // console.log(AllBilldata[0]?.billDate, "abc");
  // console.log(AllBilldata, "eee");
  // console.log(filtredByStatus, "eee");
  // console.log(perPage, "dsf");

  const todayy = new Date();
  const c = dayjs();

  const filterBySupplierName = filtredByStatus.filter((v, i) => {
    return v?.supplierId?.name
      ?.toLowerCase()
      ?.includes(searchBy.toLocaleLowerCase());
  });

  // console.log(filterBySupplierName, "abcd");

  const paginateddata = filterBySupplierName?.slice(
    (pageNumber - 1) * perPage,
    perPage * pageNumber
  );

  const setStatusFromFilter = (val) => {
    setStatus(val);
    setPageNumber(1);
  };

  const dataToSendInPaginateSection = {
    setPageNumber,
    setPerPage,
    totalPageNumber,
  };

  const dataToSendForSearchBillByWord = {
    word,
    setWord,
    typingLoading: searchBillByWordLoading,
    showDataOfSearch: billsAfterSearchByWord,
    viewPage: "/eachBill/",
  };

  if (allBillloading || billDownloadLoading || printInPdfLoading) {
    return (
      <LoaderImage />
      // <div className="centered-container">
      //   <div>
      //     <img src={require("../assets/images/bank.png")} />
      //     <h1 className="text-center">Please wait...</h1>
      //   </div>
      // </div>
    );
  } else {
    return (
      <>
        {/* <div className="ttl_mdl"></div>
        <main>
          <section className="total_parent_element">
            <div className="left_parent_element">
              <div className="total_upper_left">
                <div className="logo_area">
                  <img
                    style={{ width: "82%", height: "50px", margin: "auto" }}
                    src={require("../assets/images/bank.png")}
                  />
                </div>
                <div className="nav_area">
                  <div className="outr_dashboard_nav">
                    <div className="parent_nav_menu">
                      <Sidebar />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_parent_element">
              <NavBar /> */}

        <main>
          <section className="total_parent_element bl_scrn_sec">
            <div className="right_parent_element">
              <div className="outr-right-content">
                <div className="bIlls_page">
                  <div className="top_drv">
                    <div className="top_billchk_top">
                      <div className="billchk_brdcrm" aria-label="breadcrumb">
                        <ol className="breadcrumb bliiN">
                          <li className="breadcrumb-item">
                            <a href="javascript:void(0);">POS</a>
                          </li>
                          <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">Bills</a>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="top_bill_postn">
                  <div className="bill_postn_flx">
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Total Payables</span>
                      <h5 className="bill_prt_hdng">
                        {currencySymbol +
                          " " +
                          totalPaybles
                            ?.toLocaleString("hi-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .slice(1, totalPaybles.length)}
                      </h5>
                    </div>
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Due Today</span>
                      <h5 className="bill_prt_hdng">
                        {currencySymbol +
                          " " +
                          dueToday
                            ?.toLocaleString("hi-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .slice(1, dueToday.length)}
                      </h5>
                    </div>
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Due within 30 days</span>
                      <h5 className="bill_prt_hdng">
                        {currencySymbol +
                          " " +
                          Due30days.toLocaleString("hi-IN", {
                            style: "currency",
                            currency: "INR",
                          }).slice(1, Due30days.length)}
                      </h5>
                    </div>
                    <div className="bill_postn_prt">
                      <span className="bill_prt_spn">Over Due Bills</span>
                      <h5 className="bill_prt_hdng">
                        {currencySymbol +
                          " " +
                          overDueBils
                            ?.toLocaleString("hi-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .slice(1, overDueBils.length)}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="blls_drive new_bills_drive">
                  <div className="blls_drive_lft nw_blls_drv_lft">
                    {/* <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={theStatus}
                      placeholder="Select bill status"
                      onChange={(e) => {
                        setStatus(e.target.value);
                        setPageNumber(1);
                      }}
                      sx={{
                        borderRadius: "1rem",
                        backgroundColor: "#ffff",
                        width: "20rem",
                        fontWeight: "900",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "1rem", // Border radius for the container
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "1rem", // Border radius for the outline
                        },
                      }}
                    >
                      <MenuItem value="all">All Bills</MenuItem>
                      <MenuItem value="open">Open Bills</MenuItem>
                      <MenuItem value="paid">Paid Bills</MenuItem>
                      <MenuItem value="overdue">Overdue Bills</MenuItem>
                      <MenuItem value="partially">
                        Partially Paid Bills
                      </MenuItem>
                    </Select> */}

                    <FilterByStatus
                      statusOption={statusOption}
                      setStatus={setStatusFromFilter}
                    />
                    {/* <select
                      onChange={(e) => {
                        setStatus(e.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <option style={{ width: "50px" }} value="all">
                        All Bills
                      </option>
                      <option value="open">Open Bills</option>
                      <option value="paid">Paid Bills</option>
                      <option value="overdue">Overdue Bills</option>
                      <option value="partially">Partially Paid Bills</option>
                    </select> */}

                    {/* <div className="drv_rght_rte">
                      <input
                        type="search"
                        placeholder="Search vendor name"
                        onChange={(e) => {
                          setSearchBy(e.target.value);
                        }}
                      />
                    </div> */}
                    <SearchingDropdownForFilter
                      pr={dataToSendForSearchBillByWord}
                      comesFrom="bill"
                    />

                    {/* {selectedBill?.length > 0 && (
                      <span>
                        <button
                          className="btn btn-primary fs-1"
                          onClick={() => {
                            dispatch(deleteMultipleBiils([...selectedBill]));
                          }}
                        >
                          Delete
                        </button>
                      </span>
                    )} */}
                    {/* <button className="drv_lst_img">
                        <span className="drv_lst_pic">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </span>
                      </button> */}

                    {selectedBill?.length > 0 && (
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <>
                            <button
                              className="drv_lst_img"
                              variant="contained"
                              {...bindTrigger(popupState)}
                            >
                              <span className="drv_lst_pic">
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </span>
                            </button>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <Typography
                                onClick={() => {
                                  dispatch(
                                    deleteMultipleBiils([...selectedBill])
                                  );
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                Delete
                              </Typography>
                              <Typography
                                onClick={() => {
                                  // dispatch(printTheBill(selectedBill));
                                  printInPdffn({
                                    path: PrintBillInPdfApirtk,
                                    id: selectedBill,
                                    key: "billIds",
                                  });
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                Print
                              </Typography>
                              <Typography
                                onClick={() => {
                                  // dispatch(
                                  //   downloadBillInExcelFormat(selectedBill)
                                  // );
                                  downloadBillInExcelFormatFn({
                                    path: DownLoadBillInExcelApirtk,
                                    id: selectedBill,
                                    key: "billIds",
                                  });
                                  popupState.close();
                                }}
                                sx={{ fontSize: "17px", p: 1 }}
                              >
                                To excel format
                              </Typography>
                            </Popover>
                          </>
                        )}
                      </PopupState>
                    )}
                  </div>
                  <div className="blls_drive_rght">
                    <div className="drive_rght_flx">
                      <div className="drv_rght_lft">
                        <NavLink to={"/CreateNewBill"} className="nw_cstmr">
                          Create New Bill
                          <span>+</span>
                        </NavLink>
                      </div>

                      <span className="dt_rng">Select Date :</span>
                      {console.log(startDate, endDate, "222")}
                      <Select
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "1rem", // Adjust the border-radius value as needed
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                            boxShadow: "2",
                            // height: "1rem",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        // label="Select Date Range"
                        placeholder="Select Date "
                        labelId="demo-simple-select-label"
                        id="filterBillDate"
                        onChange={(e) => {
                          let sd = new Date();
                          let ed = new Date();
                          ed.setHours(23, 59, 59, 999);
                          switch (e.target.value) {
                            case "today":
                              sd.setHours(0, 0, 0, 0);
                              setStartDate(sd);
                              setEndDate(ed);
                              break;
                            case "thisWeek":
                              sd.setDate(todayy.getDate() - todayy.getDay());
                              sd.setHours(0, 0, 0, 0);
                              setStartDate(sd);
                              setEndDate(ed);
                              break;
                            case "thisMonth":
                              sd.setDate(1);
                              sd.setHours(0, 0, 0, 0);
                              setStartDate(sd);
                              setEndDate(ed);
                              break;
                            case "30":
                              sd.setDate(todayy.getDate() - 30);
                              sd.setHours(0, 0, 0, 0);
                              setStartDate(sd);
                              setEndDate(ed);
                              break;
                            case "custom":
                              setStartDate(new Date(1900, 1, 1));
                              setDateDialog(true);
                              break;
                            case "7":
                              sd.setDate(todayy.getDate() - 7);
                              sd.setHours(0, 0, 0, 0);
                              setStartDate(sd);
                              setEndDate(ed);
                              break;
                            case "reset":
                              sd.setFullYear(1900);
                              setStartDate(sd);
                              setEndDate(ed);
                              break;

                            default:
                              // Code to be executed if no case matches
                              break;
                          }
                        }}
                        // className="dt_rnge_optn"
                      >
                        {/* <MenuItem value="">Select date</MenuItem> */}
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="thisWeek">This Week</MenuItem>
                        <MenuItem value="thisMonth">This month</MenuItem>
                        <MenuItem value="30">Last 30 days</MenuItem>
                        <MenuItem value="7">Last 7 days</MenuItem>
                        {/* <MenuItem value="reset">Reset</MenuItem> */}
                        <MenuItem value="custom">Custom</MenuItem>
                      </Select>

                      {/* <div className="drv_rght_rte">
                        <div className="dt_rnge_ttl">
                          <span className="dt_rng">Date Range:</span>

                          <select
                            id="filterBillDate"
                            onChange={(e) => {
                              let sd = new Date();
                              let ed = new Date();
                              ed.setHours(23, 59, 59, 999);
                              switch (e.target.value) {
                                case "today":
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "thisWeek":
                                  sd.setDate(
                                    todayy.getDate() - todayy.getDay()
                                  );
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "thisMonth":
                                  sd.setDate(1);
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "30":
                                  sd.setDate(todayy.getDate() - 30);
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "custom":
                                  setStartDate(new Date(1900, 1, 1));
                                  setDateDialog(true);
                                  break;
                                case "7":
                                  sd.setDate(todayy.getDate() - 7);
                                  sd.setHours(0, 0, 0, 0);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;
                                case "reset":
                                  sd.setFullYear(1900);
                                  setStartDate(sd);
                                  setEndDate(ed);
                                  break;

                                default:
                                  // Code to be executed if no case matches
                                  break;
                              }
                            }}
                            // className="dt_rnge_optn"
                          >
                            <option value="">Select date</option>
                            <option value="today">Today</option>
                            <option value="thisWeek">This Week</option>
                            <option value="thisMonth">This month</option>
                            <option value="30">Last 30 days</option>
                            <option value="7">Last 7 days</option>
                            <option value="reset">Reset</option>
                            <option value="custom">Custom</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="drv_lst_lst">
                        <button
                          className="drv_lst_img"
                          type="button"
                          onClick={() => {
                            // dispatch(printTheBill(selectedBill));
                            printInPdffn({
                              path: PrintBillInPdfApirtk,
                              id: selectedBill,
                              key: "billIds",
                            });
                          }}
                        >
                          <span className="drv_lst_pic">
                            {/* <img src="./images/prnt.png" alt="img" /> */}
                            <img
                              src={require("../assets/images/prnt.png")}
                              alt=""
                            />
                          </span>
                        </button>
                        <button
                          className="drv_lst_img"
                          type="button"
                          onClick={() => {
                            // dispatch(downloadBillInExcelFormat(selectedBill));
                            downloadBillInExcelFormatFn({
                              path: DownLoadBillInExcelApirtk,
                              id: selectedBill,
                              key: "billIds",
                            });
                          }}
                        >
                          <span className="drv_lst_pic">
                            {/* <img src="./images/bl_cp.png" alt="img" />  */}
                            <img
                              src={require("../assets/images/bl_cp.png")}
                              alt=""
                            />
                          </span>
                        </button>
                        <button className="drv_lst_img">
                          <span className="drv_lst_pic">
                            {/* <img src="./images/bl_stng.png" alt="img" /> */}
                            <img
                              src={require("../assets/images/bl_stng.png")}
                              alt=""
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="blls_drive_tbl_ottr">
                    <table className="blls_drive_tbl_main">
                      <thead>
                        <tr>
                          <th
                            style={{ textAlign: "left", paddingLeft: "3rem" }}
                          >
                            <Checkbox
                              style={{ marginRight: "10px" }}
                              onChange={() => {
                                setToggleAllBillSelect((pre) => !pre);
                              }}
                            />
                            {/* <p style={{ color: "#005e5a" }}>
                              {selectedBill?.length} item selected
                            </p> */}
                            Bill No.
                          </th>
                          <th>Po No.</th>
                          <th>Vendor Name</th>
                          <th>Billing Date</th>
                          <th>Due Date</th>
                          <th>Status</th>
                          <th>Total Amount</th>
                          <th>Due Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginateddata && paginateddata.length > 0 ? (
                          paginateddata?.map((eachbill, eachind) => {
                            // console.log(eachbill, "abc");
                            return (
                              <tr
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`/eachBill/${eachbill?._id}`);
                                }}
                                key={eachbill?._id}
                              >
                                <td
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <div className="bl_no_flx">
                                    <div className="bl_no_chkbx_otr">
                                      <Checkbox
                                        checked={selectedBill.includes(
                                          eachbill?._id
                                        )}
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          setSelectedBill((pre) => {
                                            const res = [...pre];
                                            const indexofId = res.indexOf(
                                              eachbill?._id
                                            );
                                            // console.log(indexofId, "abc");
                                            if (indexofId === -1) {
                                              return [...res, eachbill?._id];
                                            } else {
                                              res.splice(indexofId, 1);
                                              return res;
                                            }
                                          });
                                        }}
                                      />
                                      {/* <input
                                        type="checkbox"
                                        className="bl_no_inpt"
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          setSelectedBill((pre) => {
                                            const res = [...pre];
                                            const indexofId = res.indexOf(
                                              eachbill?._id
                                            );
                                            // console.log(indexofId, "abc");
                                            if (indexofId === -1) {
                                              return [...res, eachbill?._id];
                                            } else {
                                              res.splice(indexofId, 1);
                                              return res;
                                            }
                                          });
                                        }}
                                      /> */}
                                    </div>
                                    <span className="bl_no_spn">
                                      {eachbill?.billNumber}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {eachbill?.purchaseOrderNumber}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {eachbill?.supplierId?.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {new Date(eachbill?.billDate)
                                      ?.toLocaleDateString("en-GB", options)
                                      .replaceAll("/", "-")}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {new Date(eachbill?.dueDate)
                                      ?.toLocaleDateString("en-GB", options)
                                      .replace(/\//g, "-")}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className="bl_no_spn grn"
                                    style={
                                      eachbill?.status == "paid" ||
                                      eachbill?.status == "partially paid"
                                        ? { color: "green" }
                                        : eachbill?.status == "open"
                                        ? { color: "blue" }
                                        : { color: "red" }
                                    }
                                  >
                                    {eachbill?.status.toUpperCase()}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {currencySymbol +
                                      " " +
                                      eachbill?.totalAmount?.toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  <span className="bl_no_spn">
                                    {currencySymbol +
                                      " " +
                                      eachbill?.balanceDue?.toFixed(2)}
                                  </span>
                                </td>
                                <td
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <div className="drv_tbl_icns dropdown">
                                    <button
                                      className="dropdown-toggle"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <FontAwesomeIcon icon={faSortDown} />
                                    </button>
                                    <ul className="dropdown-menu">
                                      {/* <li>
                                        <button className="dpdn_btn_icns pen">
                                          <FontAwesomeIcon icon={faPen} />
                                          Edit
                                        </button>
                                      </li> */}
                                      <li>
                                        <button
                                          type="button"
                                          className="dpdn_btn_icns trash"
                                          onClick={(e) => {
                                            dispatch(
                                              DeleteOneBillOfList(eachbill?._id)
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                          Delete
                                        </button>
                                      </li>

                                      {eachbill?.status !== "paid" && (
                                        <li>
                                          <button
                                            className="dpdn_btn_icns copy"
                                            type="button"
                                            onClick={() => {
                                              navigate(
                                                `/recordPaymentForm/${eachbill?._id}/${eachbill?.balanceDue}`
                                              );
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faArrowRight}
                                            />
                                            Record Payment
                                          </button>
                                        </li>
                                      )}
                                      {/* <li>
                                        <button
                                          className="dpdn_btn_icns eye"
                                          onClick={() => {
                                            navigate(
                                              `/eachBill/${eachbill?._id}`
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEye} />
                                          View
                                        </button>
                                      </li> */}
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <h1
                            style={{
                              textAlign: "center",
                              padding: "25px",
                            }}
                          >
                            There are no bill.
                          </h1>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {paginateddata && paginateddata.length > 0 && (
                    <CommonPaginateSection pr={dataToSendInPaginateSection} />
                  )}
                  {/* <div className="bttm_drv">
                    <div className="bttm_drv_lft">
                      <div className="bttm_drv_pg_slct">
                        <select
                          onChange={(e) => {
                            setPerPage(e.target.value);
                            setPageNumber(1);
                          }}
                        >
                          <option value={5}>5 per page</option>
                          <option value={10}>10 per page</option>
                          <option value={15}>15 per page</option>
                          <option value={20}>20 per page</option>
                        </select>
                      </div>
                    </div>
                    <div className="bttm_drv_rght">
                      <div aria-label="Page navigation example">
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          width="100%"
                        >
                          <Stack
                            spacing={2}
                            style={{
                              justifyContent: "flex-end",
                              display: "flex",
                            }}
                          >
                            <Pagination
                              count={totalPageNumber}
                              onChange={(e, v) => {
                                setPageNumber(v);
                              }}
                              sx={{
                                "& .MuiPaginationItem-root": {
                                  borderRadius: "50%", // Circular shape
                                  backgroundColor: "#f9f9f9", // Light background for unselected
                                  color: "#333", // Dark text for unselected
                                  "&:hover": {
                                    backgroundColor: "#e0e0e0", // Slightly darker on hover
                                  },
                                },
                                "& .Mui-selected": {
                                  backgroundColor: "#66b2b2", // Teal background for selected
                                  color: "#fff", // White text for selected
                                  "&:hover": {
                                    backgroundColor: "#66b2b2", // No change on hover for selected
                                  },
                                },
                              }}
                            />
                          </Stack>
                        </Box>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="right_notifictaion">
                  <div className="outr_notifictaion">
                    <div className="outr_notifictaion">
                      <div className="innr_top">
                        <div className="ntfcn_hdr">
                          <h6>Notifications</h6>
                        </div>
                        <div className="ntfcn_divs">
                          <div className="ntfcn_div">
                            <div className="nt_icn">
                              <span>
                                <i className="fa-solid fa-bug" />
                              </span>
                            </div>
                            <div className="nt_txt">
                              <h6>You fixed a bug.</h6>
                              <p>Just now</p>
                            </div>
                          </div>
                          <div className="ntfcn_div">
                            <div className="nt_icn">
                              <span>
                                <i className="fa-regular fa-user" />
                              </span>
                            </div>
                            <div className="nt_txt">
                              <h6>New user registered.</h6>
                              <p>59 minutes ago</p>
                            </div>
                          </div>
                          <div className="ntfcn_div">
                            <div className="nt_icn">
                              <span>
                                <i className="fa-solid fa-bug" />
                              </span>
                            </div>
                            <div className="nt_txt">
                              <h6>You fixed a bug.</h6>
                              <p>12 hours ago</p>
                            </div>
                          </div>
                          <div className="ntfcn_div">
                            <div className="nt_icn">
                              <span>
                                <i className="fa-solid fa-tower-broadcast" />
                              </span>
                            </div>
                            <div className="nt_txt">
                              <h6>Andi Lane subscribed to you.</h6>
                              <p>Today, 11:59 AM</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="innr_ordr">
                        <div className="orde-hdr">
                          <h6>Online Orders</h6>
                          <h6>
                            Rs - <span>230</span> - zomato - 10 min
                          </h6>
                        </div>
                        <div className="order_btns">
                          <div className="btn">
                            <a href="javascript:void(0);">Cooking</a>
                          </div>
                          <div className="btn">
                            <a href="javascript:void(0);">Delivered</a>
                          </div>
                        </div>
                      </div>
                      <div className="innr_tbl_bkn">
                        <div className="orde-hdr">
                          <h6>Table Bookings</h6>
                          <h6>26 Dec Manish Sethia 7:30 pm</h6>
                        </div>
                        <div className="order_btns tbl">
                          <div className="btn">
                            <span>
                              <i className="fa-solid fa-check" />
                            </span>
                            <a href="javascript:void(0);">Approve</a>
                          </div>
                          <div className="btn">
                            <span>
                              <i className="fa-solid fa-xmark" />
                            </span>
                            <a href="javascript:void(0);">Decline</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
            {/* <div className="right_sidebar_content">
    <div className="outr_rightsbcntnt">
      <div className="sdbr_oprtr_inpt">
        <div className="sdbr_oprtr_inpt_top">
          <div className="oprt_inpt_info">
            <label>Operator :</label>
            <input type="text" placeholder="Owner" />
            <span><img src="images/sdbr_arw.png" alt="arrow" /></span>
          </div>
          <div className="oprt_inpt_info">
            <label>Client :</label>
            <input type="text" placeholder="Cash Sale" />
            <span><img src="images/sdbr_arw.png" alt="arrow" /></span>
          </div>
        </div>
        <div className="oprt_inpt_info_thrd">
          <span>TYPE</span> <span>POINTS/ITEMS :</span>
          <span>$ VALUE: </span>
        </div>
      </div>
      <div className="sdbr_oprtr_inpt_btm">
        <div className="oprt_inpt_info oprt_inpt_info_thrd">
          <div className="brcd">
            <label>Barcode/Quantity :</label> <br />
            <input type="text" placeholder="" />
          </div>
          <div className="brcd oprt_inpt_info">
            <label>Total :</label>
            <span><img src="images/total_arw.png" alt="arrow" /></span>
          </div>
          <input type="text" placeholder="$91.50" />
        </div>
      </div>
    </div>
  </div> */}
          </section>
        </main>
        {/* </div>
          </section>
        </main> */}
        <Dialog
          anchor="right"
          open={DateDialog}
          onClose={(e) => {
            setDateDialog(false);
            document.getElementById("filterBillDate").value = "";
          }}
        >
          <h1 className="calenderModal">
            Select start date and end date for filter list of bill
            <br />
            <RangePicker
              onChange={(e) => {
                const startd = new Date(e[0]);
                const endDate = new Date(e[1]);
                endDate.setHours(23, 59, 59, 999);
                setStartDate(startd);
                setEndDate(endDate);
                setDateDialog(false);
                document.getElementById("filterBillDate").value = "";
              }}
              dropdownClassName="forIncreaseZIndex"
            />
            <button
              className="btn btn-danger fs-3 m-5"
              onClick={(e) => {
                setDateDialog(false);
                document.getElementById("filterBillDate").value = "";
              }}
            >
              cancel
            </button>
          </h1>
        </Dialog>
      </>
    );
  }
};

export default ListOfBill;
