import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GenerateBillNoForPaymentApi } from "../../../constants/Api/Api";






export const GenerateBillNoForPayment=createAsyncThunk('GenerateBillNoForPayment',async(data,thunkAPI)=>{
    try {
        const res=await axios.get(GenerateBillNoForPaymentApi,{
            headers:{Authorization:'Bearer '+localStorage.getItem('token')}
        })

        // console.log(res?.data)
        return res?.data

    } catch (error) {
        
    }
})

